/* @flow */

import { TileConfigTileType } from '../../libs/netgemLibrary/v8/types/WidgetConfig';
import { getAppCss } from '../applicationCustomization/ui';

// Default max bitrate for green streaming when no value is given in app conf (in Mbps)
export const DEFAULT_MAX_BITRATE = 3.5;

// Amount of time to skip backward or forward (in seconds)
export const SKIP_DURATION_LEVEL0 = 10;
export const SKIP_DURATION_LEVEL1 = 30;
export const SKIP_DURATION_LEVEL2 = 60;

export type SkipDuration = -60 | -30 | -10 | 10 | 30 | 60;

// Background image of card
export const CARD_BACKGROUND_IMAGE_WIDTH = 1920;
export const CARD_BACKGROUND_IMAGE_HEIGHT = 1080;

// Carousel image
export const CAROUSEL_BACKGROUND_IMAGE_WIDTH = 1920;
export const CAROUSEL_BACKGROUND_IMAGE_HEIGHT = 1080;

export const AVENUE_HEADER_WIDTH = 1920;
export const AVENUE_HEADER_HEIGHT = 1080;

export const HUB_IMAGE_WIDTH = 400;
export const HUB_IMAGE_HEIGHT = 64;

// Used to retrieve CSS variables defined at :root level
const styles = window.getComputedStyle(document.documentElement);

const getCssNumber = (name: string): number => parseInt(styles.getPropertyValue(name), 10);
const getCssString = (name: string): string => styles.getPropertyValue(name);

export const BUTTON_HOVER_COLORS: {| Cursor: string, Main: string |} = {
  Cursor: '#CE1866',
  Main: '#FE4E1E',
};

// All constants below are retrieved from CSS, through custom variables

// Imports app-specific CSS
getAppCss().then(() => {
  // Hover button colors
  BUTTON_HOVER_COLORS.Cursor = getCssString('--button-hover-cursor');
  BUTTON_HOVER_COLORS.Main = getCssString('--button-hover-main');
});

// Tile type = gemtv
export const TV_TILE_WIDTH: number = getCssNumber('--tile-gemtv-image-width-u');
export const TV_TILE_WIDTH_PLUS_MARGIN: number = TV_TILE_WIDTH + getCssNumber('--tile-gemtv-horizontal-space-u');

// Tile type = portrait
export const VOD_TILE_WIDTH: number = getCssNumber('--tile-portrait-image-width-u');
export const VOD_TILE_HEIGHT: number = getCssNumber('--tile-portrait-image-height-u');
const VOD_TILE_WIDTH_PLUS_MARGIN = VOD_TILE_WIDTH + getCssNumber('--tile-portrait-horizontal-space-u');

// Tile type = deeplink
const DEEPLINK_TILE_WIDTH_PLUS_MARGIN: number = getCssNumber('--tile-deeplink-image-width-u') + getCssNumber('--tile-deeplink-horizontal-space-u');

// Tile type = deeplink.3x1
const DEEPLINK_3X1_TILE_WIDTH_PLUS_MARGIN: number = getCssNumber('--tile-deeplink-3x1-image-width-u') + getCssNumber('--tile-deeplink-3x1-horizontal-space-u');

// Tile type = deeplink.5x1
const DEEPLINK_5X1_TILE_WIDTH_PLUS_MARGIN: number = getCssNumber('--tile-deeplink-5x1-image-width-u') + getCssNumber('--tile-deeplink-5x1-horizontal-space-u');

// Tile type = landscape.big
const LANDSCAPE_BIG_TILE_WIDTH_PLUS_MARGIN: number = getCssNumber('--tile-landscape-big-image-width-u') + getCssNumber('--tile-landscape-big-horizontal-space-u');

// Tile type = landscape.vod
const LANDSCAPE_VOD_TILE_WIDTH_PLUS_MARGIN: number = getCssNumber('--tile-landscape-vod-image-width-u') + getCssNumber('--tile-landscape-vod-horizontal-space-u');

export const NPVR_TV_TILE_WIDTH: number = getCssNumber('--npvr-tv-image-width-u');
export const NPVR_TV_TILE_HEIGHT: number = getCssNumber('--npvr-tv-image-height-u');

export const CHANNEL_GROUP_TILE_WIDTH: number = getCssNumber('--tile-channel-group-image-width-u');
export const CHANNEL_GROUP_TILE_HEIGHT: number = getCssNumber('--tile-channel-group-image-height-u');
const CHANNEL_GROUP_TILE_WIDTH_PLUS_MARGIN = CHANNEL_GROUP_TILE_WIDTH + getCssNumber('--tile-channel-group-horizontal-space-u');

export const CHANNEL_GROUP_BIG_TILE_WIDTH: number = getCssNumber('--tile-channel-group-big-image-width-u');
export const CHANNEL_GROUP_BIG_TILE_HEIGHT: number = getCssNumber('--tile-channel-group-big-image-height-u');
const CHANNEL_GROUP_BIG_TILE_WIDTH_PLUS_MARGIN = CHANNEL_GROUP_BIG_TILE_WIDTH + getCssNumber('--tile-channel-group-big-horizontal-space-u');

export const CHANNEL_IMAGE_WIDTH: number = getCssNumber('--channel-image-width-u');
export const CHANNEL_IMAGE_HEIGHT: number = getCssNumber('--channel-image-height-u');

export const CONFLICT_CHANNEL_IMAGE_WIDTH: number = getCssNumber('--conflict-channel-image-width-u');
export const CONFLICT_CHANNEL_IMAGE_HEIGHT: number = getCssNumber('--conflict-channel-image-height-u');

export const TILE_CHANNEL_IMAGE_WIDTH: number = getCssNumber('--tile-channel-logo-width-u');
export const TILE_CHANNEL_IMAGE_HEIGHT: number = getCssNumber('--tile-channel-logo-height-u');
const TILE_CHANNEL_IMAGE_WIDTH_PLUS_MARGIN = CHANNEL_GROUP_TILE_WIDTH + getCssNumber('--tile-channel-horizontal-space-u');

export const AVENUE_IMAGE_WIDTH: number = getCssNumber('--avenue-image-width-u');
export const AVENUE_IMAGE_HEIGHT: number = getCssNumber('--avenue-image-height-u');

export const TILE_SYNOPSIS_LINE_HEIGHT: number = getCssNumber('--tile-synopsis-line-height-u');
export const TILE_SYNOPSIS_TAGLINE_LINE_HEIGHT: number = getCssNumber('--tile-synopsis-tagline-line-height-u');

export const BURGER_WIDTH: number = getCssNumber('--burger-width-u');

// eslint-disable-next-line consistent-return
const getTileWidthPlusMargin = (tileType?: TileConfigTileType): number => {
  if (tileType === undefined) {
    return 0;
  }

  switch (tileType) {
    case TileConfigTileType.Channel:
      return TILE_CHANNEL_IMAGE_WIDTH_PLUS_MARGIN;
    case TileConfigTileType.ChannelGroup:
      return CHANNEL_GROUP_TILE_WIDTH_PLUS_MARGIN;
    case TileConfigTileType.ChannelGroupBig:
      return CHANNEL_GROUP_BIG_TILE_WIDTH_PLUS_MARGIN;
    case TileConfigTileType.ChannelGroupBigFill:
      return CHANNEL_GROUP_BIG_TILE_WIDTH_PLUS_MARGIN;
    case TileConfigTileType.ChannelGroupFill:
      return CHANNEL_GROUP_TILE_WIDTH_PLUS_MARGIN;
    case TileConfigTileType.Deeplink:
      return DEEPLINK_TILE_WIDTH_PLUS_MARGIN;
    case TileConfigTileType.Deeplink3x1:
      return DEEPLINK_3X1_TILE_WIDTH_PLUS_MARGIN;
    case TileConfigTileType.Deeplink5x1:
      return DEEPLINK_5X1_TILE_WIDTH_PLUS_MARGIN;
    case TileConfigTileType.Gemtv:
      return TV_TILE_WIDTH_PLUS_MARGIN;
    case TileConfigTileType.GemtvBig:
      return LANDSCAPE_BIG_TILE_WIDTH_PLUS_MARGIN;
    case TileConfigTileType.GemtvMedium:
      return TV_TILE_WIDTH_PLUS_MARGIN;
    case TileConfigTileType.Landscape:
      return TV_TILE_WIDTH_PLUS_MARGIN;
    case TileConfigTileType.LandscapeBig:
      return LANDSCAPE_BIG_TILE_WIDTH_PLUS_MARGIN;
    case TileConfigTileType.LandscapeVod:
      return LANDSCAPE_VOD_TILE_WIDTH_PLUS_MARGIN;
    case TileConfigTileType.Portrait:
      return VOD_TILE_WIDTH_PLUS_MARGIN;

    // No default
  }
};

export const EPG = Object.freeze({
  // EPG for all channels is retrieved by chunks to avoid missing programs
  ChannelChunkSize: 20,
  // Range of loaded live feed EPG (3 hours in milliseconds)
  FeedRange: 10_800_000,
  // Cached live feed is updated every 10 minutes (in milliseconds)
  IntervalCachedLiveFeed: 600_000,
  // Live feed is updated every 10 seconds (in milliseconds)
  IntervalLiveFeedEpgReady: 10_000,
  // Live feed is updated every 100 milliseconds when EPG manager is refreshing (in milliseconds)
  IntervalLiveFeedEpgRefreshing: 100,
  // How long the live feed EPG go back in time (2 hours in milliseconds)
  RewindPeriod: 7_200_000,
});

export { getTileWidthPlusMargin };
