/* @flow */

import * as React from 'react';
import { type BASE_PURCHASE_DETAILS_TYPE, Currency, DEFINITION_PRIORITY, Definition, type STREAM_PRIORITIES_TYPE, type VOD_STATUS_TYPE, getCurrencySymbol } from '../ui/metadata/Types';
import { type BO_PURCHASE_LIST_ITEM_TYPE, type BO_PURCHASE_LIST_TYPE, Purchase, type PurchaseType } from '../../redux/netgemApi/actions/videofutur/types/purchase';
import { MINUTES_PER_HOUR, SECONDS_PER_DAY, SECONDS_PER_HOUR, SECONDS_PER_MINUTE } from '../dateTime/Format';
import { NETGEM_API_V8_ITEM_LOCATION_TYPE_APP, VodKind } from '../../libs/netgemLibrary/v8/types/FeedItem';
import type { NETGEM_API_V8_METADATA_PROGRAM, NETGEM_API_V8_METADATA_PROVIDER_INFO, NETGEM_API_V8_METADATA_SERIES } from '../../libs/netgemLibrary/v8/types/MetadataProgram';
import type {
  NETGEM_API_V8_METADATA_SCHEDULE,
  NETGEM_API_V8_METADATA_SCHEDULE_LOCATION,
  NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_PARAM,
  NETGEM_API_V8_PLAYBACK_URL,
  NETGEM_API_V8_PRICE,
  NETGEM_API_V8_VOD_PURCHASEINFO,
} from '../../libs/netgemLibrary/v8/types/MetadataSchedule';
import AccurateTimestamp from '../dateTime/AccurateTimestamp';
import { Localizer } from '@ntg/utils/dist/localization';
import { NETGEM_API_V8_AUTHENT_REALM_NETGEM } from '../../libs/netgemLibrary/v8/types/Realm';
import type { NETGEM_API_V8_PURCHASE_INFO_PRICE_OPTION } from '../../libs/netgemLibrary/v8/types/PurchaseInfo';
import type { Undefined } from '@ntg/utils/dist/types';
import clsx from 'clsx';
import { getDistributorId } from '../ui/item/distributor';
import { getLocationType } from '../../libs/netgemLibrary/v8/helpers/Item';
import { getStreamsFromPlaybackUrls } from '../../components/player/helper';

export enum BOVodAssetStatus {
  Bought = 'bought',
  Free = 'free',
  FreeButAvailableInFuture = 'futureFree',
  Locked = 'locked',
  Rented = 'rented',
  Series = 'series',
  Unknown = 'unknown',
}

const MAX_TIMESTAMP = 8_640_000_000_000_000;

// Returns availability start date as a timestamp, or 0 (1970-01-01T00:00:00.000Z) if undefined
const getAvailabilityStartTimeOrDefault: (startDate?: string) => number = (startDate) => (startDate ? new Date(startDate).getTime() : 0);

// Returns availability end date as a timestamp, or 0 (275760-09-13T00:00:00.000Z) if undefined
const getAvailabilityEndTimeOrDefault: (endDate?: string) => number = (endDate) => (endDate ? new Date(endDate).getTime() : MAX_TIMESTAMP);

const getDefinitionPriority: (definition: ?Definition) => number =
  // eslint-disable-next-line consistent-return
  (definition) => {
    if (definition === undefined || definition === null) {
      return Number.MIN_SAFE_INTEGER;
    }

    switch (definition) {
      case Definition.FourK:
        return DEFINITION_PRIORITY.Definition4K;
      case Definition.HD:
        return DEFINITION_PRIORITY.DefinitionHD;
      case Definition.SD:
        return DEFINITION_PRIORITY.DefinitionSD;

      // No default
    }
  };

const getTrailerInternal: (streamPriorities: STREAM_PRIORITIES_TYPE | null, trailerUrl: ?NETGEM_API_V8_PLAYBACK_URL) => NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_PARAM | null = (
  streamPriorities,
  trailerUrl,
) => {
  if (!trailerUrl) {
    return null;
  }

  const { allStreams, errorCode } = getStreamsFromPlaybackUrls(streamPriorities, [trailerUrl]);

  if (!allStreams || errorCode) {
    // No supported stream or DRM found
    return null;
  }

  const [{ path, type }] = allStreams;

  if (!path) {
    return null;
  }

  return {
    drms: [],
    path,
    type,
  };
};

const getTrailer: (
  streamPriorities: STREAM_PRIORITIES_TYPE | null,
  programMetadata: ?NETGEM_API_V8_METADATA_PROGRAM,
  seriesMetadata: ?NETGEM_API_V8_METADATA_SERIES,
) => NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_PARAM | null = (streamPriorities, programMetadata, seriesMetadata) =>
  getTrailerInternal(streamPriorities, seriesMetadata?.trailerUrl ?? programMetadata?.trailerUrl);

const hasTrailer: (streamPriorities: STREAM_PRIORITIES_TYPE | null, programMetadata: ?NETGEM_API_V8_METADATA_PROGRAM, seriesMetadata: ?NETGEM_API_V8_METADATA_SERIES) => boolean = (
  streamPriorities,
  programMetadata,
  seriesMetadata,
) => getTrailer(streamPriorities, programMetadata, seriesMetadata) !== null;

const hasProgramTrailer: (streamPriorities: STREAM_PRIORITIES_TYPE | null, programMetadata: ?NETGEM_API_V8_METADATA_PROGRAM) => boolean = (streamPriorities, programMetadata) =>
  getTrailerInternal(streamPriorities, programMetadata?.trailerUrl) !== null;

const hasSeriesTrailer: (streamPriorities: STREAM_PRIORITIES_TYPE | null, seriesMetadata: ?NETGEM_API_V8_METADATA_SERIES) => boolean = (streamPriorities, seriesMetadata) =>
  getTrailerInternal(streamPriorities, seriesMetadata?.trailerUrl) !== null;

const getVodKind: (id: string) => VodKind = (id) => {
  if (id.startsWith((VodKind.EST: string))) {
    return VodKind.EST;
  } else if (id.startsWith((VodKind.TVOD: string))) {
    return VodKind.TVOD;
  } else if (id.startsWith((VodKind.SVOD: string))) {
    return VodKind.SVOD;
  }
  return VodKind.VOD;
};

// Function accepts 1 or 2 VTI Id in order to check if an item has been rent or bought
const hasBeenPurchased: (purchaseList: BO_PURCHASE_LIST_TYPE, distributorId: string, vtiId: number, otherVtiId?: number) => boolean = (purchaseList, distributorId, vtiId, otherVtiId) =>
  getPurchaseDetails(purchaseList, distributorId, vtiId) !== null || getPurchaseDetails(purchaseList, distributorId, otherVtiId) !== null;

const getPurchaseDetails: (purchaseList: BO_PURCHASE_LIST_TYPE, distributorId: string, vtiId?: number) => BO_PURCHASE_LIST_ITEM_TYPE | null = (purchaseList, distributorId, vtiId) => {
  if (!vtiId) {
    return null;
  }

  const { [distributorId]: purchaseListForDistributor } = purchaseList;

  if (!purchaseListForDistributor) {
    return null;
  }

  return purchaseListForDistributor[vtiId.toString()] ?? null;
};

const getVodStatusFromLocations: (vodLocations: ?Array<NETGEM_API_V8_METADATA_SCHEDULE>, purchaseList: BO_PURCHASE_LIST_TYPE | null) => VOD_STATUS_TYPE = (vodLocations, purchaseList) => {
  if (!vodLocations) {
    return { status: BOVodAssetStatus.Unknown };
  }

  const authority = vodLocations[0]?.location.authority;

  if (vodLocations.length === 0) {
    // Series
    return { status: BOVodAssetStatus.Series };
  }

  // Single
  const distributorId = getDistributorId(vodLocations);

  // FranceChannel SVOD
  if (!distributorId && authority !== NETGEM_API_V8_AUTHENT_REALM_NETGEM) {
    return { status: BOVodAssetStatus.Unknown };
  }

  let status: BOVodAssetStatus | null = null;
  let vtiId: Undefined<number> = undefined;
  let viewingHistoryId: Undefined<string> = undefined;
  let expirationTime: Undefined<number> = undefined;

  for (let i = 0; i < vodLocations.length; ++i) {
    const {
      [i]: {
        location: { availabilityStartDate, providerInfo, purchaseInfo, id },
      },
    } = vodLocations;

    const newVtiId = purchaseInfo?.vtiId;
    const newViewingHistoryId = providerInfo?.viewingHistoryId;

    if (getLocationType(id) === NETGEM_API_V8_ITEM_LOCATION_TYPE_APP || (newVtiId === undefined && availabilityStartDate === undefined)) {
      // Deeplink asset or TVOD/EST without VTI Id and availabilityStartDate (error)
      return { status: BOVodAssetStatus.Unknown };
    }

    if (getVodKind(id) === VodKind.SVOD) {
      // VOD asset is free, but it can be available in the future
      const availabilityTime = getAvailabilityStartTimeOrDefault(availabilityStartDate);
      return {
        availabilityTime,
        status: availabilityTime <= AccurateTimestamp.now() ? BOVodAssetStatus.Free : BOVodAssetStatus.FreeButAvailableInFuture,
        viewingHistoryId: newViewingHistoryId,
        vtiId: newVtiId,
      };
    }

    if (authority === NETGEM_API_V8_AUTHENT_REALM_NETGEM) {
      // FranceChannel SVOD
      return {
        status: BOVodAssetStatus.Free,
        viewingHistoryId: newViewingHistoryId,
        vtiId: newVtiId,
      };
    }

    if (!purchaseList || !distributorId) {
      // Cannot happen because either they are defined or authority is 'netgem' (and in this case, we returned just above)
      return { status: BOVodAssetStatus.Unknown };
    }

    const purchaseDetails = getPurchaseDetails(purchaseList, distributorId, newVtiId);

    if (purchaseDetails) {
      // VOD asset has been found in the purchase list
      if (purchaseDetails.type === Purchase.Rent) {
        if (status !== BOVodAssetStatus.Bought) {
          // Rented TVOD
          status = BOVodAssetStatus.Rented;
          vtiId = newVtiId;
          viewingHistoryId = newViewingHistoryId;
          ({ expirationTime } = purchaseDetails);
        }
      } else {
        // Bought EST
        return {
          status: BOVodAssetStatus.Bought,
          viewingHistoryId: newViewingHistoryId,
          vtiId: newVtiId,
        };
      }
    }
  }

  if (status === null) {
    status = BOVodAssetStatus.Locked;
  }

  return {
    expirationTime,
    status,
    viewingHistoryId,
    vtiId,
  };
};

const getPrice: (purchaseInfo: NETGEM_API_V8_VOD_PURCHASEINFO, currency: Currency) => NETGEM_API_V8_PRICE | null = (purchaseInfo, currency) => {
  const { priceOptions } = purchaseInfo;

  if (!priceOptions || priceOptions.length === 0) {
    return null;
  }

  return priceOptions.find((item) => item.currency === (currency: string)) ?? null;
};

const comparePrices: (option1: NETGEM_API_V8_PURCHASE_INFO_PRICE_OPTION, option2: NETGEM_API_V8_PURCHASE_INFO_PRICE_OPTION) => number = (option1, option2) => {
  const { amount: amount1 } = option1;
  const { amount: amount2 } = option2;

  return amount1 - amount2;
};

const isBetterPriceOption: (current: NETGEM_API_V8_PURCHASE_INFO_PRICE_OPTION, candidate: NETGEM_API_V8_PURCHASE_INFO_PRICE_OPTION) => boolean = (current, candidate) => {
  const currentStart = getAvailabilityStartTimeOrDefault(current.availabilityStartDate);
  const candidateStart = getAvailabilityStartTimeOrDefault(candidate.availabilityStartDate);

  // Open window: promo has higher priority, then dates
  return (candidate.promo && !current.promo) || candidateStart < currentStart;
};

const getCurrentOrNextPriceOption: (priceOptions: Array<NETGEM_API_V8_PURCHASE_INFO_PRICE_OPTION>) => NETGEM_API_V8_PURCHASE_INFO_PRICE_OPTION | null = (priceOptions) => {
  const now = AccurateTimestamp.now();
  let currentPriceOption: NETGEM_API_V8_PURCHASE_INFO_PRICE_OPTION | null = null;
  let nextPriceOption: NETGEM_API_V8_PURCHASE_INFO_PRICE_OPTION | null = null;

  // Sort all options by price
  const sortedOptions = [...priceOptions];
  sortedOptions.sort(comparePrices);

  for (const option of priceOptions) {
    const { availabilityEndDate, availabilityStartDate, previewAvailabilityStartDate, promo } = option;
    const previewTime = new Date(previewAvailabilityStartDate).getTime();
    const startTime = getAvailabilityStartTimeOrDefault(availabilityStartDate);
    const endTime = getAvailabilityEndTimeOrDefault(availabilityEndDate);

    // Skip past windows and buggy windows
    if (now < endTime && startTime < endTime) {
      // Current window is open
      if (startTime < now) {
        // Promo has higher priority
        if (currentPriceOption === null || promo) {
          currentPriceOption = option;
        }
      } else if ((nextPriceOption === null || startTime < getAvailabilityStartTimeOrDefault(nextPriceOption.availabilityStartDate)) && previewTime < now) {
        // Next available window
        nextPriceOption = option;
      }
    }
  }

  // Return the current price option or the next available one or null if none was found
  return currentPriceOption ?? nextPriceOption;
};

const formatDisplayPrice: (display: string, currency: Currency) => string = (display, currency) => {
  const reCurrencyText = new RegExp((currency: string), 'ugi');

  return display.replace(reCurrencyText, getCurrencySymbol(currency)).replace(/\./gu, ',');
};

const getDisplayPriceAndDefinition: (vodLocation: NETGEM_API_V8_METADATA_SCHEDULE_LOCATION) => BASE_PURCHASE_DETAILS_TYPE = (vodLocation) => {
  const { availabilityStartDate, providerInfo, purchaseInfo } = vodLocation;

  if (providerInfo?.distributorId && purchaseInfo) {
    const { distributorId } = providerInfo;
    const euroPrice = getPrice(purchaseInfo, Currency.Euro);
    if (euroPrice !== null) {
      const { display } = euroPrice;
      const { definition, vtiId } = purchaseInfo;
      return {
        availabilityStartTime: 0,
        definition,
        displayPrice: formatDisplayPrice(display, Currency.Euro),
        distributorId,
        vtiId,
      };
    }
  }

  return {
    availabilityStartTime: getAvailabilityStartTimeOrDefault(availabilityStartDate),
    definition: Definition.SD,
    displayPrice: '',
    distributorId: '',
    vtiId: 0,
  };
};

const renderPurchaseSummary: (purchaseType: PurchaseType, definition: Definition, displayPrice: string, discountPrice: ?string, updatedPrice: ?string) => React.Node = (
  purchaseType,
  definition,
  displayPrice,
  discountPrice,
  updatedPrice,
) => (
  <>
    <span className='label'>{Localizer.localize(purchaseType === Purchase.Rent ? 'vod.pricing.tvod.rent' : 'vod.pricing.est.buy')}</span>&nbsp;
    <span className='definition'>{(definition: string)}</span>&nbsp;
    <span className='priceFor'>{Localizer.localize('vod.pricing.priceFor')}</span>&nbsp;
    <span className={clsx('price', discountPrice && 'discount')}>{discountPrice || updatedPrice || displayPrice}</span>
    {discountPrice ? <span className='price struck'>{updatedPrice || displayPrice}</span> : null}
  </>
);

const getPurchaseType: (vodKind: VodKind) => PurchaseType | null = (vodKind) => {
  if (vodKind === VodKind.EST) {
    return Purchase.Buy;
  } else if (vodKind === VodKind.TVOD) {
    return Purchase.Rent;
  }

  return null;
};

// Given date is a timestamp in seconds
const getTimeLeftFromExpirationTime: (expirationTime: number) => string = (expirationTime) => {
  const duration = expirationTime - AccurateTimestamp.nowInSeconds();

  if (duration <= SECONDS_PER_MINUTE) {
    // Less than 1 minute
    return Localizer.localize('vod.pricing.tvod.minute0', { count: duration });
  }

  if (duration <= SECONDS_PER_HOUR) {
    // Between 1 and 60 minutes
    return Localizer.localize('vod.pricing.tvod.minute', { count: Math.ceil(duration / MINUTES_PER_HOUR) });
  }

  const TWO = 2;
  if (duration <= SECONDS_PER_DAY * TWO) {
    // Between 60 minutes and 2 days
    return Localizer.localize('vod.pricing.tvod.hour', { count: Math.ceil(duration / SECONDS_PER_HOUR) });
  }

  // More than 2 days
  return Localizer.localize('vod.pricing.tvod.day', { count: Math.ceil(duration / SECONDS_PER_DAY) });
};

/*
 * Ids look like this:
 *  tvod://videofutur/10039482/21580099/dsktp-widevine/vitis-vno-fibre-v8/41/NA
 *  est://videofutur/10039482/21580150/dsktp-widevine/vitis-vno-fibre-v8/41/12451254
 *  svod://videofutur/52002737/70006135/dsktp-widevine/universcine-v8/41/NA
 *
 * General shape is:
 *  <scheme>://.../.../<device OS>/<distributor>/.../...
 */
const extractDistributorId: (id?: string, deviceOS: string) => string | null = (id, deviceOS) => {
  if (!id) {
    return null;
  }

  const re = new RegExp(`/${deviceOS}/(?<distributorId>.+?)/`, 'ugi');
  const match = re.exec(id);

  return match?.groups?.distributorId ?? null;
};

const getTitIdFromProviderInfo: (programProviderInfo: NETGEM_API_V8_METADATA_PROVIDER_INFO, seriesMetadata: ?NETGEM_API_V8_METADATA_SERIES) => string = (programProviderInfo, seriesMetadata) => {
  const { id } = programProviderInfo;

  // Regular case: TIT Id is provided as "id" in program's provider info
  if (id) {
    return id;
  }

  // TIT Id could also be in series' provider info
  const titId = seriesMetadata?.providerInfo.id;
  if (titId) {
    return titId;
  }

  // Hack: when id is not provided, extract it from series' "viewingHistoryId"
  return seriesMetadata?.providerInfo.viewingHistoryId.split('/').at(-1) ?? '';
};

const getVodLocationFromVtiId: (vodLocations: Array<NETGEM_API_V8_METADATA_SCHEDULE> | null, vtiId: ?number) => NETGEM_API_V8_METADATA_SCHEDULE | null = (vodLocations, vtiId) => {
  if (!vodLocations) {
    // No locations: error
    return null;
  }

  if (!vtiId && vodLocations.length > 0) {
    // No VTI Id: item not rent/bought
    return vodLocations[0];
  }

  return vodLocations.find((loc) => loc.location.purchaseInfo?.vtiId === vtiId) ?? null;
};

export {
  extractDistributorId,
  formatDisplayPrice,
  getAvailabilityStartTimeOrDefault,
  getAvailabilityEndTimeOrDefault,
  getCurrentOrNextPriceOption,
  getDefinitionPriority,
  getDisplayPriceAndDefinition,
  getPrice,
  getPurchaseDetails,
  getPurchaseType,
  getTimeLeftFromExpirationTime,
  getTitIdFromProviderInfo,
  getTrailer,
  getVodLocationFromVtiId,
  getVodStatusFromLocations,
  getVodKind,
  hasBeenPurchased,
  hasProgramTrailer,
  hasSeriesTrailer,
  hasTrailer,
  isBetterPriceOption,
  renderPurchaseSummary,
};
