/* @flow */

import './Section.css';
import * as React from 'react';
import {
  METADATA_KIND_PROGRAM,
  METADATA_KIND_SERIES,
  type MetadataKind,
  type NETGEM_API_V8_METADATA,
  type NETGEM_API_V8_METADATA_PROGRAM,
  type NETGEM_API_V8_METADATA_SERIES,
} from '../../../libs/netgemLibrary/v8/types/MetadataProgram';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import type { NETGEM_API_V8_FEED, NETGEM_API_V8_FEED_ITEM } from '../../../libs/netgemLibrary/v8/types/FeedItem';
import type { NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE, TILE_CONFIG_TYPE } from '../../../libs/netgemLibrary/v8/types/WidgetConfig';
import type { CARD_DATA_MODAL_TYPE } from '../../modal/cardModal/CardModalConstsAndTypes';
import type { CombinedReducers } from '../../../redux/reducers';
import type { Dispatch } from '../../../redux/types/types';
import LocalStorageManager from '../../../helpers/localStorage/localStorageManager';
import { Localizer } from '@ntg/utils/dist/localization';
import { NETGEM_API_V8_AUTHENT_REALM_NETGEM } from '../../../libs/netgemLibrary/v8/types/Realm';
import type { NETGEM_API_V8_SECTION } from '../../../libs/netgemLibrary/v8/types/Section';
import PlaceholderTVEpisode from './PlaceholderTVEpisode';
import PlaceholderVodEpisode from './PlaceholderVodEpisode';
import { StorageKeys } from '../../../helpers/localStorage/keys';
import TVItem from './TVEpisode';
import { type Undefined } from '@ntg/utils/dist/types';
import VodItem from './VodEpisode';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { filterCredits } from '../../../helpers/ui/metadata/Exclusion';
import { getTileConfig } from '../../../helpers/ui/section/tile';
import getTranslatedText from '../../../libs/netgemLibrary/v8/helpers/Lang';
import { ignoreIfAborted } from '../../../libs/netgemLibrary/helpers/cancellablePromise/promiseHelper';

import { produce } from 'immer';
import sendV8MetadataRequest from '../../../redux/netgemApi/actions/v8/metadata';
import sendV8SectionFeedRequest from '../../../redux/netgemApi/actions/v8/feed';
import { showDebug } from '../../../helpers/debug/debug';

type ReduxSectionDispatchToPropsType = {|
  +localSendV8MetadataRequest: (assetId: string, type: MetadataKind, signal?: AbortSignal) => Promise<any>,
  +localSendV8SectionFeedRequest: (section: NETGEM_API_V8_SECTION, signal?: AbortSignal) => Promise<any>,
|};

type ReduxSectionReducerStateType = {|
  +isDebugModeEnabled: boolean,
|};

type DefaultProps = {|
  +focusedLocationId?: string,
  +onItemClick?: NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE,
|};

type SectionPropType = {|
  ...DefaultProps,
  +cardData: CARD_DATA_MODAL_TYPE,
  +isVod: boolean,
  +section: NETGEM_API_V8_SECTION,
  // Series Id is only given in case series metadata is not passed to component and has to be loaded from here
  +seriesId: string,
  +seriesMetadata: ?NETGEM_API_V8_METADATA_SERIES,
|};

type CompleteSectionPropType = {|
  ...SectionPropType,
  ...ReduxSectionReducerStateType,
  ...ReduxSectionDispatchToPropsType,
|};

type SectionStateType = {|
  episodesMetadata: Array<NETGEM_API_V8_METADATA_PROGRAM>,
  expandedItemIndex: number,
  feed: NETGEM_API_V8_FEED | null,
  isLoading: boolean,
  seriesMetadata: ?NETGEM_API_V8_METADATA_SERIES,
  tileRenderer: Undefined<(i: number, item: NETGEM_API_V8_FEED_ITEM, seriesMetadata: NETGEM_API_V8_METADATA_SERIES) => React.Node>,
|};

const InitialState: $ReadOnly<SectionStateType> = Object.freeze({
  episodesMetadata: [],
  expandedItemIndex: -1,
  feed: null,
  isLoading: true,
  seriesMetadata: null,
  tileRenderer: undefined,
});

class SectionView extends React.PureComponent<CompleteSectionPropType, SectionStateType> {
  abortController: AbortController;

  placeholderRenderer: (i: number) => React.Node;

  sectionName: string;

  tileConfig: TILE_CONFIG_TYPE;

  static defaultProps: DefaultProps = {
    focusedLocationId: undefined,
    onItemClick: undefined,
  };

  constructor(props: CompleteSectionPropType) {
    super(props);

    const {
      isVod,
      section,
      section: { id },
    } = props;

    this.abortController = new AbortController();
    this.placeholderRenderer = isVod ? this.getVodPlaceholderRenderer : this.getTVPlaceholderRenderer;
    this.sectionName = id;
    this.tileConfig = getTileConfig(section);

    this.state = { ...InitialState };
  }

  componentDidMount() {
    this.loadData();

    // Recordings or scheduled recordings section: ensure data freshness
    Messenger.on(MessengerEvents.REFRESH_RECORDINGS_SECTION, this.forceRefresh);
  }

  componentWillUnmount() {
    const { abortController } = this;

    abortController.abort('Component SectionSeries will unmount');
    Messenger.off(MessengerEvents.REFRESH_RECORDINGS_SECTION, this.forceRefresh);
  }

  showDebugInfo: () => void = () => {
    const { props, state } = this;

    showDebug('Section', {
      instance: this,
      instanceFields: ['sectionName'],
      props,
      propsFields: ['focusedLocationId', 'isVod', 'onItemClick', 'section', 'seriesId', 'seriesMetadata'],
      state,
      stateFields: ['episodesMetadata', 'expandedItemIndex', 'feed', 'isLoading', 'seriesMetadata'],
    });
  };

  setEmptySectionLoaded = () => {
    this.setState({
      episodesMetadata: [],
      expandedItemIndex: -1,
      feed: [],
      isLoading: false,
    });
  };

  // Only used for recordings and scheduled recordings sections
  forceRefresh: () => void = () => {
    this.loadData();
  };

  loadData = () => {
    const { localSendV8SectionFeedRequest, section } = this.props;
    const {
      abortController: { signal },
    } = this;

    const seriesMetadata = this.getSeriesMetadata();

    // Ensure series metadata is loaded before feed
    if (!seriesMetadata) {
      this.loadSeriesMetadata();
      return;
    }

    localSendV8SectionFeedRequest(section, signal)
      .then((response) => {
        signal.throwIfAborted();

        Messenger.emit(MessengerEvents.EPISODES_FEED_LOADED);
        const feed = ((response: any): NETGEM_API_V8_FEED);

        this.setState(
          {
            episodesMetadata: new Array(feed.length),
            feed,
          },
          this.loadEpisodesMetadata,
        );
      })
      .catch((error) => ignoreIfAborted(signal, error, this.setEmptySectionLoaded));
  };

  loadSeriesMetadata = () => {
    const { localSendV8MetadataRequest, seriesId } = this.props;
    const {
      abortController: { signal },
    } = this;

    localSendV8MetadataRequest(seriesId, METADATA_KIND_SERIES, signal)
      .then((metadata: NETGEM_API_V8_METADATA) => {
        signal.throwIfAborted();

        const seriesMetadata = ((metadata: any): NETGEM_API_V8_METADATA_SERIES);
        if (seriesMetadata) {
          this.setState({ seriesMetadata }, () => this.loadData());
        }
      })
      .catch((error) => ignoreIfAborted(signal, error, () => this.setState({ seriesMetadata: null }, this.setEmptySectionLoaded)));
  };

  loadEpisodeMetadata = (index: number, item: NETGEM_API_V8_FEED_ITEM, signal?: AbortSignal): Promise<any> => {
    const { focusedLocationId, isVod, localSendV8MetadataRequest } = this.props;

    const {
      selectedLocation: { id },
      selectedProgramId,
    } = item;

    return localSendV8MetadataRequest(selectedProgramId, METADATA_KIND_PROGRAM, signal).then((metadata) => {
      if (signal?.aborted) {
        return;
      }

      const filteredMetadata = ((filterCredits(metadata): any): NETGEM_API_V8_METADATA_PROGRAM);

      this.setState(
        produce((draft) => {
          draft.episodesMetadata[index] = filteredMetadata;

          // Select (i.e. expand) item that was clicked (TV only)
          if (!isVod && id === focusedLocationId) {
            draft.expandedItemIndex = index;
          }
        }),
      );
    });
  };

  loadEpisodesMetadata = () => {
    const { feed } = this.state;
    const {
      abortController: { signal },
      sectionName,
    } = this;

    if (!feed || feed.length === 0) {
      this.setState({ isLoading: false });
      return;
    }

    const promises = feed.map((item, index) => this.loadEpisodeMetadata(index, item, signal));

    Promise.all(promises)
      .then(() => {
        if (signal.aborted) {
          return;
        }

        if (sectionName !== 'next_section') {
          // Check if a specific episode has been expanded before opening the player
          const data = LocalStorageManager.loadObject(StorageKeys.CardExpandedItemIndex, null);
          if (data) {
            const { expandedItemIndex: savedIndex, itemId: savedItemId, playerExit } = data;
            const {
              cardData: {
                item: { id: itemId },
              },
            } = this.props;

            if (playerExit && savedItemId === itemId) {
              this.updateExpandedState(savedIndex);
            }

            LocalStorageManager.delete(StorageKeys.CardExpandedItemIndex);
          }
        }

        this.setState({ isLoading: false }, this.checkEpisodeType);
      })
      .catch((error) => ignoreIfAborted(signal, error, this.setEmptySectionLoaded));
  };

  checkEpisodeType = (): void => {
    const { isVod } = this.props;
    const { episodesMetadata } = this.state;

    const authority = episodesMetadata[0]?.authority;

    this.setState({ tileRenderer: isVod && authority !== NETGEM_API_V8_AUTHENT_REALM_NETGEM ? this.getVodTileRenderer : this.getTVTileRenderer });
  };

  getSeriesMetadata = (): ?NETGEM_API_V8_METADATA_SERIES => {
    const { seriesMetadata: propsMetadata } = this.props;
    const { seriesMetadata: stateMetadata } = this.state;

    return propsMetadata ?? stateMetadata;
  };

  handleSectionTitleOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const { isDebugModeEnabled } = this.props;

    if (isDebugModeEnabled && event.ctrlKey) {
      this.showDebugInfo();
    }
  };

  getTVPlaceholderRenderer = (i: number): React.Node => <PlaceholderTVEpisode key={i} />;

  getVodPlaceholderRenderer = (i: number): React.Node => <PlaceholderVodEpisode index={i} key={i} />;

  handleOnToggleExpandedState = (index: number) => {
    const { expandedItemIndex } = this.state;

    this.updateExpandedState(index === expandedItemIndex ? -1 : index);
  };

  updateExpandedState = (expandedItemIndex: number) => {
    const {
      cardData: {
        item: { id: itemId },
      },
    } = this.props;
    const { sectionName } = this;

    if (sectionName !== 'next_section') {
      // Save expanded item index to expand it again after player exited (saved data will be updated when player exits to distinguish between manually reopening the card)
      LocalStorageManager.save(StorageKeys.CardExpandedItemIndex, {
        expandedItemIndex,
        itemId,
      });
    }

    this.setState({ expandedItemIndex });
  };

  getTVTileRenderer = (i: number, item: NETGEM_API_V8_FEED_ITEM, seriesMetadata: NETGEM_API_V8_METADATA_SERIES): React.Node => {
    const { cardData, focusedLocationId, onItemClick } = this.props;
    const { episodesMetadata, expandedItemIndex } = this.state;
    const {
      tileConfig: { type: tileType },
    } = this;

    /*
     * Focused location Id is passed to the episode because in the case of a live episode being recorded,
     * the focused location is a scheduled event, while the episode location is a recording (GM-2513)
     */
    return (
      <TVItem
        cardData={cardData}
        focusedLocationId={focusedLocationId}
        index={i}
        isExpanded={i === expandedItemIndex}
        item={item}
        key={item.id}
        onItemClick={onItemClick}
        onToggleExpandedState={this.handleOnToggleExpandedState}
        programMetadata={episodesMetadata[i]}
        seriesMetadata={seriesMetadata}
        tileType={tileType}
      />
    );
  };

  getVodTileRenderer = (i: number, item: NETGEM_API_V8_FEED_ITEM, seriesMetadata: NETGEM_API_V8_METADATA_SERIES): React.Node => {
    const { cardData } = this.props;
    const { episodesMetadata, expandedItemIndex } = this.state;

    return (
      <VodItem
        cardData={cardData}
        index={i}
        isExpanded={i === expandedItemIndex}
        item={item}
        key={item.id}
        onToggleExpandedState={this.handleOnToggleExpandedState}
        programMetadata={episodesMetadata[i]}
        seriesMetadata={seriesMetadata}
      />
    );
  };

  renderEpisodes = (feed: NETGEM_API_V8_FEED, seriesMetadata: NETGEM_API_V8_METADATA_SERIES): Array<React.Node> => {
    const { episodesMetadata, tileRenderer } = this.state;
    const { placeholderRenderer } = this;

    let lastLoadedIndex = -1;
    for (let i = 0; i < episodesMetadata.length; ++i) {
      if (episodesMetadata[i] === undefined) {
        break;
      }
      lastLoadedIndex += 1;
    }

    return feed.map((item, i) => {
      if (tileRenderer === undefined || i > lastLoadedIndex) {
        return placeholderRenderer(i);
      }

      return tileRenderer(i, item, seriesMetadata);
    });
  };

  render(): React.Node {
    const { section } = this.props;
    const { feed, isLoading } = this.state;
    const { sectionName } = this;

    const seriesMetadata = this.getSeriesMetadata();
    const sectionTitle = getTranslatedText(section.title, Localizer.language);

    if (!feed || !seriesMetadata) {
      return null;
    }

    const { length: itemCount } = feed;

    if (!isLoading && itemCount === 0) {
      // Empty sections are not displayed
      return null;
    }

    return (
      <div className={clsx('seriesSection', isLoading && 'placeholder')} id={`section_${sectionName}`}>
        <div className='header'>
          <div className={clsx('sectionTitleContainer', isLoading && sectionTitle === '' && 'noTitle')} onClick={this.handleSectionTitleOnClick}>
            <div className='sectionTitle'>
              <div className='title'>{sectionTitle}</div>
              <div className='episodeCount'>({itemCount})</div>
            </div>
          </div>
        </div>
        <div className='episodes'>{this.renderEpisodes(feed, seriesMetadata)}</div>
      </div>
    );
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxSectionReducerStateType => {
  return {
    isDebugModeEnabled: state.appConfiguration.isDebugModeEnabled,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxSectionDispatchToPropsType => {
  return {
    localSendV8MetadataRequest: (assetId: string, type: MetadataKind, signal?: AbortSignal): Promise<any> => dispatch(sendV8MetadataRequest(assetId, type, signal)),

    localSendV8SectionFeedRequest: (section: NETGEM_API_V8_SECTION, signal?: AbortSignal): Promise<any> => dispatch(sendV8SectionFeedRequest(section, null, signal)),
  };
};

const Section: React.ComponentType<SectionPropType> = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SectionView);

export default Section;
