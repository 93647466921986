/* @flow */

import type { NETGEM_API_V8_HEADERS, NETGEM_API_V8_REQUEST_HEADERS } from '../types/Headers';
import { HeaderName } from '../constants/Headers';
import type { KeyValuePair } from '@ntg/utils/dist/types';

const addAuthenticationHeader: (headerList: NETGEM_API_V8_REQUEST_HEADERS, authenticationToken: string | null) => void = (headerList, authenticationToken) => {
  if (!authenticationToken) {
    return;
  }

  headerList.push({
    name: HeaderName.Authorization,
    value: `Bearer ${authenticationToken}`,
  });
};

const addETagHeader: (headerList: NETGEM_API_V8_REQUEST_HEADERS, eTag: string | null) => void = (headerList, eTag) => {
  if (!eTag) {
    return;
  }

  const existingETag = headerList.find((h) => h.name === HeaderName.IfNoneMatch);
  if (existingETag) {
    return;
  }

  headerList.push({
    name: HeaderName.IfNoneMatch,
    value: eTag,
  });
};

const buildHeaderList: (...args: Array<any>) => NETGEM_API_V8_REQUEST_HEADERS = (...args) => {
  const headerList: NETGEM_API_V8_REQUEST_HEADERS = [];
  const ARGUMENT_STEP = 2;

  const { length } = args;
  if (length > 1) {
    for (let i = 0; i < length - 1; i += ARGUMENT_STEP) {
      const { [i]: name, [i + 1]: value } = args;

      if (name && value !== undefined && value !== null) {
        headerList.push({
          name,
          value,
        });
      }
    }
  }

  return headerList;
};

const parseResponseHeaders: (responseHeaders: ?string) => NETGEM_API_V8_HEADERS = (responseHeaders) => {
  const headers: KeyValuePair<string> = {};

  if (responseHeaders) {
    responseHeaders.split('\r\n').forEach((header) => {
      const [key, ...value] = header.trim().split(':');
      if (key) {
        headers[key.trim().toLowerCase()] = value.join(':').trim();
      }
    });
  }

  return headers;
};

const getResponseHeader: (headers?: NETGEM_API_V8_HEADERS | null, header: string) => string | null = (headers, header) => {
  if (!headers) {
    return null;
  }

  let key: string = '';
  switch (header) {
    case HeaderName.Accept:
      key = 'accept';
      break;
    case HeaderName.AppKey:
      key = 'app_key';
      break;
    case HeaderName.Authorization:
      key = 'authorization';
      break;
    case HeaderName.ContentType:
      key = 'content-type';
      break;
    case HeaderName.IfMatch:
      key = 'if-match';
      break;
    case HeaderName.IfNoneMatch:
      key = 'if-none-match';
      break;
    case HeaderName.VuDrmToken:
      key = 'x-vudrm-token';
      break;
    case HeaderName.WwwAuthenticate:
      key = 'www-authenticate';
      break;

    // No default
  }

  return headers[key] ?? null;
};

export { addAuthenticationHeader, addETagHeader, buildHeaderList, getResponseHeader, parseResponseHeaders };
