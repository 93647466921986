/* @flow */

import './CardModalAvenue.css';
import * as React from 'react';
import { GroupItemsBy, type NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE } from '../../../libs/netgemLibrary/v8/types/WidgetConfig';
import { ItemType, type NETGEM_API_V8_FEED_ITEM } from '../../../libs/netgemLibrary/v8/types/FeedItem';
import type { CARD_DATA_MODAL_TYPE } from './CardModalConstsAndTypes';
import type { Dispatch } from '../../../redux/types/types';
import { type NETGEM_API_V8_AVENUE } from '../../../libs/netgemLibrary/v8/types/Avenue';
import type { NETGEM_API_V8_URL_DEFINITION } from '../../../libs/netgemLibrary/v8/types/NtgVideoFeed';
import RegularSection from '../../avenue/section/Section';
import SeriesSection from '../../avenue/sectionSeries/Section';
import type { Undefined } from '@ntg/utils/dist/types';
import { connect } from 'react-redux';
import { filterSections } from '../../../helpers/ui/metadata/Exclusion';
import { ignoreIfAborted } from '../../../libs/netgemLibrary/helpers/cancellablePromise/promiseHelper';
import { isVodItem } from '../../../helpers/ui/item/metadata';
import sendV8CustomStrategyRequest from '../../../redux/netgemApi/actions/v8/customStrategyRequest';

type ReduxAvenueDispatchToPropsType = {|
  +localSendV8CustomStrategyRequest: (urlDefinition: NETGEM_API_V8_URL_DEFINITION, item: NETGEM_API_V8_FEED_ITEM, signal?: AbortSignal) => Promise<any>,
|};

type AvenuePropType = {|
  +cardData: CARD_DATA_MODAL_TYPE,
  +displayedSectionCount: ?number,
|};

type CompleteAvenuePropType = {|
  ...AvenuePropType,
  ...ReduxAvenueDispatchToPropsType,
|};

type AvenueStateType = {|
  avenue: NETGEM_API_V8_AVENUE | null,
  cardItem: NETGEM_API_V8_FEED_ITEM | null,
|};

const InitialState = Object.freeze({
  avenue: null,
  cardItem: null,
});

class CardModalAvenue extends React.PureComponent<CompleteAvenuePropType, AvenueStateType> {
  abortController: AbortController;

  constructor(props: CompleteAvenuePropType) {
    super(props);

    this.abortController = new AbortController();

    this.state = { ...InitialState };
  }

  componentDidMount() {
    this.loadAvenue();
  }

  componentDidUpdate(prevProps: CompleteAvenuePropType) {
    const {
      cardData: { item },
    } = this.props;
    const {
      cardData: { item: prevItem },
    } = prevProps;

    if (prevItem !== item) {
      this.loadAvenue();
    }
  }

  componentWillUnmount() {
    const { abortController } = this;

    abortController.abort('Component CardModalAvenue will unmount');
  }

  loadAvenueFromUrlDefinition = (urlDefinition: NETGEM_API_V8_URL_DEFINITION, cardItem: NETGEM_API_V8_FEED_ITEM) => {
    const { localSendV8CustomStrategyRequest } = this.props;
    const {
      abortController: { signal },
    } = this;

    localSendV8CustomStrategyRequest(urlDefinition, cardItem, signal)
      .then((response) => {
        signal.throwIfAborted();

        this.setState({ avenue: filterSections(response) });
      })
      .catch((error) => ignoreIfAborted(signal, error, this.destroyAvenue));
  };

  loadAvenue = () => {
    const {
      cardData: {
        item,
        item: { type },
        seriesMetadata,
        urlDefinition,
      },
    } = this.props;

    if (!urlDefinition) {
      return;
    }

    // Used to force treating episode of series with "program" type as a series
    const cardItem =
      type === ItemType.Program && seriesMetadata !== undefined && seriesMetadata !== null
        ? {
            ...item,
            id: seriesMetadata.id,
            seriesId: seriesMetadata.id,
            type: ItemType.Series,
          }
        : item;

    this.setState({ cardItem });
    this.loadAvenueFromUrlDefinition(urlDefinition, cardItem);
  };

  dataLoadedCallback = () => {
    // Nothing to do at the moment
  };

  createAvenue = (displayedSectionCount: ?number): Array<React.Node> | null => {
    const {
      cardData,
      cardData: { seriesMetadata },
    } = this.props;
    const { avenue, cardItem } = this.state;

    if (!avenue || !cardItem) {
      return null;
    }

    const {
      id: itemId,
      seriesId,
      selectedLocation: { id: selectedLocationId },
    } = cardItem;

    const {
      sections,
      sections: { length },
    } = avenue;
    const sectionCount = typeof displayedSectionCount === 'number' ? Math.min(displayedSectionCount, length) : length;
    const sectionContainer: Array<React.Node> = [];

    for (let i: number = 0; i < sectionCount; ++i) {
      const { [i]: section } = sections;
      const { id, widgetConfig } = section;
      let groupItemsBy: ?GroupItemsBy = null;
      let onItemClick: Undefined<NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE> = undefined;

      if (widgetConfig) {
        ({ groupItemsBy, onItemClick } = widgetConfig);
      }

      if (seriesId && groupItemsBy === GroupItemsBy.Program) {
        sectionContainer.push(
          <SeriesSection
            cardData={cardData}
            focusedLocationId={selectedLocationId}
            isVod={isVodItem(cardItem)}
            key={`section_${id}`}
            onItemClick={onItemClick}
            section={section}
            seriesId={seriesId}
            seriesMetadata={seriesMetadata}
          />,
        );
      } else {
        sectionContainer.push(
          <RegularSection
            avenueIndex={0}
            cardData={cardData}
            dataLoadedCallback={this.dataLoadedCallback}
            key={`section_${id}_${itemId}`}
            onItemClick={onItemClick}
            section={section}
            sectionIndex={i}
          />,
        );
      }
    }

    return sectionContainer;
  };

  destroyAvenue = () => {
    this.setState({ ...InitialState });
  };

  render(): React.Node {
    const { displayedSectionCount } = this.props;

    if (typeof displayedSectionCount === 'number' && displayedSectionCount === 0) {
      return null;
    }

    return <div className='cardAvenueView'>{this.createAvenue(displayedSectionCount)}</div>;
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ReduxAvenueDispatchToPropsType => {
  return {
    localSendV8CustomStrategyRequest: (urlDefinition: NETGEM_API_V8_URL_DEFINITION, item: NETGEM_API_V8_FEED_ITEM, signal?: AbortSignal): Promise<any> =>
      dispatch(sendV8CustomStrategyRequest(urlDefinition, item, signal)),
  };
};

const CardAvenueView: React.ComponentType<AvenuePropType> = connect(null, mapDispatchToProps, null, { forwardRef: true })(CardModalAvenue);

export default CardAvenueView;
