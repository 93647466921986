/* @flow */

import type { NETGEM_API_V8_FEED, NETGEM_API_V8_ITEM_LOCATION, NETGEM_API_V8_ITEM_SCORE, NETGEM_API_V8_MINIMAL_FEED_ITEM } from '../types/FeedItem';
import type { NETGEM_API_V8_FEED_SCORING, ScoreContextType } from '../types/Feed';
import { getLocationStartAndEndTime, getLocationType } from './Item';
import type { NETGEM_API_VIEWINGHISTORY_PLAYED_ITEMS } from '../types/ViewingHistory';
import { compareLocationScore } from './Filter';
import { getIso8601DateInSeconds } from '../../../../helpers/dateTime/Format';
import { getRandomInteger } from '../../../../helpers/maths/maths';
import { getRoundedNowToISOString } from './Date';
import sha256 from 'crypto-js/sha256';

const getLastPlayedTime: (loc: NETGEM_API_V8_ITEM_LOCATION, playedItemsMap: ?NETGEM_API_VIEWINGHISTORY_PLAYED_ITEMS) => number = (loc, playedItemsMap) => {
  if (!playedItemsMap) {
    return 0;
  }

  const { id } = loc;
  if (!id) {
    return 0;
  }

  const { [id]: item } = playedItemsMap;
  if (!item) {
    return 0;
  }

  return new Date(item.date).getTime();
};

const calculateLocationScore: (loc: NETGEM_API_V8_ITEM_LOCATION, scoring: NETGEM_API_V8_FEED_SCORING, context?: ScoreContextType) => NETGEM_API_V8_ITEM_SCORE = (loc, scoring, context) => {
  const score: NETGEM_API_V8_ITEM_SCORE = [];
  const { channelId, id: assetId = '', startDate } = loc;
  const playedItemsMap = context?.playedItemsMap;
  const sessionId = context?.sessionId;

  const channel = channelId && context?.channels ? context?.channels[channelId] : null;

  for (let i = 0; i < scoring.length; i++) {
    const { args = [], op, order = 'asc' } = scoring[i];
    let value = 0;

    switch (op) {
      case 'broadcastTime':
        value = getIso8601DateInSeconds(startDate);
        break;

      case 'const':
        value = args.length > 0 ? Number(args[0]) : 0;
        break;

      case 'favouriteChannel':
        // Use "channel.isFavourite ? 1 : 0;" to enable favourite channel feature
        value = 0;
        break;

      case 'lastPlayedTime':
        value = getLastPlayedTime(loc, playedItemsMap);
        break;

      case 'lcn':
        value = channel ? (order === 'asc' ? 1 : -1) * channel.number : 0;
        break;

      case 'random':
        if (args.length > 0) {
          if (args[0] === 'session' && sessionId) {
            value = sha256(`${sessionId}-${assetId}`).toString();
          } else if (args[0] === 'now') {
            const [, rounding, offset] = args;
            if (typeof rounding === 'number' && (offset === undefined || typeof offset === 'number')) {
              const now = getRoundedNowToISOString(rounding, offset);
              value = sha256(`${now}-${assetId}`).toString();
            }
          }
        } else {
          value = getRandomInteger(0, Number.MAX_SAFE_INTEGER);
        }
        break;

      default:
        value = 0;
    }

    if (order === 'desc') {
      value = -value;
    }

    score.push(value);
  }

  return score;
};

const setLocationsScore: (locations: Array<NETGEM_API_V8_ITEM_LOCATION>, scoring: NETGEM_API_V8_FEED_SCORING, context?: ScoreContextType) => void = (locations, scoring, context) => {
  locations.forEach((loc) => {
    loc.score = calculateLocationScore(loc, scoring, context);
  });
};

const getBestLocation: (locations: ?Array<NETGEM_API_V8_ITEM_LOCATION>) => NETGEM_API_V8_ITEM_LOCATION | null = (locations) => {
  if (!locations) {
    return null;
  }

  let bestLocation: NETGEM_API_V8_ITEM_LOCATION | null = null;

  locations.forEach((loc) => {
    if (!bestLocation || compareLocationScore(loc, bestLocation) < 0) {
      bestLocation = loc;
    }
  });

  return bestLocation;
};

const getBestLocationForItem: (item: NETGEM_API_V8_MINIMAL_FEED_ITEM) => { location: NETGEM_API_V8_ITEM_LOCATION | null, programId: string } = (item) => {
  const { elements, id, locations } = item;

  let bestLocation = getBestLocation(locations);
  let bestProgramId = id;

  if (elements) {
    elements.forEach((e) => {
      const { location, programId } = getBestLocationForItem(e);
      if (!bestLocation || (location && compareLocationScore(location, bestLocation) < 0)) {
        bestLocation = location;
        bestProgramId = programId;
      }
    });
  }

  return {
    location: bestLocation,
    programId: bestProgramId,
  };
};

const updateSelectedLocation: (feed: NETGEM_API_V8_FEED) => void = (feed) => {
  feed.forEach((item) => {
    const { location, programId } = getBestLocationForItem(item);

    if (location && programId) {
      const { id, score } = location;
      const { endTime, startTime } = getLocationStartAndEndTime(location);

      item.selectedLocation = location;
      item.selectedProgramId = programId;
      item.locType = getLocationType(id);
      item.score = score;
      item.startTime = startTime;
      item.endTime = endTime;
    }
  });
};

export { calculateLocationScore, getBestLocationForItem, setLocationsScore, updateSelectedLocation };
