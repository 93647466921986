/* @flow */

import { type NETGEM_API_WISHLIST, WishlistItemKind } from '../../../../libs/netgemLibrary/v8/types/Wishlist';
import { deleteWishlistETag, resetWishlist, setWishlistError, updateWishlist } from '../../../ui/actions';
import AccurateTimestamp from '../../../../helpers/dateTime/AccurateTimestamp';
import type { CombinedReducers } from '../../../reducers';
import { CustomNetworkError } from '../../../../libs/netgemLibrary/helpers/CustomNetworkError';
import type { Dispatch } from '../../../types/types';
import { HttpStatus } from '../../../../libs/netgemLibrary/v8/constants/NetworkCodesAndMessages';
import { type NETGEM_API_V8_REQUEST_RESPONSE } from '../../../../libs/netgemLibrary/v8/types/RequestResponse';
import { PersonalDataKey } from '../../../../libs/netgemLibrary/personalData/constants/keys';
import type { RequestResponseMethodDefinitionType } from '../emitter';
import { getWishlistTypeFromId } from '../../../../libs/netgemLibrary/v8/helpers/Wishlist';
import { isUndefinedOrNull } from '@ntg/utils/dist/types';
import { sendPersonalDataGetRequest } from './get';
import { sendPersonalDataPostRequest } from './post';

const getWishlist: (signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers): Promise<any> => {
    const {
      ui: {
        wishlistETagCache: { wishlist: initialETag },
      },
    } = getState();

    return dispatch(sendPersonalDataGetRequest(PersonalDataKey.Wishlist, initialETag, signal))
      .then((data: NETGEM_API_V8_REQUEST_RESPONSE) => {
        const { eTag: wishlistETag } = data;
        const result = JSON.parse((data.result: string));
        let wishlist: NETGEM_API_WISHLIST = (result.wishlist: NETGEM_API_WISHLIST) || [];

        if (!Array.isArray(wishlist)) {
          wishlist = [];
        }

        // Filter out malformed items
        wishlist = validateWishlist(wishlist);

        dispatch(updateWishlist(wishlist, wishlistETag));
        return Promise.resolve(wishlistETag);
      })
      .catch((error: CustomNetworkError) => {
        const status = error.getStatus();
        if (status === HttpStatus.NotFound) {
          // Not found means wishlist does not exist yet
          dispatch(resetWishlist());
          return Promise.resolve(null);
        } else if (status === HttpStatus.NotModified) {
          return Promise.resolve(initialETag);
        }

        dispatch(setWishlistError());
        return Promise.reject(error);
      });
  };

const validateWishlist: (wishlist: NETGEM_API_WISHLIST) => NETGEM_API_WISHLIST = (wishlist) => {
  const validatedWishlist = [];

  for (let i = 0; i < wishlist.length; ++i) {
    const { [i]: item } = wishlist;
    const { channelId, date, id, type } = item;

    if (
      (channelId === undefined || typeof channelId === 'string') &&
      typeof date === 'string' &&
      typeof id === 'string' &&
      (type === WishlistItemKind.Program || type === WishlistItemKind.Season || type === WishlistItemKind.Series)
    ) {
      validatedWishlist.push(item);
    }
  }

  return validatedWishlist;
};

const findAssetId: (wishlist: NETGEM_API_WISHLIST, assetId: string, channelId: string, acceptNotMatchingChannelId?: boolean) => number = (wishlist, assetId, channelId, acceptNotMatchingChannelId) => {
  for (let i = 0; i < wishlist.length; i++) {
    const {
      [i]: { id, channelId: chId },
    } = wishlist;

    // Channel Id is null for items added in first version of wishlist (backward compatibility)
    if (id === assetId && (chId === channelId || (acceptNotMatchingChannelId && chId && chId !== channelId) || isUndefinedOrNull(chId))) {
      return i;
    }
  }

  return -1;
};

const deleteWishlist: (isSecondAttempt?: boolean, eTag?: string) => RequestResponseMethodDefinitionType =
  (isSecondAttempt, eTag) =>
  (dispatch: Dispatch, getState: () => CombinedReducers): Promise<any> => {
    const {
      ui: {
        wishlistETagCache: { wishlist: initialETag },
      },
    } = getState();
    const wishlist: NETGEM_API_WISHLIST = [];

    return dispatch(sendPersonalDataPostRequest(PersonalDataKey.Wishlist, { wishlist }, eTag ?? initialETag))
      .then((data: NETGEM_API_V8_REQUEST_RESPONSE) => {
        const { eTag: wishlistETag } = data;
        dispatch(updateWishlist(wishlist, wishlistETag));
        return Promise.resolve();
      })
      .catch((error: CustomNetworkError) => {
        const status = error.getStatus();
        if (!isSecondAttempt && (status === HttpStatus.Conflict || status === HttpStatus.PreconditionFailed)) {
          /*
           * Most probable cause: another device paired with the same account already updated the wishlist
           * So we send a GET request to retrieve the latest eTag, but we only try once to avoid infinite loop between multiple devices
           */
          dispatch(deleteWishlistETag());
          return dispatch(getWishlist()).then((newETag) => dispatch(deleteWishlist(true, newETag)));
        }

        return Promise.reject(error);
      });
  };

const sendWishlistUpdateRequest: (wishlist: NETGEM_API_WISHLIST, initialETag: ?string, signal?: AbortSignal, isSecondAttempt?: boolean) => RequestResponseMethodDefinitionType =
  (wishlist, initialETag, signal, isSecondAttempt) =>
  (dispatch: Dispatch): Promise<any> =>
    dispatch(sendPersonalDataPostRequest(PersonalDataKey.Wishlist, { wishlist }, initialETag, signal))
      .then((data: NETGEM_API_V8_REQUEST_RESPONSE) => {
        const { eTag: wishlistETag } = data;
        dispatch(updateWishlist(wishlist, wishlistETag));
        return Promise.resolve();
      })
      .catch((error: CustomNetworkError) => {
        const status = error.getStatus();
        if (!isSecondAttempt && (status === HttpStatus.Conflict || status === HttpStatus.PreconditionFailed)) {
          /*
           * Most probable cause: another device paired with the same account already updated the wishlist
           * So we send a GET request to retrieve the latest eTag, but we only try once to avoid infinite loop between multiple devices
           */
          dispatch(deleteWishlistETag());

          return dispatch(getWishlist(signal)).then((newETag) => dispatch(sendWishlistUpdateRequest(wishlist, newETag, signal, true)));
        }

        return Promise.reject(error);
      });

const addToWishlist: (assetId: string, channelId: string, signal?: AbortSignal, isSecondAttempt?: boolean) => RequestResponseMethodDefinitionType =
  (assetId, channelId, signal, isSecondAttempt) =>
  (dispatch: Dispatch, getState: () => CombinedReducers): Promise<any> => {
    const {
      ui: {
        wishlistETagCache: { wishlist: initialETag },
        wishlist,
      },
    } = getState();

    if (!wishlist) {
      // Wishlist not retrieved yet
      return Promise.resolve();
    }

    const assetIdIndex = findAssetId(wishlist, assetId, channelId);

    if (assetIdIndex > -1) {
      // Item already in wishlist (should not happen but could be a sync issue)
      return Promise.resolve();
    }

    // Add item
    const newWishlist = [
      ...wishlist,
      {
        channelId,
        date: AccurateTimestamp.nowAsIsoString(),
        id: assetId,
        type: getWishlistTypeFromId(assetId),
      },
    ];

    return dispatch(sendWishlistUpdateRequest(newWishlist, initialETag, signal, isSecondAttempt));
  };

const removeFromWishlist: (assetId: string, channelId: string, signal?: AbortSignal, isSecondAttempt?: boolean) => RequestResponseMethodDefinitionType =
  (assetId, channelId, signal, isSecondAttempt) =>
  (dispatch: Dispatch, getState: () => CombinedReducers): Promise<any> => {
    const {
      ui: {
        wishlistETagCache: { wishlist: initialETag },
        wishlist,
      },
    } = getState();

    if (!wishlist) {
      // Wishlist not retrieved yet
      return Promise.resolve();
    }

    let assetIdIndex = findAssetId(wishlist, assetId, channelId);

    if (assetIdIndex === -1) {
      // Item not found: let's search again while ignoring the channel Id in case the item was added in the first version (backward compatibility)
      assetIdIndex = findAssetId(wishlist, assetId, channelId, true);

      if (assetIdIndex === -1) {
        // Item not found in wishlist (not supposed to happen but could be a sync issue)
        return Promise.resolve();
      }
    }

    // Remove item
    const newWishlist = wishlist.filter((item, index) => index !== assetIdIndex);

    return dispatch(sendWishlistUpdateRequest(newWishlist, initialETag, signal, isSecondAttempt));
  };

export { addToWishlist, deleteWishlist, getWishlist, removeFromWishlist };
