/* @flow */

import { type AppConfigurationFeatures, ExternalContentDisplayType, type LocalesType, stringToExternalContentDisplayType } from '../types/types';
import {
  FEATURE_NPVR,
  FEATURE_SUBSCRIPTION,
  FEATURE_TV,
  FEATURE_VOD,
  REDUX_MSG_APP_CONFIGURATION_UPDATE,
  REDUX_MSG_BO_PRODUCT_ID_UPDATE,
  REDUX_MSG_BO_SETTINGS_UPDATE,
  REDUX_MSG_BO_V1_FORCE,
  REDUX_MSG_CHANNELS_UPDATE,
  REDUX_MSG_COMMERCIAL_OFFERS_UPDATE,
  REDUX_MSG_DEBUG_MODE_TOGGLE,
  REDUX_MSG_DEBUG_MODE_UPDATE,
  REDUX_MSG_DEFAULT_RIGHTS_UPDATE,
  REDUX_MSG_DEVICE_OS_UPDATE,
  REDUX_MSG_DEVICE_SETTINGS_UPDATE,
  REDUX_MSG_DISTRIBUTOR_APPKEYS_UPDATE,
  REDUX_MSG_ENABLE_PACK_PURCHASE_API,
  REDUX_MSG_LIST_ALIAS_UPDATE,
  REDUX_MSG_MAIN_DISTRIBUTOR_UPDATE,
  REDUX_MSG_MESH_STATE_UPDATE,
  REDUX_MSG_PACK_PURCHASE_TOGGLE,
  REDUX_MSG_PLAYER_STREAM_PRIORITIES_UPDATE,
  REDUX_MSG_USER_RIGHTS_UPDATE,
  REDUX_MSG_UTM_PARAMETERS_UPDATE,
  REDUX_MSG_VERSION_BO_UPDATE,
  REDUX_MSG_VERSION_CRM_BACK_UPDATE,
  REDUX_MSG_VERSION_CRM_FRONT_UPDATE,
  REDUX_MSG_VOD_STATE_UPDATE,
} from '../constants';
import { type KeyValuePair, type Undefined, isUndefinedOrNull } from '@ntg/utils/dist/types';
import { parseBoolean, parseFloat } from '../../../helpers/jsHelpers/parser';
import { type AppConfigurationAction } from '../actions';
import type { ChannelMap } from '../../../libs/netgemLibrary/v8/types/Channel';
import { DEFAULT_MAX_BITRATE } from '../../../helpers/ui/constants';
import type { DmsSettingMap } from '../../../libs/netgemLibrary/dms/types/DeviceInfoSettings';
import type { NETGEM_API_V8_RIGHTS } from '../../../libs/netgemLibrary/v8/types/Rights';
import Notifier from '../../../helpers/notification/notifier';
import type { STREAM_PRIORITIES_TYPE } from '../../../helpers/ui/metadata/Types';
import SentryWrapper from '../../../helpers/debug/sentry';
import { decryptDidomiKey } from '../../../helpers/crypto/crypto';
import { isRunningOnLocalhost } from '../../../helpers/jsHelpers/environment';
import log from 'loglevel';
import { logDebug } from '../../../helpers/debug/debug';
import { produce } from 'immer';

export type AppConfigurationState = {|
  applicationName: string,
  boSettings: KeyValuePair<string>,
  configuration: KeyValuePair<string>,
  defaultSubtitles: string,
  deviceChannels: ChannelMap,
  deviceOS: string,
  deviceSettings: DmsSettingMap,
  devicesUrl: {|
    chrome: string,
    edge: string,
    firefox: string,
  |},
  distributorAppKeys: KeyValuePair<string>,
  features: AppConfigurationFeatures,
  hasFreeTrialButton: boolean,
  isAuthentInFullscreen: boolean,
  isBORunning: boolean,
  isDebugModeEnabled: boolean,
  isDmsMeshEnabled: boolean | null,
  isExternalCheckOutEnabled: boolean,
  isGuestModeAllowed: boolean,
  isMaxBitrateAllowed: boolean,
  isSubscriberIdShown: boolean,
  listAlias: string | null,
  listAliasChannelLists: KeyValuePair<Array<string>>,
  locales: LocalesType,
  localesDefault: KeyValuePair<Undefined<string>>,
  mainDistributorId: string | null,
  maxBitrate: number,
  playerStreamPriorities: STREAM_PRIORITIES_TYPE | null,
  realm: string,
  sessionId: string,
  subscriptionPresentationDisplay: ExternalContentDisplayType,
  rightsCommercialOffers: KeyValuePair<Array<string>> | null,
  rightsDefault: NETGEM_API_V8_RIGHTS | null,
  rightsUser: Array<string> | null,
  tagListAliases: KeyValuePair<string>,
  useBOV1ApiForced: boolean,
  useBOV2Api: boolean,
  usePackPurchaseApi: boolean,
  userAccountDisplay: ExternalContentDisplayType,
  utmParameters: KeyValuePair<string>,
  versionApp: string,
  versionAppShort: string,
  versionBO: string,
  versionCrmBack: string,
  versionCrmFront: string,
  wikipediaUrl: string,
|};

const InitialState: AppConfigurationState = {
  applicationName: process.env.REACT_APP_NAME || '',
  boSettings: {},
  configuration: {},
  defaultSubtitles: process.env.REACT_APP_DEFAULT_SUBTITLES || '',
  deviceChannels: {},
  deviceOS: '',
  deviceSettings: {},
  devicesUrl: {
    chrome: process.env.REACT_APP_CHROME_URL || '',
    edge: process.env.REACT_APP_EDGE_URL || '',
    firefox: process.env.REACT_APP_FIREFOX_URL || '',
  },
  distributorAppKeys: {},
  features: {
    [FEATURE_NPVR]: parseBoolean(process.env.REACT_APP_FEATURE_NPVR),
    [FEATURE_SUBSCRIPTION]: parseBoolean(process.env.REACT_APP_FEATURE_SUBSCRIPTION),
    [FEATURE_TV]: parseBoolean(process.env.REACT_APP_FEATURE_TV),
    [FEATURE_VOD]: parseBoolean(process.env.REACT_APP_FEATURE_VOD),
  },
  hasFreeTrialButton: parseBoolean(process.env.REACT_APP_HAS_FREE_TRIAL_BUTTON),
  isAuthentInFullscreen: parseBoolean(process.env.REACT_APP_FULLSCREEN_CRM),
  isBORunning: false,
  isDebugModeEnabled: parseBoolean(process.env.REACT_APP_DEBUG_MODE),
  isDmsMeshEnabled: null,
  isExternalCheckOutEnabled: parseBoolean(process.env.REACT_APP_EXTERNAL_CHECKOUT),
  isGuestModeAllowed: parseBoolean(process.env.REACT_APP_GUEST_MODE),
  isMaxBitrateAllowed: true,
  isSubscriberIdShown: parseBoolean(process.env.REACT_APP_SHOW_SUBSCRIBER_ID),
  listAlias: null,
  listAliasChannelLists: {},
  locales: {},
  localesDefault: { legalInformation: process.env.REACT_APP_LEGAL_INFORMATION },
  mainDistributorId: null,
  maxBitrate: DEFAULT_MAX_BITRATE,
  playerStreamPriorities: null,
  realm: process.env.REACT_APP_REALM || '',
  rightsCommercialOffers: null,
  rightsDefault: null,
  rightsUser: null,
  sessionId: crypto.randomUUID(),
  subscriptionPresentationDisplay: stringToExternalContentDisplayType(process.env.REACT_APP_SUBSCRIPTION_PRESENTATION_DISPLAY),
  tagListAliases: {},
  useBOV1ApiForced: false,
  useBOV2Api: false,
  usePackPurchaseApi: false,
  userAccountDisplay: stringToExternalContentDisplayType(process.env.REACT_APP_USER_ACCOUNT_DISPLAY),
  utmParameters: {},
  versionApp: process.env.REACT_APP_VERSION || 'local',
  versionAppShort: process.env.REACT_APP_VERSION || 'local',
  versionBO: '',
  versionCrmBack: '',
  versionCrmFront: '',
  wikipediaUrl: process.env.REACT_APP_WIKIPEDIA_LICENSE_URL || '',
};

if (!isRunningOnLocalhost()) {
  SentryWrapper.initialize(InitialState.sessionId);
}

const DefaultAction: AppConfigurationAction = { type: null };

const appConfigurationReducer: (state: AppConfigurationState, action: AppConfigurationAction) => AppConfigurationState =
  // eslint-disable-next-line max-lines-per-function, complexity
  produce((draft = InitialState, action = DefaultAction) => {
    switch (action.type) {
      case REDUX_MSG_CHANNELS_UPDATE: {
        draft.deviceChannels = action.channels;
        break;
      }

      case REDUX_MSG_DEBUG_MODE_TOGGLE: {
        const newIsDebugModeEnabled = !draft.isDebugModeEnabled;
        draft.isDebugModeEnabled = newIsDebugModeEnabled;
        Notifier.isDebugModeEnabled = newIsDebugModeEnabled;
        if (newIsDebugModeEnabled) {
          // Enabling debug mode automatically switches log level to TRACE
          log.setLevel(log.levels.TRACE, true);
        } else if (process.env.REACT_APP_ENV_NAME === '') {
          // In production or staging, disabling debug mode automatically switches log level to WARN
          log.setLevel(log.levels.WARN, true);
        }
        break;
      }

      case REDUX_MSG_DEBUG_MODE_UPDATE: {
        draft.isDebugModeEnabled = action.isDebugModeEnabled;
        Notifier.isDebugModeEnabled = action.isDebugModeEnabled;
        if (action.isDebugModeEnabled) {
          // Enabling debug mode automatically switches log level to TRACE
          log.setLevel(log.levels.TRACE, true);
        } else if (process.env.REACT_APP_ENV_NAME === '') {
          // In production or staging, disabling debug mode automatically switches log level to WARN
          log.setLevel(log.levels.WARN, true);
        }
        break;
      }

      case REDUX_MSG_DEVICE_OS_UPDATE: {
        draft.deviceOS = action.deviceOS;
        break;
      }

      case REDUX_MSG_DEVICE_SETTINGS_UPDATE: {
        draft.deviceSettings = action.settings;
        break;
      }

      case REDUX_MSG_BO_SETTINGS_UPDATE: {
        draft.boSettings = action.settings;
        break;
      }

      case REDUX_MSG_BO_PRODUCT_ID_UPDATE: {
        draft.boSettings.productId = action.productId.toString();
        break;
      }

      case REDUX_MSG_DISTRIBUTOR_APPKEYS_UPDATE: {
        draft.distributorAppKeys = action.distributorAppKeys;
        break;
      }

      case REDUX_MSG_LIST_ALIAS_UPDATE: {
        if (action.tag !== null) {
          // Channel list with specific tag
          draft.tagListAliases[(action.tag: string)] = action.listAlias;
          draft.listAliasChannelLists[action.listAlias] = action.channelList;
          break;
        }

        // Entire channel list
        draft.listAlias = action.listAlias;
        break;
      }

      case REDUX_MSG_MAIN_DISTRIBUTOR_UPDATE: {
        draft.mainDistributorId = action.distributorId;
        break;
      }

      case REDUX_MSG_MESH_STATE_UPDATE: {
        draft.isDmsMeshEnabled = action.isMeshEnabled;
        break;
      }

      case REDUX_MSG_PLAYER_STREAM_PRIORITIES_UPDATE: {
        draft.playerStreamPriorities = action.priorities;
        break;
      }

      case REDUX_MSG_APP_CONFIGURATION_UPDATE: {
        const { configuration, didomi, locales } = action;

        draft.configuration = configuration;

        if (didomi !== undefined) {
          const { apiKey, webNoticeId } = didomi;
          draft.configuration.didomiApiKey = decryptDidomiKey(apiKey);
          draft.configuration.didomiNoticeId = webNoticeId;
        }

        if (locales !== undefined) {
          draft.locales = locales;
        }

        const {
          configuration: { boV2ServiceInfoUrl, greenStreamingMaxMpbs },
        } = draft;

        draft.useBOV2Api = !draft.useBOV1ApiForced && !isUndefinedOrNull(boV2ServiceInfoUrl) && boV2ServiceInfoUrl !== '';
        logDebug(`BO API version: ${draft.useBOV2Api ? 'v2' : 'v1'}`);

        const maxBitrate = parseFloat(greenStreamingMaxMpbs, DEFAULT_MAX_BITRATE);
        draft.isMaxBitrateAllowed = maxBitrate !== 0;
        draft.maxBitrate = maxBitrate;

        break;
      }

      case REDUX_MSG_BO_V1_FORCE: {
        draft.useBOV1ApiForced = true;
        logDebug('BO API v1 is forced');
        break;
      }

      case REDUX_MSG_ENABLE_PACK_PURCHASE_API: {
        // Pack purchase is enabled if BO V2 API is enabled and purchase API has been found
        draft.usePackPurchaseApi = draft.useBOV2Api;
        logDebug(`Pack purchase: ${draft.usePackPurchaseApi ? 'enabled' : 'disabled'}`);
        break;
      }

      case REDUX_MSG_PACK_PURCHASE_TOGGLE: {
        if (draft.useBOV2Api) {
          draft.usePackPurchaseApi = !draft.usePackPurchaseApi;
          logDebug(`Pack purchase: ${draft.usePackPurchaseApi ? 'enabled' : 'disabled'}`);
        } else {
          logDebug('Pack purchase cannot be enabled since BO API version is v1');
        }
        break;
      }

      case REDUX_MSG_VOD_STATE_UPDATE: {
        draft.isBORunning = draft.features[FEATURE_VOD] && action.isBoRunning;
        break;
      }

      case REDUX_MSG_VERSION_BO_UPDATE: {
        draft.versionBO = action.version;
        break;
      }

      case REDUX_MSG_VERSION_CRM_BACK_UPDATE: {
        draft.versionCrmBack = action.version;
        break;
      }

      case REDUX_MSG_VERSION_CRM_FRONT_UPDATE: {
        draft.versionCrmFront = action.version;
        break;
      }

      case REDUX_MSG_COMMERCIAL_OFFERS_UPDATE: {
        draft.rightsCommercialOffers = action.commercialOffers;
        break;
      }

      case REDUX_MSG_DEFAULT_RIGHTS_UPDATE: {
        draft.rightsDefault = action.rights;
        break;
      }

      case REDUX_MSG_USER_RIGHTS_UPDATE: {
        draft.rightsUser = action.rights;
        break;
      }

      case REDUX_MSG_UTM_PARAMETERS_UPDATE: {
        draft.utmParameters = action.utmParameters;
        break;
      }

      default:
    }
  }, InitialState);

export default appConfigurationReducer;
