/* @flow */

import { HeaderName, HeaderValue } from '../constants/Headers';
import type { CombinedReducers } from '../../../../redux/reducers';
import { HttpMethod } from '../types/HttpMethod';
import { buildHeaderList } from '../helpers/RequestHeaders';
import sendV8Request from './v8';

const sendV8GenericRequest: (
  url: string,
  method: HttpMethod,
  authenticationToken: string | null,
  state: ?CombinedReducers,
  signal: ?AbortSignal,
  responseType: ?string,
  eTag: ?string,
) => Promise<any> = (url, method, authenticationToken, state, signal, responseType, eTag) => {
  const headers = [];

  // Empty string means no response expected
  if (responseType !== '') {
    headers.push(HeaderName.Accept);
    if (responseType === undefined || responseType === null) {
      // Default is 'application/json'
      headers.push(HeaderValue.ApplicationJson);
    } else {
      // Add provided accepted type
      headers.push(responseType);
    }
  }

  if (eTag) {
    headers.push(!method || method === HttpMethod.GET ? HeaderName.IfNoneMatch : HeaderName.IfMatch);
    headers.push(eTag);
  }

  const headerList = buildHeaderList(...headers);
  return sendV8Request(url, authenticationToken, null, headerList, state, signal, null, method);
};

export { sendV8GenericRequest };
