/* @flow */

import { ISO8601_DURATION_ZERO, MILLISECONDS_PER_SECOND, MIN_DATE_ISO } from '../../../../helpers/dateTime/Format';
import {
  ItemContent,
  ItemType,
  type NETGEM_API_V8_FEED_ITEM,
  type NETGEM_API_V8_FEED_RAW_ITEM,
  type NETGEM_API_V8_ITEM_LOCATION,
  type NETGEM_API_V8_ITEM_LOCATION_TYPE,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_APP,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_EST,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_SVOD,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_TVOD,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_VOD,
  type NETGEM_API_V8_MINIMAL_FEED_ITEM,
} from '../types/FeedItem';
import { type NETGEM_API_V8_AUTHENT_REALM, NETGEM_API_V8_AUTHENT_REALM_NETGEM } from '../types/Realm';
import type { NETGEM_API_V8_PARENTAL_GUIDANCE } from '../types/ParentalGuidance';
import type { NETGEM_API_V8_RAW_FEED } from '../types/FeedResult';
import type { Undefined } from '@ntg/utils/dist/types';
import { addISO8601Duration } from './Date';
import { getBestLocationForItem } from './Score';

export enum SelectionStrategy {
  BestLocation,
  FirstLocation,
}

const getIdType: (id: string) => string | null = (id) => {
  const result: Array<string> | null = /^([a-zA-Z]+)(:\/\/)/u.exec(id);
  return result && result.length > 1 ? result[1] : null;
};

const getLocationType: (id?: string) => NETGEM_API_V8_ITEM_LOCATION_TYPE | null = (id) => {
  if (!id) {
    return null;
  }

  const type = getIdType(id);
  if (type) {
    return ((type: any): NETGEM_API_V8_ITEM_LOCATION_TYPE);
  }
  return null;
};

const getItemType: (id: string) => ItemType | null = (id) => {
  const type = getIdType(id);
  if (type) {
    return ((type: any): ItemType);
  }
  return null;
};

const getFirstLocation: (item: NETGEM_API_V8_MINIMAL_FEED_ITEM) => NETGEM_API_V8_ITEM_LOCATION | null = (item) => {
  const { locations } = getFirstElement(item);
  return locations && locations.length > 0 ? locations[0] : null;
};

const getFirstElement: (item: NETGEM_API_V8_MINIMAL_FEED_ITEM) => NETGEM_API_V8_MINIMAL_FEED_ITEM = (item) => {
  const stack: Array<NETGEM_API_V8_MINIMAL_FEED_ITEM> = [item];

  while (stack.length > 0) {
    const rawItem = stack.pop();

    // Since flowjs v0.239.0, "rawItem" is said to be potentially undefined (which is impossible, here)
    if (!rawItem) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const { elements, locations } = rawItem;

    if (elements) {
      for (let i = 0; i < elements.length; i++) {
        stack.unshift(elements[i]);
      }
    } else if (locations) {
      return rawItem;
    }
  }

  return item;
};

// Return start and end times in seconds
const getLocationStartAndEndTime: (location: NETGEM_API_V8_ITEM_LOCATION) => { endTime: number, startTime: number } = (location) => {
  let { scheduledEventStartDate: startDate, scheduledEventDuration: duration } = location;

  if (!startDate) {
    ({ startDate } = location);
    if (!startDate) {
      startDate = MIN_DATE_ISO;
    }
  }

  if (!duration) {
    ({ duration } = location);
    if (!duration) {
      duration = ISO8601_DURATION_ZERO;
    }
  }

  const startTime = new Date(startDate).getTime() / MILLISECONDS_PER_SECOND;
  const endTime = addISO8601Duration(startDate, duration).getTime() / MILLISECONDS_PER_SECOND;

  return {
    endTime,
    startTime,
  };
};

const getFirstLocationForItem: (item: NETGEM_API_V8_FEED_RAW_ITEM) => { location: NETGEM_API_V8_ITEM_LOCATION | null, programId: string | null } = (item) => {
  const stack = [item];

  while (stack.length > 0) {
    const rawItem = stack.pop();

    // Since flowjs v0.239.0, "item" is said to be potentially undefined (which is impossible, here)
    if (!rawItem) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const { elements, id, locations } = rawItem;

    if (elements) {
      for (let i = 0; i < elements.length; i++) {
        stack.unshift(elements[i]);
      }
    } else if (locations && locations.length > 0) {
      return {
        location: locations[0],
        programId: id,
      };
    }
  }

  return {
    location: null,
    programId: null,
  };
};

// Default value for selectionStrategy is SelectionStrategy.BestLocation
const buildFeedItem: (item: NETGEM_API_V8_FEED_RAW_ITEM, selectionStrategy?: SelectionStrategy) => NETGEM_API_V8_FEED_ITEM | null = (item, selectionStrategy) => {
  // Best location will be used to set selectedLocation property
  const { location: selectedLocation, programId: selectedProgramId } = selectionStrategy === SelectionStrategy.FirstLocation ? getFirstLocationForItem(item) : getBestLocationForItem(item);

  if (!selectedLocation || !selectedProgramId) {
    return null;
  }

  const { elements, id, locations, parentalGuidance, purchasable } = item;

  const { id: selectedLocationId, score: selectedLocationScore } = selectedLocation;
  const locType = getLocationType(selectedLocationId);
  const { endTime, startTime } = getLocationStartAndEndTime(selectedLocation);
  const type = getItemType(id);
  const seriesId = type === ItemType.Series ? id : null;

  const feedItem: NETGEM_API_V8_FEED_ITEM = {
    endTime,
    id,
    locType,
    selectedLocation,
    selectedProgramId,
    seriesId,
    startTime,
    type,
  };

  if (elements) {
    feedItem.elements = deepCopyElements(elements);
  }

  if (locations) {
    feedItem.locations = deepCopyLocations(locations);
  }

  if (parentalGuidance) {
    feedItem.parentalGuidance = deepCopyParentalGuidance(parentalGuidance);
  }

  if (purchasable !== undefined) {
    feedItem.purchasable = purchasable;
  }

  if (selectedLocationScore) {
    feedItem.score = [...selectedLocationScore];
  }

  return feedItem;
};

const getFeedItemChannelIds: (item: NETGEM_API_V8_FEED_RAW_ITEM) => Set<string> = (item) => {
  const channelIds = new Set<string>();
  const stack = [item];

  while (stack.length > 0) {
    const rawItem = stack.pop();

    // Since flowjs v0.239.0, "rawItem" is said to be potentially undefined (which is impossible, here)
    if (!rawItem) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const { elements, locations } = rawItem;

    if (elements) {
      for (let i = 0; i < elements.length; ++i) {
        stack.unshift(elements[i]);
      }
    } else if (locations) {
      for (let j = 0; j < locations.length; ++j) {
        const { [j]: location } = locations;
        const { channelId } = location;
        if (channelId) {
          channelIds.add(channelId);
        }
      }
    }
  }

  return channelIds;
};

const deepCopyLocationsForChannel: (locations: Array<NETGEM_API_V8_ITEM_LOCATION>, selectedChannelId: string) => Array<NETGEM_API_V8_ITEM_LOCATION> = (locations, selectedChannelId) => {
  const filteredLocations = locations.filter((loc) => loc.channelId === selectedChannelId);
  return deepCopyLocations(filteredLocations);
};

const deepCopyElementsForChannel: (elements: NETGEM_API_V8_RAW_FEED, selectedChannelId: string) => NETGEM_API_V8_RAW_FEED = (elements, selectedChannelId) =>
  elements.map((el) => deepCopyFeedItemForChannel(el, selectedChannelId));

const deepCopyFeedItemForChannel: (item: NETGEM_API_V8_FEED_RAW_ITEM, selectedChannelId: string) => NETGEM_API_V8_FEED_RAW_ITEM = (item, selectedChannelId) => {
  const { elements, id, locations, parentalGuidance, score } = item;

  const clonedItem: NETGEM_API_V8_FEED_RAW_ITEM = { id };

  if (elements) {
    clonedItem.elements = deepCopyElementsForChannel(elements, selectedChannelId);
  }

  if (locations) {
    const clonedLocations = deepCopyLocationsForChannel(locations, selectedChannelId);
    if (clonedLocations.length > 0) {
      // Due to the channel filtering, locations can be reduced to an empty array, and in this case, we let it undefined
      clonedItem.locations = clonedLocations;
    }
  }

  if (parentalGuidance) {
    clonedItem.parentalGuidance = deepCopyParentalGuidance(parentalGuidance);
  }

  if (score) {
    clonedItem.score = [...score];
  }

  return clonedItem;
};

const splitFeedItemByChannels: (item: NETGEM_API_V8_FEED_RAW_ITEM) => Array<{| channelId: string, rawItem: NETGEM_API_V8_FEED_RAW_ITEM |}> = (item) => {
  const channelIds = getFeedItemChannelIds(item);

  if (channelIds.size === 1) {
    // Only one channel in this item
    return [{ channelId: [...channelIds.keys()][0], rawItem: item }];
  }

  // More than one channel in this item: let's create copies
  return [...channelIds].map((channelId) => {
    return { channelId, rawItem: deepCopyFeedItemForChannel(item, channelId) };
  });
};

const deepCopyParentalGuidance: (parentalGuidance: NETGEM_API_V8_PARENTAL_GUIDANCE) => NETGEM_API_V8_PARENTAL_GUIDANCE = (parentalGuidance) => {
  const { explanatoryText, minimumAge } = parentalGuidance;

  const clonedParentalGuidance: NETGEM_API_V8_PARENTAL_GUIDANCE = { minimumAge };

  if (explanatoryText) {
    clonedParentalGuidance.explanatoryText = [...explanatoryText];
  }

  return clonedParentalGuidance;
};

const deepCopyElements: (elements: NETGEM_API_V8_RAW_FEED) => NETGEM_API_V8_RAW_FEED = (elements) =>
  elements.map(({ elements: itemElements, id, locations, parentalGuidance, purchasable, score }) => {
    const clonedItem: NETGEM_API_V8_FEED_RAW_ITEM = { id };

    if (itemElements) {
      clonedItem.elements = deepCopyElements(itemElements);
    }

    if (locations) {
      clonedItem.locations = deepCopyLocations(locations);
    }

    if (parentalGuidance) {
      clonedItem.parentalGuidance = deepCopyParentalGuidance(parentalGuidance);
    }

    if (purchasable !== undefined) {
      clonedItem.purchasable = purchasable;
    }

    if (score) {
      clonedItem.score = [...score];
    }

    return clonedItem;
  });
const deepCopyLocations: (locations: Array<NETGEM_API_V8_ITEM_LOCATION>) => Array<NETGEM_API_V8_ITEM_LOCATION> = (locations) =>
  locations.map(
    ({ availabilityEndDate, availabilityStartDate, channelId, duration, id, parentalGuidance, previewAvailabilityStartDate, scheduledEventDuration, scheduledEventStartDate, score, startDate }) => {
      const clonedLocation: NETGEM_API_V8_ITEM_LOCATION = { id, score: [...score] };

      if (availabilityEndDate) {
        clonedLocation.availabilityEndDate = availabilityEndDate;
      }

      if (availabilityStartDate) {
        clonedLocation.availabilityStartDate = availabilityStartDate;
      }

      if (channelId) {
        clonedLocation.channelId = channelId;
      }

      if (duration) {
        clonedLocation.duration = duration;
      }

      if (parentalGuidance) {
        clonedLocation.parentalGuidance = deepCopyParentalGuidance(parentalGuidance);
      }

      if (previewAvailabilityStartDate) {
        clonedLocation.previewAvailabilityStartDate = previewAvailabilityStartDate;
      }

      if (scheduledEventDuration) {
        clonedLocation.scheduledEventDuration = scheduledEventDuration;
      }

      if (scheduledEventStartDate) {
        clonedLocation.scheduledEventStartDate = scheduledEventStartDate;
      }

      if (startDate) {
        clonedLocation.startDate = startDate;
      }

      return clonedLocation;
    },
  );

const deepCopyRawItem: (item: NETGEM_API_V8_FEED_RAW_ITEM) => NETGEM_API_V8_FEED_RAW_ITEM = (item) => {
  const { elements, id, locations, parentalGuidance, purchasable, score } = item;

  const clonedItem: NETGEM_API_V8_FEED_RAW_ITEM = { id };

  if (elements) {
    clonedItem.elements = deepCopyElements(elements);
  }

  if (locations) {
    clonedItem.locations = deepCopyLocations(locations);
  }

  if (parentalGuidance) {
    clonedItem.parentalGuidance = deepCopyParentalGuidance(parentalGuidance);
  }

  if (purchasable !== undefined) {
    clonedItem.purchasable = purchasable;
  }

  if (score) {
    clonedItem.score = [...score];
  }

  return clonedItem;
};

const findLocationInLocations: (locationId: string, locations?: Array<NETGEM_API_V8_ITEM_LOCATION>) => Undefined<NETGEM_API_V8_ITEM_LOCATION> = (locationId, locations) => {
  if (!locations) {
    return undefined;
  }

  for (const loc of locations) {
    if (loc.id === locationId) {
      return loc;
    }
  }

  return undefined;
};

const findLocationOrDefault: (locationId: string, itemLocations?: Array<NETGEM_API_V8_ITEM_LOCATION>, itemElements?: NETGEM_API_V8_RAW_FEED) => Undefined<NETGEM_API_V8_ITEM_LOCATION> = (
  locationId,
  itemLocations,
  itemElements,
) => {
  const location = findLocationInLocations(locationId, itemLocations);

  if (location !== undefined) {
    return location;
  }

  let defaultLocation = itemLocations?.[0];

  if (itemElements === undefined) {
    return defaultLocation;
  }

  const stack = [...itemElements];

  while (stack.length > 0) {
    const item = stack.pop();

    // Since flowjs v0.239.0, "item" is said to be potentially undefined (which is impossible, here)
    if (!item) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const { elements, locations } = item;

    if (elements) {
      for (let i = 0; i < elements.length; i++) {
        stack.unshift(elements[i]);
      }
    } else if (locations) {
      if (locations.length > 0 && defaultLocation === null) {
        [defaultLocation] = locations;
      }

      const loc = findLocationInLocations(locationId, locations);
      if (loc !== undefined) {
        return loc;
      }
    }
  }

  return defaultLocation;
};

const deepCopyItem: (item: NETGEM_API_V8_FEED_ITEM) => NETGEM_API_V8_FEED_ITEM = (item) => {
  const { elements, endTime, id, locType, locations, parentalGuidance, purchasable, score, selectedLocation, selectedProgramId, seriesId, startTime, type } = item;

  const clonedElements = elements ? deepCopyElements(elements) : undefined;
  const clonedLocations = locations ? deepCopyLocations(locations) : undefined;
  const clonedSelectedLocation = findLocationOrDefault(selectedLocation.id, clonedLocations, clonedElements);

  const clonedItem: NETGEM_API_V8_FEED_ITEM = {
    endTime,
    id,
    locType,
    // Following nullish coalescing is only there to calm down Flow, but clonedSelectedLocation cannot theoretically be undefined since the cloned item necessarily has at least one location
    selectedLocation: clonedSelectedLocation ?? selectedLocation,
    selectedProgramId,
    seriesId,
    startTime,
    type,
  };

  if (clonedElements) {
    clonedItem.elements = clonedElements;
  }

  if (clonedLocations) {
    clonedItem.locations = clonedLocations;
  }

  if (parentalGuidance) {
    clonedItem.parentalGuidance = deepCopyParentalGuidance(parentalGuidance);
  }

  if (purchasable !== undefined) {
    clonedItem.purchasable = purchasable;
  }

  if (score) {
    clonedItem.score = [...score];
  }

  return clonedItem;
};

const getItemContentType: (item: NETGEM_API_V8_FEED_ITEM, authority?: NETGEM_API_V8_AUTHENT_REALM) => ItemContent = (item, authority) => {
  const { locType } = item;

  if (locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_SVOD) {
    if (authority === NETGEM_API_V8_AUTHENT_REALM_NETGEM) {
      return ItemContent.NetgemSVOD;
    }

    return ItemContent.VODOrDeeplink;
  }

  if (
    locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_APP ||
    locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_EST ||
    locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_TVOD ||
    locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_VOD
  ) {
    return ItemContent.VODOrDeeplink;
  }

  return ItemContent.TV;
};

export { buildFeedItem, deepCopyItem, deepCopyRawItem, getFirstLocation, getItemContentType, getItemType, getLocationStartAndEndTime, getLocationType, splitFeedItemByChannels };
