/* @flow */

import type { NETGEM_API_V8_FEED } from '../../../libs/netgemLibrary/v8/types/FeedItem';
import type { SECTION_PAGINATION } from './types';

const createSectionPagination: (feed: NETGEM_API_V8_FEED, itemWidth: number, itemCountPerPage: number, previousPageIndex: number, previousItemCountPerPage?: number) => SECTION_PAGINATION = (
  feed,
  itemWidth,
  itemCountPerPage,
  previousPageIndex,
  previousItemCountPerPage,
) => {
  const { length: itemCount } = feed;

  if (itemCount === 0) {
    return {
      maxPageIndex: -1,
      pageIndex: 0,
      sliderPositions: [],
    };
  }

  const maxPageIndex = Math.ceil(itemCount / itemCountPerPage) - 1;
  const sliderPositions = [];

  for (let i: number = 0; i <= maxPageIndex; i++) {
    sliderPositions.push(-(i * itemCountPerPage * itemWidth));
  }

  // Compute new pageIndex so that the same tile remains as the first entirely visible one (if possible)
  const mostLeftItemIndex = previousItemCountPerPage !== undefined ? previousPageIndex * previousItemCountPerPage : 0;
  const desiredPageIndex = Math.floor(mostLeftItemIndex / itemCountPerPage);
  const pageIndex = Math.min(desiredPageIndex, maxPageIndex);

  return {
    maxPageIndex,
    pageIndex,
    sliderPositions,
  };
};

export { createSectionPagination };
