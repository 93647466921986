/* @flow */

import {
  ItemContent,
  type NETGEM_API_V8_FEED_ITEM,
  type NETGEM_API_V8_ITEM_LOCATION_TYPE,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_EST,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_SVOD,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_TVOD,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_VOD,
} from '../../libs/netgemLibrary/v8/types/FeedItem';
import { LEGACY_DEEPLINK_TEMPLATE_NETGEM, LEGACY_DEEPLINK_TEMPLATE_VF, SHORT_DEEPLINK_TEMPLATE_PROGRAM, SHORT_DEEPLINK_TEMPLATE_SERIES } from '../../components/avenue/item/ItemConstsAndTypes';
import { type NETGEM_API_V8_AUTHENT_REALM, NETGEM_API_V8_AUTHENT_REALM_NETGEM, NETGEM_API_V8_AUTHENT_REALM_VIDEOFUTUR } from '../../libs/netgemLibrary/v8/types/Realm';
import type { NETGEM_API_V8_METADATA_PROGRAM, NETGEM_API_V8_METADATA_SERIES } from '../../libs/netgemLibrary/v8/types/MetadataProgram';
import { logInfo, logWarning } from './debug';
import { APPLICATION_ID } from '../applicationCustomization/types';
import type { NETGEM_API_V8_METADATA_SCHEDULE } from '../../libs/netgemLibrary/v8/types/MetadataSchedule';
import type { NETGEM_API_V8_PURCHASE_INFO } from '../../libs/netgemLibrary/v8/types/PurchaseInfo';
import { extractDistributorId } from '../videofutur/metadata';
import { getDistributorId } from '../ui/item/distributor';

type DEEPLINK_GENERATION_DATA = {
  authority?: NETGEM_API_V8_AUTHENT_REALM,
  contentType: ItemContent,
  deviceOS: string,
  item: NETGEM_API_V8_FEED_ITEM,
  programMetadata: NETGEM_API_V8_METADATA_PROGRAM | null,
  purchaseInfo?: NETGEM_API_V8_PURCHASE_INFO | null,
  seriesMetadata: NETGEM_API_V8_METADATA_SERIES | null,
  vodLocationsMetadata: Array<NETGEM_API_V8_METADATA_SCHEDULE> | null,
};

const generateVFDeeplink = (data: DEEPLINK_GENERATION_DATA): string => {
  const {
    location: { host, protocol },
  } = window;
  const {
    deviceOS,
    item: {
      selectedLocation: { id },
    },
    programMetadata,
    purchaseInfo,
    seriesMetadata,
    vodLocationsMetadata,
  } = data;

  if (!programMetadata) {
    throw new Error('No program metadata');
  }

  const { providerInfo } = programMetadata;

  if (!providerInfo) {
    throw new Error('No provider info');
  }

  const distributorId = getDistributorId(vodLocationsMetadata, purchaseInfo, programMetadata) ?? extractDistributorId(id, deviceOS);

  if (!distributorId) {
    throw new Error('No distributor Id');
  }

  const { id: titId } = providerInfo;

  return LEGACY_DEEPLINK_TEMPLATE_VF.replace('<protocol>', protocol)
    .replace('<host>', host)
    .replace('<type>', seriesMetadata ? 'series' : 'program')
    .replace('<titId>', titId)
    .replace('<distributorId>', distributorId);
};

const generateDeeplinkInternal = (data: DEEPLINK_GENERATION_DATA, kind: Array<NETGEM_API_V8_ITEM_LOCATION_TYPE>): string => {
  const {
    location: { host, protocol },
  } = window;
  const {
    item: {
      id: itemId,
      selectedLocation: { channelId },
    },
    seriesMetadata,
  } = data;

  // Since flowjs v0.239.0, "id" is said to be potentially undefined (which is impossible, here)
  const id = itemId.split('/').pop() ?? '';

  return LEGACY_DEEPLINK_TEMPLATE_NETGEM.replace('<protocol>', protocol)
    .replace('<host>', host)
    .replace('<type>', seriesMetadata ? 'series' : 'program')
    .replace('<id>', id)
    .replace('<kind>', encodeURIComponent(encodeURIComponent(JSON.stringify(kind))))
    .replace('<channel>', encodeURIComponent(encodeURIComponent(channelId ?? '')));
};

const generateNetgemVodDeeplink = (data: DEEPLINK_GENERATION_DATA): string => generateDeeplinkInternal(data, [NETGEM_API_V8_ITEM_LOCATION_TYPE_VOD]);

const generateNetgemTVDeeplink = (data: DEEPLINK_GENERATION_DATA): string =>
  generateDeeplinkInternal(data, [NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT, NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP]);

const normalizeString = (s: string): string =>
  s
    .normalize('NFKD')
    .replace(/[\u0300-\u036f]/gu, '')
    .toLowerCase()
    .replace(/[^a-z0-9]+/gu, '-');

const getDomain = (): string => {
  switch (process.env.REACT_APP_ID) {
    case APPLICATION_ID.FranceChannel:
      return 'francechannel.tv';
    case APPLICATION_ID.Gaumont:
      return 'gaumontclassique.fr';
    case APPLICATION_ID.Premiere:
      return 'premieremax.com';
    case APPLICATION_ID.Viva:
      return `${process.env.REACT_APP_ENV_NAME !== '' ? '' : 'viva.'}videofutur.fr`;
    default:
      throw new Error(`Application "${process.env.REACT_APP_ID ?? '<unknown>'}" not supported`);
  }
};

const getVodDomain = (): string => `vod${process.env.REACT_APP_ENV_NAME !== '' ? 'test' : ''}.${getDomain()}`;

const getId = (data: DEEPLINK_GENERATION_DATA): string => {
  const {
    authority,
    item: { id },
    programMetadata,
  } = data;

  if (authority === NETGEM_API_V8_AUTHENT_REALM_NETGEM) {
    // FranceChannel SVOD
    return id.split('/').slice(-1)[0];
  }

  // All other cases

  if (!programMetadata) {
    throw new Error('No program metadata');
  }

  const {
    providerInfo: { id: titId },
  } = programMetadata;

  return titId;
};

const generateLegacyDeeplink = (data: DEEPLINK_GENERATION_DATA): string => {
  const {
    authority,
    contentType,
    item: { locType },
  } = data;
  if (authority === undefined) {
    throw new Error('No authority');
  }

  if (contentType === ItemContent.VODOrDeeplink || contentType === ItemContent.NetgemSVOD) {
    // VOD
    if (authority === NETGEM_API_V8_AUTHENT_REALM_VIDEOFUTUR) {
      // Videofutur content (TVOD or EST)
      return generateVFDeeplink(data);
    } else if (authority === NETGEM_API_V8_AUTHENT_REALM_NETGEM) {
      // Netgem content (e.g. FranceChannel SVOD)
      return generateNetgemVodDeeplink(data);
    }
    throw new Error(`Authority "${authority}" not supported`);
  }

  if (locType !== NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT && locType !== NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP) {
    throw new Error(`Location "${locType ?? '<unknown>'}" not supported`);
  }

  // TV: scheduled event or catchup
  return generateNetgemTVDeeplink(data);
};

const generateShortDeeplink = (data: DEEPLINK_GENERATION_DATA): string => {
  const { programMetadata, seriesMetadata } = data;

  const deeplinkPattern = seriesMetadata ? SHORT_DEEPLINK_TEMPLATE_SERIES : SHORT_DEEPLINK_TEMPLATE_PROGRAM;
  const titles = seriesMetadata ? seriesMetadata.titles : programMetadata?.titles;
  const title = titles?.[0].value;

  if (!title) {
    throw new Error('No title');
  }

  return deeplinkPattern.replace('<vod_domain>', getVodDomain()).replace('<title>', normalizeString(title)).replace('<id>', getId(data));
};

const generateDeeplink = (data: DEEPLINK_GENERATION_DATA) => {
  const {
    authority,
    item: { locType },
  } = data;

  if (
    locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_EST ||
    locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_TVOD ||
    locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_VOD ||
    (locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_SVOD && (authority === NETGEM_API_V8_AUTHENT_REALM_NETGEM || authority === NETGEM_API_V8_AUTHENT_REALM_VIDEOFUTUR))
  ) {
    // Short deeplinks only work for VOD items
    try {
      const deeplink = generateShortDeeplink(data);

      // Successfully generated
      logInfo('Short deeplink successfully generated:');
      logInfo(deeplink);

      return;
    } catch (error) {
      logWarning('Error generating short deeplink');
    }
  }

  // Fallback to long deeplinks
  try {
    const deeplink = generateLegacyDeeplink(data);

    // Successfully generated
    logInfo('Legacy deeplink successfully generated:');
    logInfo(deeplink);
  } catch (error) {
    logWarning('Error generating legacy deeplink');
  }
};

export { generateDeeplink };
