/* @flow */

import './Section.css';
import * as React from 'react';
import { type AllSettledPromises, SettledPromiseFulfilled } from '../../../helpers/jsHelpers/promise';
import type { BasicFunction, KeyValuePair } from '@ntg/utils/dist/types';
import {
  type CompleteSectionPropType,
  type DefaultProps,
  type LocalPlayedItemType,
  MAX_Z_INDEX,
  PAGE_DISPLAY_COUNT,
  type ReduxSectionDispatchToPropsType,
  type ReduxSectionReducerStateType,
  SWIPE,
  type SectionPropType,
  type SectionStateType,
  StandardSectionType,
} from './SectionConstsAndTypes';
import { EPG, getTileWidthPlusMargin } from '../../../helpers/ui/constants';
import { LocalFeed, type NETGEM_API_V8_SECTION } from '../../../libs/netgemLibrary/v8/types/Section';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import { type NETGEM_API_V8_FEED, type NETGEM_API_V8_FEED_RAW_ITEM, type NETGEM_API_V8_ITEM_LOCATION_TYPE } from '../../../libs/netgemLibrary/v8/types/FeedItem';
import type { NETGEM_API_VIEWINGHISTORY_LOCATION, NETGEM_API_VIEWINGHISTORY_PLAYED_ITEMS, VIEWING_HISTORY_TYPE } from '../../../libs/netgemLibrary/v8/types/ViewingHistory';
import { PictoArrowLeft, PictoArrowRight } from '@ntg/components/dist/pictos/Element';
import { SectionDisplayType, type SwipeEventData } from '../../../helpers/ui/section/types';
import { type SortAndFilterType, SortDirection, SortKind } from '../sortAndFilter/type';
import {
  checkSources,
  compareDate,
  disableHotKeys,
  enableHotKeys,
  getLocalPath,
  getPageStepFromKeyboardModifiers,
  getPlaceholderItemCount,
  getTimestampFromRange,
  getValuesFromQueryString,
  getViewingHistoryPromiseGenerator,
  getWishlistPromiseGenerator,
  isItemTypeMatching,
  locationTypeToQueryType,
  queryTypeToLocationType,
  showDebugInfo,
} from './helper';
import { filterFeed, filterViewingHistoryFeed, mergeFeeds, setFeedScore } from '../../../libs/netgemLibrary/v8/helpers/Feed';
import { getTileConfig, getTileTypeClass } from '../../../helpers/ui/section/tile';
import { resetGridSectionId, updateGridSectionId, updateSectionPageIndex } from '../../../redux/ui/actions';
import AccurateTimestamp from '../../../helpers/dateTime/AccurateTimestamp';
import ButtonBack from '../../buttons/ButtonBack';
import type { CombinedReducers } from '../../../redux/reducers';
import type { Dispatch } from '../../../redux/types/types';
import EpgManager from '../../../helpers/epg/epgManager';
import { FeedProviderKind } from '../../../libs/netgemLibrary/v8/types/Feed';
import Item from '../item/Item';
import { LoadableStatus } from '../../../helpers/loadable/loadable';
import { Localizer } from '@ntg/utils/dist/localization';
import type { NETGEM_API_V8_AGGREGATION_FEED } from '../../../libs/netgemLibrary/v8/types/AggregationFeed';
import type { NETGEM_API_V8_NTG_VIDEO_FEED } from '../../../libs/netgemLibrary/v8/types/NtgVideoFeed';
import PlaceholderItem from '../item/PlaceholderItem';
import { RegistrationType } from '../../../redux/appRegistration/types/types';
import SortAndFilter from '../sortAndFilter/SortAndFilter';
import Swipeable from '../../swipeable/swipeable';
import { type TILE_CONFIG_TYPE } from '../../../libs/netgemLibrary/v8/types/WidgetConfig';
import ViewingHistoryCache from '../../../helpers/viewingHistory/ViewingHistoryCache';
import { Waypoint } from 'react-waypoint';
import WishlistCache from '../../../helpers/wishlist/WishlistCache';
import { areFavoriteListsDifferent } from '../../../helpers/ui/section/comparisons';
import { cleanUpViewingHistory } from '../../../redux/netgemApi/actions/personalData/viewingHistory';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { createSectionPagination } from '../../../helpers/ui/section/Pagination';
import { getIso8601DateInSeconds } from '../../../helpers/dateTime/Format';
import { getLocationType } from '../../../libs/netgemLibrary/v8/helpers/Item';
import { getMostRecentlyPlayedEpisode } from '../../../helpers/viewingHistory/ViewingHistory';
import { getSectionChannels } from '../../../helpers/channel/helper';
import getTranslatedText from '../../../libs/netgemLibrary/v8/helpers/Lang';
import { ignoreIfAborted } from '../../../libs/netgemLibrary/helpers/cancellablePromise/promiseHelper';
import { isItemRecordingOrScheduledRecording } from '../../../helpers/npvr/recording';
import { produce } from 'immer';
import sendV8LocationCatchupForAssetRequest from '../../../redux/netgemApi/actions/v8/catchupForAsset';
import sendV8LocationEpgForAssetRequest from '../../../redux/netgemApi/actions/v8/epgForAsset';
import sendV8LocationVodForAssetRequest from '../../../redux/netgemApi/actions/v8/vodForAsset';
import sendV8SectionFeedRequest from '../../../redux/netgemApi/actions/v8/feed';

const InitialState = Object.freeze({
  displayType: SectionDisplayType.Regular,
  feed: null,
  isCollapsed: false,
  isDebugModePlus: false,
  isLive: false,
  isLoading: true,
  isSwiping: false,
  itemCountPerPage: -1,
  maxPageIndex: -1,
  pageIndex: 0,
  sortAndFilter: null,
  updateDimensionsNeeded: true,
});

class SectionView extends React.PureComponent<CompleteSectionPropType, SectionStateType> {
  abortController: AbortController;

  isVisible: boolean;

  liveFeedTimer: TimeoutID | null;

  sectionChannels: Set<string> | null;

  sectionName: string;

  sectionItemWidth: number;

  sectionType: ?StandardSectionType;

  slider: HTMLElement | null;

  sliderPositions: Array<number>;

  swipeTimeout: TimeoutID | null;

  tileConfig: TILE_CONFIG_TYPE;

  static defaultProps: DefaultProps = {
    cardData: null,
    hubItem: null,
    isGridAvenue: false,
    isInExploreModal: false,
    onItemClick: undefined,
    searchString: null,
  };

  constructor(props: CompleteSectionPropType) {
    super(props);

    const {
      cardData,
      gridSectionId,
      section,
      section: { id },
    } = props;
    const tileConfig = getTileConfig(section);
    const { type } = tileConfig;

    this.abortController = new AbortController();
    this.isVisible = false;
    this.liveFeedTimer = null;
    this.sectionChannels = null;
    this.sectionName = id;
    this.sectionItemWidth = getTileWidthPlusMargin(type);
    this.sectionType = null;
    this.slider = null;
    this.sliderPositions = [];
    this.swipeTimeout = null;
    this.tileConfig = tileConfig;

    const isCollapsed = !cardData && gridSectionId !== null && id !== gridSectionId;

    let displayType = SectionDisplayType.Regular;
    if (isCollapsed) {
      displayType = SectionDisplayType.Collapsed;
    } else if (id === gridSectionId) {
      displayType = SectionDisplayType.Grid;
    }

    this.state = {
      ...InitialState,
      displayType,
      isCollapsed,
    };
  }

  componentDidMount() {
    const { displayType } = this.state;

    Messenger.on(MessengerEvents.AVENUE_RESET, this.reset);

    window.addEventListener('resize', this.updateDimensions, { passive: true });

    if (displayType === SectionDisplayType.Grid) {
      enableHotKeys(this.handleExitGridViewHotKey);
    }

    this.loadData();
  }

  componentDidUpdate(prevProps: CompleteSectionPropType, prevState: SectionStateType) {
    const {
      favoriteList,
      gridSectionId,
      searchString,
      section: { id: sectionId },
      viewingHistory,
      viewingHistoryStatus,
      wishlist,
      wishlistStatus,
    } = this.props;
    const { displayType, isCollapsed, itemCountPerPage } = this.state;
    const { favoriteList: prevFavoriteList, gridSectionId: prevGridSectionId, searchString: prevSearchString, viewingHistory: prevViewingHistory, wishlist: prevWishlist } = prevProps;
    const { displayType: prevDisplayType, isCollapsed: prevIsCollapsed, itemCountPerPage: prevItemCountPerPage } = prevState;
    const { sectionType } = this;

    if (gridSectionId !== prevGridSectionId) {
      this.updateIsCollapsed();
    }

    if (sectionId === 'wishList_vod' && areFavoriteListsDifferent(prevFavoriteList, favoriteList)) {
      this.buildRegularSection(false);
    }

    if (displayType !== prevDisplayType) {
      if (displayType === SectionDisplayType.Grid) {
        enableHotKeys(this.handleExitGridViewHotKey);
      } else {
        disableHotKeys(this.handleExitGridViewHotKey);
      }
    }

    if (isCollapsed !== prevIsCollapsed) {
      if (isCollapsed) {
        this.collapse();
      } else {
        this.show();
      }
    }

    if (itemCountPerPage !== prevItemCountPerPage) {
      this.createPageList(prevItemCountPerPage);
    }

    if (sectionType === StandardSectionType.Wishlist && wishlistStatus !== LoadableStatus.NotInitialized && wishlist !== prevWishlist) {
      this.buildWishlistSection();
      return;
    }

    if (sectionType === StandardSectionType.ViewingHistory && viewingHistoryStatus !== LoadableStatus.NotInitialized && viewingHistory !== prevViewingHistory) {
      this.buildViewingHistorySection();
      return;
    }

    if (searchString !== prevSearchString) {
      this.loadData();
      return;
    }

    this.componentDidUpdateFeedUpdate(prevProps, prevState);
  }

  componentWillUnmount() {
    const { abortController, liveFeedTimer } = this;

    abortController.abort('Component Section will unmount');

    Messenger.off(MessengerEvents.AVENUE_RESET, this.reset);
    Messenger.off(MessengerEvents.REFRESH_RECORDINGS_SECTION, this.forceRefresh);
    window.removeEventListener('resize', this.updateDimensions, { passive: true });
    disableHotKeys(this.handleExitGridViewHotKey);

    this.resetSwipeTimeout();

    // Clear timer
    if (liveFeedTimer) {
      clearTimeout(liveFeedTimer);
    }
  }

  componentDidUpdateFeedUpdate = (prevProps: CompleteSectionPropType, prevState: SectionStateType) => {
    const {
      avenueIndex,
      dataLoadedCallback,
      hubItem,
      section: { id },
      sectionIndex,
    } = this.props;
    const { displayType, feed, isCollapsed } = this.state;
    const { avenueIndex: prevAvenueIndex, hubItem: prevHubItem } = prevProps;
    const { displayType: prevDisplayType, feed: prevFeed } = prevState;

    if (avenueIndex !== prevAvenueIndex || (hubItem !== prevHubItem && !hubItem) || (!prevFeed && feed) || (feed && feed.length !== prevFeed?.length)) {
      // Section has already been built in previous avenue or sub-avenue
      dataLoadedCallback(id, sectionIndex, avenueIndex, feed?.length ?? 0);
    }

    if (feed?.length === 0 && displayType === SectionDisplayType.Regular && prevDisplayType === SectionDisplayType.Grid) {
      // Section has been emptied while in grid view, it must be collapsed now it changed back to regular view
      this.collapse();
    }

    if (feed && prevFeed !== feed) {
      if (feed.length === 0) {
        // Feed changed from non-empty to empty
        if (displayType === SectionDisplayType.Grid) {
          // First exit grid view, then collapse section
          this.exitGridView();
        } else {
          // Collapse empty section
          this.collapse();
        }
        return;
      }

      // Feed is not empty

      if (!isCollapsed) {
        this.show();
      }

      if (!prevFeed || prevFeed.length !== feed.length) {
        // Feed has changed: re-render everything
        this.createPageList();
      }
    }
  };

  updateIsCollapsed: () => void = () => {
    const {
      gridSectionId,
      section: { id },
    } = this.props;

    this.setState({ isCollapsed: gridSectionId !== null && id !== gridSectionId });
  };

  resetSwipeTimeout: () => void = () => {
    if (this.swipeTimeout) {
      clearTimeout(this.swipeTimeout);
      this.swipeTimeout = null;
    }
  };

  // Exit grid view
  handleExitGridViewHotKey: (event: SyntheticKeyboardEvent<HTMLElement>) => void = (event) => {
    const { displayType } = this.state;

    if (displayType === SectionDisplayType.Grid) {
      event.preventDefault();
      event.stopPropagation();
      this.exitGridView();
    }
  };

  setEmptySectionLoaded: () => void = () => {
    this.setSectionLoaded([]);
  };

  setSectionLoaded: (feed: NETGEM_API_V8_FEED) => void = (feed) => {
    const {
      avenueIndex,
      dataLoadedCallback,
      section: { id },
      sectionIndex,
    } = this.props;

    this.setState(
      produce((draft) => {
        draft.feed = feed;
        draft.isLoading = false;
      }),
    );

    // Notify avenue and tell it how many items were loaded
    dataLoadedCallback(id, sectionIndex, avenueIndex, feed.length ?? 0);
  };

  updateLiveSection: () => void = () => {
    const { hubItem, section } = this.props;
    const { sectionChannels } = this;

    if (sectionChannels !== null && !EpgManager.isRefreshing()) {
      const newLiveFeed = EpgManager.getLiveFeed(section, sectionChannels, hubItem);

      this.setState(
        produce((draft) => {
          draft.feed = newLiveFeed;
          draft.isLoading = false;
        }),
      );
    }

    this.liveFeedTimer = setTimeout(this.updateLiveSection, EpgManager.isRefreshing() ? EPG.IntervalLiveFeedEpgRefreshing : EPG.IntervalLiveFeedEpgReady);
  };

  buildWishlistSection = (): void => {
    const {
      channels,
      localSendV8LocationCatchupForAssetRequest,
      localSendV8LocationEpgForAssetRequest,
      localSendV8LocationVodForAssetRequest,
      recordingsListFeed,
      section: {
        model,
        model: { scoring: globalScoring, slice: globalSlice },
      },
      sessionId,
      wishlist,
      wishlistStatus,
    } = this.props;
    const {
      abortController: { signal },
    } = this;

    if (wishlistStatus === LoadableStatus.NotInitialized) {
      // Not yet retrieved from server
      return;
    }

    if (wishlistStatus === LoadableStatus.Error || wishlist.length === 0) {
      this.setEmptySectionLoaded();
      return;
    }

    // Build promises for every source
    const promises: Array<Promise<any>> = [];
    let { sources } = ((model: any): NETGEM_API_V8_AGGREGATION_FEED);
    const now = AccurateTimestamp.now();

    // Check if feed model is not an aggregation of sources
    sources = checkSources(sources, model);

    if (sources.length === 0) {
      this.setEmptySectionLoaded();
      return;
    }

    for (let i = 0; i < sources.length; i++) {
      const {
        [i]: { uri },
      } = sources;
      const promiseGenerator = getWishlistPromiseGenerator(
        localSendV8LocationCatchupForAssetRequest,
        localSendV8LocationEpgForAssetRequest,
        localSendV8LocationVodForAssetRequest,
        recordingsListFeed,
        uri,
        now,
        signal,
      );
      wishlist.forEach((item) => promises.push(promiseGenerator(item)));
    }

    // Wait for all promises to resolve
    Promise.allSettled(promises).then((results: AllSettledPromises) => {
      if (signal.aborted || sources === undefined) {
        return;
      }

      const { length: itemCount } = wishlist;

      const feedArray = [];
      for (let i = 0; i < sources.length; i++) {
        const {
          [i]: { filter, scoring, slice, uri },
        } = sources;
        const startIndex = i * itemCount;
        const endIndex = startIndex + itemCount;
        const { q: expectedType } = getValuesFromQueryString(uri, ['q']);

        const sourceFeed = [];
        const addToSourceFeed = (item: NETGEM_API_V8_FEED_RAW_ITEM) => {
          if (isItemTypeMatching(item, expectedType)) {
            sourceFeed.push(item);
          }
        };

        for (let j = startIndex; j < endIndex; ++j) {
          const {
            [j]: { status, value },
          } = results;

          if (status === SettledPromiseFulfilled) {
            value?.result?.feed?.forEach(addToSourceFeed);
          }
        }

        if (sourceFeed.length > 0) {
          let filteredFeed: NETGEM_API_V8_FEED = filterFeed(sourceFeed, channels, filter, slice);
          if (scoring) {
            filteredFeed = setFeedScore(filteredFeed, scoring, { sessionId });
          }
          feedArray.push(filteredFeed);
        }
      }

      this.setSectionLoaded(mergeFeeds(feedArray, globalScoring, { sessionId }, globalSlice));
    });
  };

  parseViewingHistory: (viewingHistory: VIEWING_HISTORY_TYPE) => { playedItemIds: KeyValuePair<Set<string>>, playedItemsMap: KeyValuePair<LocalPlayedItemType>, playedSeries: Set<string> } = (
    viewingHistory,
  ) => {
    const playedItemIds: KeyValuePair<Set<string>> = {};
    const playedItemsMap: KeyValuePair<LocalPlayedItemType> = {};
    const playedSeries: Set<string> = new Set();

    viewingHistory.forEach((item) => {
      const { episodes, id, playeditems } = item;
      let itemsToAdd: NETGEM_API_VIEWINGHISTORY_PLAYED_ITEMS | null = null;

      if (episodes) {
        // Series
        playedSeries.add(id);

        const mostRecentlyPlayedEpisode = getMostRecentlyPlayedEpisode(episodes);
        const { playeditems: items } = mostRecentlyPlayedEpisode;
        if (items) {
          itemsToAdd = items;
        }
      } else if (playeditems) {
        // Program
        itemsToAdd = playeditems;
      }

      if (itemsToAdd) {
        Object.keys(itemsToAdd).forEach((locationId) => {
          const playedItem: ?NETGEM_API_VIEWINGHISTORY_LOCATION = itemsToAdd?.[locationId];
          const locationType = getLocationType(locationId);

          if (locationType) {
            const queryType = locationTypeToQueryType(locationType);
            if (queryType) {
              if (!playedItemIds[queryType]) {
                playedItemIds[queryType] = new Set<string>();
              }
              playedItemIds[queryType].add(locationId);

              // $FlowFixMe: prop-missing but "date" exists in playedItem (of type NETGEM_API_VIEWINGHISTORY_LOCATION)
              playedItemsMap[locationId] = {
                ...playedItem,
                id,
              };
            }
          }
        });
      }
    });

    return {
      playedItemIds,
      playedItemsMap,
      playedSeries,
    };
  };

  buildViewingHistoryFeedFromPromises: (
    sources: Array<NETGEM_API_V8_NTG_VIDEO_FEED>,
    promiseCountByType: KeyValuePair<number>,
    playedItemsMap: KeyValuePair<LocalPlayedItemType>,
    playedSeries: Set<string>,
    results: AllSettledPromises,
  ) => void = (sources, promiseCountByType, playedItemsMap, playedSeries, results) => {
    const {
      channels,
      localCleanUpViewingHistory,
      section: {
        model: { scoring: globalScoring, slice: globalSlice },
      },
      sessionId,
      viewingHistory,
      viewingHistoryStatus,
    } = this.props;

    const searchedLocationTypes = new Set<NETGEM_API_V8_ITEM_LOCATION_TYPE>();
    const feedArray = [];

    const noIdPlayedItemsMap: NETGEM_API_VIEWINGHISTORY_PLAYED_ITEMS = {};
    Object.entries(playedItemsMap).forEach(([key, value]) => {
      // Get all properties except id
      const { channelId, date, percent, position } = ((value: any): LocalPlayedItemType);
      noIdPlayedItemsMap[key] = {
        channelId,
        date,
        percent,
        position,
      };
    });

    let startIndex = 0;
    for (let i = 0; i < sources.length; i++) {
      const {
        [i]: { filter, scoring, slice, uri },
      } = sources;
      const { q: queryType } = getValuesFromQueryString(uri, ['q']);

      const locationType = queryTypeToLocationType(queryType);
      if (locationType) {
        searchedLocationTypes.add(locationType);
      }

      if (queryType && promiseCountByType[queryType]) {
        const endIndex = startIndex + promiseCountByType[queryType];
        const sourceFeed = [];
        const addToSourceFeed = (item: NETGEM_API_V8_FEED_RAW_ITEM) => sourceFeed.push(item);

        for (let j = startIndex; j < endIndex; ++j) {
          const { [j]: result } = results;
          const { status, value } = result;

          if (status === SettledPromiseFulfilled) {
            value?.result?.feed?.forEach(addToSourceFeed);
          }
        }

        startIndex = endIndex;

        if (sourceFeed.length > 0) {
          let filteredFeed: NETGEM_API_V8_FEED = filterFeed(sourceFeed, channels, filter, slice);
          if (scoring) {
            filteredFeed = setFeedScore(filteredFeed, scoring, {
              playedItemsMap: noIdPlayedItemsMap,
              sessionId,
            });
          }
          const validFeed = filterViewingHistoryFeed(filteredFeed, noIdPlayedItemsMap, playedSeries);
          feedArray.push(validFeed);
        }
      }
    }

    const feed = mergeFeeds(feedArray, globalScoring, { sessionId }, globalSlice);

    this.setSectionLoaded(feed);

    if (viewingHistoryStatus === LoadableStatus.Loaded) {
      localCleanUpViewingHistory(viewingHistory, feed, searchedLocationTypes);
    }
  };

  buildViewingHistorySection: () => void = () => {
    const {
      localSendV8LocationCatchupForAssetRequest,
      localSendV8LocationVodForAssetRequest,
      recordingsListFeed,
      section: { model },
      viewingHistory,
      viewingHistoryStatus,
    } = this.props;
    const {
      abortController: { signal },
    } = this;

    if (viewingHistoryStatus === LoadableStatus.NotInitialized) {
      // Not yet retrieved from server
      return;
    }

    if (viewingHistoryStatus === LoadableStatus.Error || viewingHistory.length === 0) {
      this.setEmptySectionLoaded();
      return;
    }

    /*
     * Parse viewing history to retrieve items Ids
     * playedItemIds:  map of sets of location Ids grouped by type (catchup, recording, etc.)
     * playedItemsMap: map from location Id (catchup, recording, etc.) to viewing history item Id (i.e. series or program Id)
     * playedSeries:   set of series Id
     */
    const { playedItemIds, playedItemsMap, playedSeries } = this.parseViewingHistory(viewingHistory);

    // Build promises for every source
    const promises: Array<Promise<any>> = [];
    let { sources } = ((model: any): NETGEM_API_V8_AGGREGATION_FEED);
    const now = AccurateTimestamp.now();

    // Check if feed model is not an aggregation of sources
    sources = checkSources(sources, model);

    if (sources.length === 0) {
      this.setEmptySectionLoaded();
      return;
    }

    const promiseCountByType: KeyValuePair<number> = {};

    for (let i = 0; i < sources.length; i++) {
      const {
        [i]: { uri },
      } = sources;
      const { allChannels, q: queryType, range } = getValuesFromQueryString(uri, ['allChannels', 'q', 'range']);
      const thresholdTime = getTimestampFromRange(range);

      if (queryType && playedItemIds[queryType]) {
        promiseCountByType[queryType] = 0;
        const promiseGenerator = getViewingHistoryPromiseGenerator(
          localSendV8LocationCatchupForAssetRequest,
          localSendV8LocationVodForAssetRequest,
          recordingsListFeed,
          uri,
          now,
          allChannels !== '0',
          queryType,
          signal,
        );

        if (promiseGenerator !== null) {
          playedItemIds[queryType].forEach((locationId) => {
            const item = playedItemsMap[locationId];
            const { date } = item;

            if (item && getIso8601DateInSeconds(date) >= thresholdTime) {
              promiseCountByType[queryType] += 1;
              promises.push(promiseGenerator(item));
            }
          });
        }
      }
    }

    if (promises.length === 0) {
      this.setEmptySectionLoaded();
      return;
    }

    // Wait for all promises to resolve/reject
    Promise.allSettled(promises).then((results: AllSettledPromises) => {
      if (signal.aborted || sources === undefined) {
        return;
      }

      this.buildViewingHistoryFeedFromPromises(sources, promiseCountByType, playedItemsMap, playedSeries, results);
    });
  };

  buildRegularSection: (isFirstCall: boolean) => void = (isFirstCall) => {
    const { localSendV8SectionFeedRequest, searchString, section } = this.props;
    const {
      abortController: { signal },
    } = this;

    localSendV8SectionFeedRequest(section, searchString, signal)
      .then((response) => {
        signal.throwIfAborted();

        const feed = ((response: any): NETGEM_API_V8_FEED);

        if (isFirstCall && feed.some((i) => isItemRecordingOrScheduledRecording(i))) {
          // Recordings or scheduled recordings section: ensure data freshness
          Messenger.on(MessengerEvents.REFRESH_RECORDINGS_SECTION, this.forceRefresh);
        }

        this.setSectionLoaded(feed);
      })
      .catch((error) => ignoreIfAborted(signal, error, this.setEmptySectionLoaded));
  };

  // Only used for recordings and scheduled recordings sections
  forceRefresh: () => void = () => {
    this.buildRegularSection(false);
  };

  loadData: () => void = () => {
    const {
      channels,
      isRegisteredAsGuest,
      section,
      section: { model },
      state,
    } = this.props;

    // Reset section display: go back to first page
    this.reset(true);

    let { provider } = model;
    let uri: string = '';
    let path: ?string = null;

    if (provider === FeedProviderKind.Aggregation) {
      const aggregationModel = ((model: any): NETGEM_API_V8_AGGREGATION_FEED);
      const { sources } = aggregationModel;

      if (!sources) {
        return;
      }

      // Pick first model
      [{ provider, uri }] = sources;
    } else {
      ({ uri } = ((model: any): NETGEM_API_V8_NTG_VIDEO_FEED));
    }

    if (provider === FeedProviderKind.Local) {
      /*
       * Examples:
       *  local://channels/list --> same as ?event=live
       *  local://channels/list?event=live&channels={channelList}
       *  local://channels/list?event=next&channels={channelList}
       *  local://wishlist/?q=scheduledevent
       *  local://wishlist/?q=(catchup|recording|svod|tvod|est)
       *  local://viewinghistory/?q=(catchup|recording)&range=P15D
       */
      path = getLocalPath(uri);
    }

    switch (provider) {
      case FeedProviderKind.Local:
        // Local provider

        switch (path) {
          case LocalFeed.Channels: {
            // Feed of local channels
            const { channels: channelsParam, event } = getValuesFromQueryString(uri, ['channels', 'event']);
            this.sectionChannels = getSectionChannels(section, channelsParam, channels, state);

            if (event !== null) {
              // Live feed
              this.sectionType = StandardSectionType.Live;
              this.setState({ isLive: true });
              this.updateLiveSection();
            } else {
              // Channels feed (e.g. FAST channels)
              this.sectionType = StandardSectionType.Regular;
              const feed = EpgManager.buildFakeChannelsSection(section, this.sectionChannels);
              if (feed !== null) {
                if (feed.length === 0) {
                  this.setEmptySectionLoaded();
                } else {
                  this.setState({ feed });
                }
              }
            }
            break;
          }

          case LocalFeed.ViewingHistory:
            // Viewing history (TV)
            if (isRegisteredAsGuest) {
              this.collapse();
              this.setEmptySectionLoaded();
            } else {
              this.sectionType = StandardSectionType.ViewingHistory;
              this.buildViewingHistorySection();
            }
            break;

          case LocalFeed.Wishlist:
            // TV programs flagged with the gem icon
            if (isRegisteredAsGuest) {
              this.collapse();
              this.setEmptySectionLoaded();
            } else {
              this.sectionType = StandardSectionType.Wishlist;
              this.buildWishlistSection();
            }
            break;

          default:
            Messenger.emit(
              MessengerEvents.NOTIFY_ERROR,
              <>
                <div>Type de feed inconnu&nbsp;:</div>
                <div>
                  <b>{path}</b>
                </div>
              </>,
            );
            break;
        }
        break;

      case FeedProviderKind.NtgVideo:
      default:
        // Regular sections (replay, future, live)
        this.sectionType = StandardSectionType.Regular;
        this.buildRegularSection(true);
    }
  };

  reset: (skipGridViewExit?: boolean) => void = (skipGridViewExit) => {
    const { isGridAvenue } = this.props;
    const { displayType } = this.state;

    if (displayType === SectionDisplayType.Regular) {
      this.goToPage(0);
    } else if (!isGridAvenue && skipGridViewExit !== true) {
      this.exitGridView();
    }
  };

  calculateItemCountPerPage: () => number = () => {
    const { sectionItemWidth, slider } = this;

    if (!slider || !(slider.parentElement instanceof HTMLElement) || sectionItemWidth <= 0) {
      return -1;
    }

    return Math.max(1, Math.floor(slider.parentElement.offsetWidth / sectionItemWidth));
  };

  updateDimensions: () => void = () => {
    const { isVisible } = this;

    if (!isVisible) {
      this.setState({ updateDimensionsNeeded: true });
      return;
    }

    const itemCountPerPage = this.calculateItemCountPerPage();

    this.setState({
      itemCountPerPage,
      updateDimensionsNeeded: itemCountPerPage === -1,
    });
  };

  createPageList: (previousItemCountPerPage?: number) => void = (previousItemCountPerPage) => {
    const { feed, itemCountPerPage, pageIndex: previousPageIndex } = this.state;
    const { sectionItemWidth } = this;

    if (!feed) {
      return;
    }

    if (itemCountPerPage === -1) {
      this.setState({ updateDimensionsNeeded: true });
      return;
    }

    const { maxPageIndex, pageIndex, sliderPositions } = createSectionPagination(feed, sectionItemWidth, itemCountPerPage, previousPageIndex, previousItemCountPerPage);

    this.sliderPositions = sliderPositions;
    this.setState(
      {
        maxPageIndex,
        pageIndex,
      },
      () => this.goToPage(pageIndex, true, this.checkSavedPageIndex),
    );
  };

  handleEnterGridViewButtonOnClick: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.enterGridView();
  };

  handleExitGridViewButtonOnClick: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.exitGridView();
  };

  handlePlaceholderSectionTitleOnClick: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void = (event) => this.handleSectionTitleOnClick(event, true);

  handleSectionTitleOnClick: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>, isPlaceholder?: boolean) => void = (event, isPlaceholder) => {
    const { cardData, isDebugModeEnabled } = this.props;
    const { displayType, isDebugModePlus, maxPageIndex, pageIndex } = this.state;

    event.preventDefault();
    event.stopPropagation();

    if (isDebugModeEnabled && event.ctrlKey) {
      if (event.shiftKey) {
        // Enable debug mode plus for all items in section (live only)
        this.setState({ isDebugModePlus: !isDebugModePlus });
      } else {
        // Show debug info for section
        showDebugInfo(this.props, this.state, this);
      }
      return;
    }

    if (displayType === SectionDisplayType.Grid || cardData || (pageIndex === 0 && maxPageIndex === 0)) {
      return;
    }

    if (!isPlaceholder && displayType !== SectionDisplayType.Grid) {
      this.enterGridView();
    }
  };

  handleNextButtonOnClick: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.goToNextPage(getPageStepFromKeyboardModifiers(event));
  };

  handlePrevButtonOnClick: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.goToPreviousPage(getPageStepFromKeyboardModifiers(event));
  };

  goToPreviousPage: (pageStep: number) => void = (pageStep) => {
    const { pageIndex } = this.state;

    this.goToPage(Math.max(0, pageIndex - pageStep));
  };

  goToNextPage: (pageStep: number) => void = (pageStep) => {
    const { maxPageIndex, pageIndex } = this.state;

    this.goToPage(Math.min(pageIndex + pageStep, maxPageIndex));
  };

  goToPage: (pageIndex: number, skipIndexSave?: boolean, callback?: BasicFunction) => void = (pageIndex, skipIndexSave, callback) => {
    const {
      localSavePageIndex,
      section: { id },
    } = this.props;
    const { slider, sliderPositions } = this;

    if (!slider || pageIndex < 0 || pageIndex >= sliderPositions.length) {
      if (callback) {
        callback();
      }
      return;
    }

    const posX = sliderPositions[pageIndex];
    slider.style.transform = `translateX(${posX}px) translateZ(0)`;

    this.setState({ pageIndex }, callback);

    if (!skipIndexSave) {
      // Save page index to restore it after player exited
      const { cardData } = this.props;
      localSavePageIndex(`${cardData ? `card_${cardData.item.id}_` : ''}${id}`, pageIndex);
    }

    Messenger.emit(MessengerEvents.SECTION_SLIDING_UPDATE, true);
  };

  checkSavedPageIndex: () => void = () => {
    const {
      section: { id },
      sectionPageIndices,
    } = this.props;
    const { displayType } = this.state;

    const savedPageIndex = sectionPageIndices[id];

    if (displayType === SectionDisplayType.Regular && savedPageIndex) {
      // Restore page index to its value before the player opened
      this.goToPage(savedPageIndex, true);
    }
  };

  handleOnTransitionEnd: () => void = () => {
    Messenger.emit(MessengerEvents.SECTION_SLIDING_UPDATE, false);
  };

  handleSectionVisibilityChanged: (isVisible: boolean) => void = (isVisible) => {
    const { updateDimensionsNeeded } = this.state;
    this.isVisible = isVisible;

    if (isVisible && updateDimensionsNeeded) {
      // Became visible
      this.updateDimensions();
    }
  };

  handleWaypointEnter: () => void = () => {
    this.handleSectionVisibilityChanged(true);
  };

  handleWaypointLeave: () => void = () => {
    this.handleSectionVisibilityChanged(false);
  };

  // $FlowFixMe: flow doesn't understand some external classes/types/interfaces
  handleOnSwiping: (eventData: SwipeEventData) => void = (eventData) => {
    const { deltaX } = eventData;
    const { displayType, maxPageIndex, pageIndex } = this.state;
    const { slider, sliderPositions } = this;

    if (!slider || maxPageIndex <= 0 || displayType === SectionDisplayType.Grid) {
      return;
    }

    this.setState({ isSwiping: true });

    const posX = sliderPositions[pageIndex];
    const newPosX = posX + deltaX;

    slider.style.transform = `translateX(${newPosX}px) translateZ(0)`;
  };

  // $FlowFixMe: flow doesn't understand some external classes/types/interfaces
  handleOnSwiped: (eventData: SwipeEventData) => void = (eventData) => {
    const { dir, velocity } = eventData;
    const { displayType, maxPageIndex } = this.state;

    this.resetSwipeTimeout();
    this.swipeTimeout = setTimeout(() => this.setState({ isSwiping: false }), SWIPE.CooldownTime);

    if (maxPageIndex <= 0 || displayType === SectionDisplayType.Grid) {
      return;
    }

    const pageStep = Math.ceil(velocity / SWIPE.PageVelocityStep);

    if (dir === 'Left') {
      this.goToNextPage(pageStep);
    } else if (dir === 'Right') {
      this.goToPreviousPage(pageStep);
    }
  };

  enterGridView: () => void = () => {
    const {
      localUpdateGridSectionId,
      section: { id },
    } = this.props;
    Messenger.emit(MessengerEvents.MOVE_TO_TOP, true);
    localUpdateGridSectionId(id);
    this.goToPage(0, true, () => {
      this.setState({ displayType: SectionDisplayType.Grid });
    });
  };

  exitGridView: () => void = () => {
    const { localResetGridSectionId } = this.props;
    const { displayType } = this.state;

    if (displayType !== SectionDisplayType.Grid) {
      return;
    }

    localResetGridSectionId();

    this.setState(
      {
        displayType: SectionDisplayType.Regular,
        sortAndFilter: null,
      },
      () => {
        // Will restore vertical scroll position
        Messenger.emit(MessengerEvents.RESTORE_POSITION);

        // Will restore horizontal position of all sections
        this.checkSavedPageIndex();
      },
    );
  };

  onUpdateSortAndFilter: (sortAndFilter: SortAndFilterType) => void = (sortAndFilter) => {
    this.setState(
      produce((draft) => {
        draft.sortAndFilter = sortAndFilter;
      }),
    );
  };

  collapse: () => void = () => {
    this.setState({ displayType: SectionDisplayType.Collapsed });
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  hide: () => void = () => {
    this.setState({ displayType: SectionDisplayType.Hidden });
  };

  show: () => void = () => {
    const { displayType, feed } = this.state;

    if ((displayType === SectionDisplayType.Collapsed || displayType === SectionDisplayType.Hidden) && feed && feed.length > 0) {
      this.setState({ displayType: SectionDisplayType.Regular }, this.checkSavedPageIndex);
    }
  };

  renderPlaceholderSection: (sectionTitle: string, tileTypeClass: string) => React.Node = (sectionTitle, tileTypeClass) => {
    const { displayType } = this.state;
    const {
      tileConfig: { type },
    } = this;

    const tileCount = getPlaceholderItemCount(type);
    const placeholderTiles = [];
    for (let i = 0; i < tileCount; ++i) {
      placeholderTiles.push(<PlaceholderItem key={i} />);
    }

    return (
      <Waypoint fireOnRapidScroll={false} onEnter={this.handleWaypointEnter} onLeave={this.handleWaypointLeave}>
        <div className={clsx('section', 'placeholder', (displayType: string), tileTypeClass)}>
          <div className='header'>
            <div className={clsx('sectionTitleContainer', sectionTitle === '' && 'noTitle')} onClick={this.handlePlaceholderSectionTitleOnClick}>
              <div className='sectionTitle'>{sectionTitle}</div>
            </div>
          </div>
          <div>
            <div
              className='sectionSlider'
              ref={(instance) => {
                this.slider = instance;
              }}
            >
              {placeholderTiles}
            </div>
          </div>
        </div>
      </Waypoint>
    );
  };

  customSortFeed: (feed: NETGEM_API_V8_FEED) => NETGEM_API_V8_FEED = (feed) => {
    const { sortAndFilter } = this.state;

    if (sortAndFilter === null) {
      return feed;
    }

    const sortedFeed = [...feed];

    const { direction, kind } = sortAndFilter;

    if (kind === SortKind.Chronological) {
      sortedFeed.sort(compareDate);
    }

    if (direction === SortDirection.Descendant) {
      sortedFeed.reverse();
    }

    return sortedFeed;
  };

  renderTiles: () => Array<React.Node> = () => {
    const { cardData, isInExploreModal, onItemClick } = this.props;
    const { displayType, feed, isDebugModePlus, isLive, isSwiping, itemCountPerPage, pageIndex, sortAndFilter } = this.state;
    const { tileConfig } = this;

    if (!feed) {
      return [];
    }

    const localFeed = this.customSortFeed(feed);

    const { length: itemCount } = localFeed;
    const indexEnd = displayType === SectionDisplayType.Grid ? itemCount : Math.min((pageIndex + PAGE_DISPLAY_COUNT) * itemCountPerPage, itemCount);
    const tiles: Array<React.Node> = [];

    for (let i = 0; i < indexEnd; ++i) {
      const { [i]: item } = localFeed;

      tiles.push(
        <Item
          cardData={cardData}
          // eslint-disable-next-line react/jsx-no-leaked-render
          isDebugModePlusForced={isLive && isDebugModePlus}
          isInExploreModal={isInExploreModal}
          isInLiveSection={isLive}
          isSwiping={isSwiping}
          item={item}
          key={i}
          onItemClick={onItemClick}
          tileConfig={tileConfig}
          titleFilter={sortAndFilter?.filter}
        />,
      );
    }

    return tiles;
  };

  renderHeader: (title: string, pageIndex: number, maxPageIndex: number, prevButton: React.Node, nextButton: React.Node) => React.Node = (title, pageIndex, maxPageIndex, prevButton, nextButton) => {
    const { cardData, isDebugModeEnabled } = this.props;
    const { displayType } = this.state;

    const sortAndFilterElt = isDebugModeEnabled && displayType === SectionDisplayType.Grid ? <SortAndFilter updateSortAndFilter={this.onUpdateSortAndFilter} /> : null;

    const gridViewButton =
      displayType !== SectionDisplayType.Grid && !cardData && (prevButton || nextButton) ? (
        <div className='gridViewButton' onClick={this.handleEnterGridViewButtonOnClick}>
          <div>{Localizer.localize('common.actions.see_all')}</div>
          <PictoArrowRight forceHoverEffect />
        </div>
      ) : null;

    const paginationElt =
      displayType !== SectionDisplayType.Grid && maxPageIndex > 0 ? (
        <div className='sectionPagination'>
          {pageIndex + 1} / {maxPageIndex + 1}
        </div>
      ) : null;

    return (
      <div className='header'>
        <div className={clsx('sectionTitleContainer', gridViewButton !== null && 'hoverable')} onClick={gridViewButton !== null || isDebugModeEnabled ? this.handleSectionTitleOnClick : undefined}>
          <div className='sectionTitle'>{title}</div>
        </div>
        {gridViewButton}
        {sortAndFilterElt}
        {paginationElt}
      </div>
    );
  };

  render(): React.Node {
    const { isGridAvenue, section, sectionIndex } = this.props;
    const { displayType, feed, isLoading, maxPageIndex, pageIndex } = this.state;
    const {
      sectionName,
      tileConfig: { imageLayout, type },
    } = this;

    const sectionTitle = getTranslatedText(section.title, Localizer.language);

    if (displayType === SectionDisplayType.Collapsed) {
      // Do not display empty section
      return null;
    }

    // Tile type to CSS class conversion (e.g. 'gemtv.big' -> 'gemtv big')
    const tileTypeClass = getTileTypeClass(type);

    if (isLoading || maxPageIndex === -1 || !feed) {
      // Display placeholder while loading
      return this.renderPlaceholderSection(sectionTitle, tileTypeClass);
    }

    // Display all visible items (+ a little more) or all items in grid view
    const tiles = this.renderTiles();

    const prevButton =
      displayType !== SectionDisplayType.Grid && pageIndex > 0 ? (
        <div className={clsx('navigationButton', 'previous', tileTypeClass)} onClick={this.handlePrevButtonOnClick}>
          <PictoArrowLeft forceHoverEffect />
        </div>
      ) : null;

    const nextButton =
      displayType !== SectionDisplayType.Grid && pageIndex < maxPageIndex ? (
        <div className={clsx('navigationButton', 'next', tileTypeClass)} onClick={this.handleNextButtonOnClick}>
          <PictoArrowRight forceHoverEffect />
        </div>
      ) : null;

    const backButton = !isGridAvenue && displayType === SectionDisplayType.Grid ? <ButtonBack className='backBar' onClick={this.handleExitGridViewButtonOnClick} /> : null;

    return (
      <Waypoint fireOnRapidScroll={false} onEnter={this.handleWaypointEnter} onLeave={this.handleWaypointLeave}>
        <div className={clsx('section', (displayType: string), tileTypeClass, imageLayout)} id={`section_${sectionName}`} style={{ zIndex: MAX_Z_INDEX - sectionIndex }}>
          {backButton}
          {this.renderHeader(sectionTitle, pageIndex, maxPageIndex, prevButton, nextButton)}
          <Swipeable onSwiped={this.handleOnSwiped} onSwiping={this.handleOnSwiping} trackMouse>
            <div
              className='sectionSlider'
              onTransitionEnd={this.handleOnTransitionEnd}
              ref={(instance) => {
                this.slider = instance;
              }}
            >
              {tiles}
            </div>
          </Swipeable>
          {prevButton}
          {nextButton}
        </div>
      </Waypoint>
    );
  }
}

const mapStateToProps: (state: CombinedReducers) => ReduxSectionReducerStateType = (state) => {
  return {
    channels: state.appConfiguration.deviceChannels,
    favoriteList: state.ui.favoriteList || [],
    gridSectionId: state.ui.gridSectionId,
    isDebugModeEnabled: state.appConfiguration.isDebugModeEnabled,
    isRegisteredAsGuest: state.appRegistration.registration === RegistrationType.RegisteredAsGuest,
    recordingsListFeed: state.npvr.npvrRecordingsListFeed,
    sectionPageIndices: state.ui.sectionPageIndices,
    sessionId: state.appConfiguration.sessionId,
    state,
    viewingHistory: ViewingHistoryCache.checkGet(state.ui.viewingHistory, state.ui.viewingHistoryStatus, true),
    viewingHistoryStatus: state.ui.viewingHistoryStatus,
    wishlist: WishlistCache.checkGet(state.ui.wishlist, state.ui.wishlistStatus, true).items,
    wishlistStatus: state.ui.wishlistStatus,
  };
};

const mapDispatchToProps: (dispatch: Dispatch) => ReduxSectionDispatchToPropsType = (dispatch) => {
  return {
    localCleanUpViewingHistory: (viewingHistory: VIEWING_HISTORY_TYPE, feed: NETGEM_API_V8_FEED, locationTypes: Set<NETGEM_API_V8_ITEM_LOCATION_TYPE>): Promise<any> =>
      dispatch(cleanUpViewingHistory(viewingHistory, feed, locationTypes)),

    localResetGridSectionId: (): void => dispatch(resetGridSectionId()),

    localSavePageIndex: (sectionId: string, index: number): void => dispatch(updateSectionPageIndex(sectionId, index)),

    localSendV8LocationCatchupForAssetRequest: (assetId: string, startDate: number, range: number, channelIds?: Array<string>, signal?: AbortSignal): Promise<any> =>
      dispatch(sendV8LocationCatchupForAssetRequest(assetId, startDate, range, channelIds, signal)),

    localSendV8LocationEpgForAssetRequest: (assetId: string, startDate: number, range: number, channelIds?: Array<string>, signal?: AbortSignal): Promise<any> =>
      dispatch(sendV8LocationEpgForAssetRequest(assetId, startDate, range, channelIds, signal)),

    localSendV8LocationVodForAssetRequest: (assetId: string, startDate: number, range: number, channelIds?: Array<string>, signal?: AbortSignal): Promise<any> =>
      dispatch(sendV8LocationVodForAssetRequest(assetId, startDate, range, channelIds, signal)),

    localSendV8SectionFeedRequest: (section: NETGEM_API_V8_SECTION, searchString: ?string, signal?: AbortSignal): Promise<any> => dispatch(sendV8SectionFeedRequest(section, searchString, signal)),

    localUpdateGridSectionId: (gridSectionId: string | null): void => dispatch(updateGridSectionId(gridSectionId)),
  };
};

const Section: React.ComponentType<SectionPropType> = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SectionView);

export default Section;
