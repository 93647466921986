/* @flow */

import type { NETGEM_API_V8_METADATA_IMAGE } from '../../../libs/netgemLibrary/v8/types/MetadataProgram';

export const IMAGE_TAG_NO_TEXT: 'no-text' = 'no-text';
export type IMAGE_TAG_TYPE = typeof IMAGE_TAG_NO_TEXT;

const findLandscapeImageId: (images: Array<NETGEM_API_V8_METADATA_IMAGE>, tag?: IMAGE_TAG_TYPE) => string | null = (images, tag) => {
  const image = images.find((img) => {
    if (!img || !img.size) {
      return false;
    }

    const {
      size: { height, width },
      tags,
    } = img;

    if (width > height) {
      // Landscape
      if (tag !== undefined) {
        // Looking for a specific tag
        if (tags?.includes(tag)) {
          // Tag has been found
          return true;
        }
      } else {
        // Any landscape will do (no tag required)
        return true;
      }
    }

    // No landscape found
    return false;
  });

  return image?.id ?? null;
};

export { findLandscapeImageId };
