/* @flow */

import type { DmsSettingMap, NETGEM_API_DMS_DEVICE_INFO_SETTINGS } from '../../libs/netgemLibrary/dms/types/DeviceInfoSettings';
import { Drm, getFirstSupportedDrm } from '../jsHelpers/Drm';
import type { KeyValuePair } from '@ntg/utils/dist/types';
import { Localizer } from '@ntg/utils/dist/localization';
import { capitalizeFirstLetter } from '@ntg/utils/dist/string';
import { detect as detectBrowser } from 'detect-browser';

const getDeviceAndBrowser: () => {|
  browserVersion: string,
  deviceName: string,
|} = () => {
  let deviceName = Localizer.localize('authentication.my_device');
  let browserVersion = '';

  const browser = detectBrowser();
  if (browser) {
    browserVersion = browser.version;

    if (browser.name && browser.os) {
      deviceName = Localizer.localize('authentication.browser_and_os', {
        browser: capitalizeFirstLetter(browser.name),
        os: capitalizeFirstLetter(browser.os),
      });
    } else if (browser.name) {
      deviceName = capitalizeFirstLetter(browser.name);
    }
  }

  return {
    browserVersion,
    deviceName,
  };
};

const getDeviceOS: () => string =
  // eslint-disable-next-line consistent-return
  () => {
    const drm = getFirstSupportedDrm();

    if (drm === undefined) {
      // Default value needs to be set because of Google bots
      return process.env.REACT_APP_DRM_WIDEVINE_NAME ?? 'dsktp-widevine';
    }

    switch (drm) {
      case Drm.Fairplay:
        return process.env.REACT_APP_DRM_FAIRPLAY_NAME ?? 'dsktp-fairplay';
      case Drm.Playready:
        return process.env.REACT_APP_DRM_PLAYREADY_NAME ?? 'dsktp-playready';
      case Drm.Widevine:
        return process.env.REACT_APP_DRM_WIDEVINE_NAME ?? 'dsktp-widevine';

      // No default
    }
  };

const addGtmData: (params: KeyValuePair<string>) => void = (params) => {
  const urlParams = new URLSearchParams(location.search);
  const utmSource = urlParams.get('utm_source');
  const utmMedium = urlParams.get('utm_medium');
  const utmCampaign = urlParams.get('utm_campaign');

  if (utmSource) {
    params.utm_source = utmSource; // eslint-disable-line camelcase
  }

  if (utmMedium) {
    params.utm_medium = utmMedium; // eslint-disable-line camelcase
  }

  if (utmCampaign) {
    params.utm_campaign = utmCampaign; // eslint-disable-line camelcase
  }
};

const buildDeviceUrl: (deviceId: string, url?: string) => ?string = (deviceId, url) => {
  if (!url || deviceId === '') {
    return null;
  }

  const { browserVersion, deviceName } = getDeviceAndBrowser();

  const params = {
    appversion: '',
    deviceid: deviceId,
    devicemodel: 'html5',
    devicename: deviceName,
    deviceos: getDeviceOS(),
    deviceosversion: browserVersion,
    webview: 'false',
  };

  addGtmData(params);

  const localUrl = new URL(url);
  Object.entries(params).forEach(([key, value]) => localUrl.searchParams.set(key, ((value: any): string)));

  return localUrl.toString();
};

const areSectionSettingsEqual: (settings1: Array<NETGEM_API_DMS_DEVICE_INFO_SETTINGS>, settings2: Array<NETGEM_API_DMS_DEVICE_INFO_SETTINGS>) => boolean = (settings1, settings2) => {
  if (settings1.length !== settings2.length) {
    return false;
  }

  const map1 = new Map(settings1.map((s) => [s.name, s.value]));
  const map2 = new Map(settings2.map((s) => [s.name, s.value]));
  const keys1 = map1.keys();

  for (const key of keys1) {
    const val1 = map1.get(key);
    const val2 = map2.get(key);
    if (val2 !== val1) {
      return false;
    }
  }

  return true;
};

const areSettingsEqual: (settings1: ?DmsSettingMap, settings2: ?DmsSettingMap) => boolean = (settings1, settings2) => {
  if (settings1 === settings2) {
    return true;
  }

  if (!settings1 || !settings2) {
    return false;
  }

  const keys1 = Object.keys(settings1);
  const keys2 = Object.keys(settings2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = settings1[key];
    const val2 = settings2[key];
    if (!Array.isArray(val1) || !Array.isArray(val2)) {
      return false;
    }

    if (!areSectionSettingsEqual(val1, val2)) {
      return false;
    }
  }

  return true;
};

export { areSettingsEqual, buildDeviceUrl, getDeviceOS };
