/* @flow */

import { Localizer } from '@ntg/utils/dist/localization';
import { StreamType } from '../../libs/netgemLibrary/v8/types/MetadataSchedule';
import type { Undefined } from '@ntg/utils/dist/types';

const TWO = 2;

export enum Drm {
  Fairplay = 'fairplay',
  Playready = 'playready',
  Widevine = 'widevine',
}

export const DRM_KEY_SYSTEM = Object.freeze({
  Fairplay: 'com.apple.fps',
  Playready: 'com.microsoft.playready',
  Widevine: 'com.widevine.alpha',
});

// List of DRMs supported by PTF
export const DRM_KEY_SYSTEMS = Object.freeze({
  Fairplay: [DRM_KEY_SYSTEM.Fairplay, 'com.apple.fps.1_0', 'com.apple.fps.2_0', 'com.apple.fps.3_0', 'com.apple.fairplay'],
  Playready: [DRM_KEY_SYSTEM.Playready, 'com.youtube.playready'],
  Widevine: [DRM_KEY_SYSTEM.Widevine],
});

// List of DRMs supported by current browser (filled at startup)
const supportedDrms: Array<Drm> = [];

const getDrmName = (drm?: Drm): Undefined<string> => {
  if (drm === undefined) {
    return undefined;
  }

  return (drm: string);
};

// eslint-disable-next-line consistent-return
const getKeySystemsFromDrm = (drm: Drm): Array<string> => {
  switch (drm) {
    case Drm.Fairplay:
      return DRM_KEY_SYSTEMS.Fairplay;
    case Drm.Playready:
      return DRM_KEY_SYSTEMS.Playready;
    case Drm.Widevine:
      return DRM_KEY_SYSTEMS.Widevine;

    // No default
  }
};

const Config = Object.freeze([
  {
    audioCapabilities: [
      {
        contentType: 'audio/mp4;codecs="mp4a.40.2"',
        robustness: 'SW_SECURE_CRYPTO',
      },
    ],
    initDataTypes: ['cenc'],
    videoCapabilities: [
      {
        contentType: 'video/mp4;codecs="avc1.42E01E"',
        robustness: 'SW_SECURE_CRYPTO',
      },
    ],
  },
]);

const getDrmPriority: (name: Drm) => number =
  // eslint-disable-next-line consistent-return
  (name) => {
    // Following order determines which DRM is used when more than one is available (0 is the highest priority)
    switch (name) {
      case Drm.Fairplay:
        return TWO;
      case Drm.Playready:
        return 1;
      case Drm.Widevine:
        return 0;

      // No default
    }
  };

const compareDrms: (drm1?: Drm, drm2?: Drm) => number = (drm1, drm2) => {
  if (drm1 === undefined || drm2 === undefined) {
    return 0;
  }

  const priority1 = getDrmPriority(drm1);
  const priority2 = getDrmPriority(drm2);

  if (priority1 === undefined && priority2 === undefined) {
    return 0;
  }

  if (priority2 === undefined) {
    return -1;
  }
  if (priority1 === undefined) {
    return 1;
  }
  return priority1 - priority2;
};

const compareDrmsFromName: (drm1: string, drm2: string) => number = (drm1, drm2) => compareDrms(Drm.cast(drm1), Drm.cast(drm2));

const isDrmSupported: (drm: ?Drm) => boolean = (drm) => {
  if (drm === undefined || drm === null) {
    return true;
  }

  return supportedDrms.some((supportedDrm) => drm === supportedDrm);
};

const getFirstSupportedDrm: () => Undefined<Drm> = () => {
  if (supportedDrms.length > 0) {
    const [drm] = supportedDrms;
    return drm;
  }

  // No supported DRM
  return undefined;
};

const getSupportedDrms: () => Array<Drm> = () => supportedDrms;

const getSupportedTypesPerDrm: (drm: Drm) => Array<StreamType> =
  // eslint-disable-next-line consistent-return
  (drm) => {
    switch (drm) {
      case Drm.Fairplay:
        return [StreamType.HLS, StreamType.MP4];
      case Drm.Playready:
        return [StreamType.DASH, StreamType.HLS, StreamType.MP4, StreamType.MSS];
      case Drm.Widevine:
        return [StreamType.DASH, StreamType.HLS, StreamType.MP4];

      // No default
    }
  };

const requestMediaKey: (keySystem: string) => Promise<any> = (keySystem) => window.navigator.requestMediaKeySystemAccess(keySystem, Config);

const defaultEmeChecker: (keySystem: string) => Promise<any> = (keySystem) => requestMediaKey(keySystem);

const webKitEmeChecker: (keySystem: string) => Promise<any> = (keySystem) => {
  try {
    new window.WebKitMediaKeys(keySystem); // eslint-disable-line no-new
    return Promise.resolve();
  } catch {
    return Promise.reject(new Error(`${keySystem} no supported`));
  }
};

const msEmeChecker: (keySystem: string) => Promise<any> = (keySystem) => {
  if (window.MSMediaKeys.isTypeSupported(keySystem)) {
    return Promise.resolve();
  }

  return Promise.reject(new Error('Not supported'));
};

const detectDrmInternal: (checker: (keySystem: string) => Promise<any>) => Promise<any> = (checker) => {
  const supportedSystems = new Set<Drm>();
  const promises = [];

  Array.from(Drm.members()).forEach((drm) => {
    getKeySystemsFromDrm(drm).forEach((keySystem) => {
      promises.push(checker(keySystem).then(() => supportedSystems.add(drm)));
    });
  });

  return Promise.allSettled(promises).then(() => supportedSystems);
};

const detectDrm: () => Promise<any> = () => {
  const videoElement = document.createElement('video');

  if (videoElement.mediaKeys) {
    throw new Error(Localizer.localize('common.messages.errors.no_drm'));
  }

  let checker: ?(keySystem: string) => Promise<any> = null;

  if (window.WebKitMediaKeys) {
    checker = webKitEmeChecker;
  } else if (typeof window.MSMediaKeys === 'function') {
    checker = msEmeChecker;
  } else if (typeof window.navigator.requestMediaKeySystemAccess === 'function') {
    checker = defaultEmeChecker;
  } else {
    throw new Error(Localizer.localize('common.messages.errors.no_drm'));
  }

  return detectDrmInternal(checker).then((supportedSystems) => {
    if (supportedSystems.size === 0) {
      return Promise.reject(new Error(Localizer.localize('common.messages.errors.no_drm')));
    }

    const systems = [...supportedSystems];
    systems.sort(compareDrms);
    systems.forEach((drm) => supportedDrms.push(drm));
    return Promise.resolve();
  });
};

export { compareDrms, compareDrmsFromName, detectDrm, getDrmPriority, getDrmName, getFirstSupportedDrm, getSupportedDrms, getSupportedTypesPerDrm, isDrmSupported };
