/* @flow */

import { logWarning } from '../debug/debug';

const replacer: (key: string, value: any) => any = (key, value) => {
  if (value instanceof Map) {
    return {
      dataType: 'Map',
      value: Array.from(value.entries()),
    };
  }

  if (typeof value === 'number' && isNaN(value)) {
    return 'NaN';
  }

  return value;
};

const reviver: (key: string, value: any) => any = (key, value) => {
  if (typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map') {
      return new Map(value.value);
    }
  }

  if (value === 'NaN') {
    return NaN;
  }

  return value;
};

export default class LocalStorageManager {
  // $FlowFixMe: Flow does not support symbols yet
  get [Symbol.toStringTag]() {
    return 'LocalStorageManager';
  }

  static clear: () => void = () => localStorage.clear();

  static delete: (...keys: Array<string>) => void = (...keys) => keys.forEach((key) => localStorage.removeItem(key));

  static loadString: (key: string, defaultValue: string) => string = (key, defaultValue) => localStorage.getItem(key) ?? defaultValue;

  static loadObject: (key: string, defaultValue: any) => any = (key, defaultValue) => {
    try {
      const str = localStorage.getItem(key);
      if (str) {
        const value = JSON.parse(str, reviver);

        if (value !== null) {
          return value;
        }
      }
    } catch {
      // Nothing to do: default value or null is returned below
      logWarning(`Value found in local storage is invalid for key "${key}" and has been deleted`);
      localStorage.removeItem(key);
    }

    return defaultValue ?? null;
  };

  static loadBoolean: (key: string, defaultValue: boolean) => boolean = (key, defaultValue) => {
    try {
      const str = localStorage.getItem(key);
      if (str) {
        return str === '1' || str.toLowerCase() === 'true';
      }
    } catch {
      // Nothing to do: default value is returned below
    }

    return defaultValue;
  };

  static loadNumber: (key: string, defaultValue: number) => number = (key, defaultValue) => {
    try {
      const str = localStorage.getItem(key);
      if (str) {
        const n = Number(str);
        if (!isNaN(n)) {
          return n;
        }
      }
    } catch {
      // Nothing to do: default value is returned below
    }

    return defaultValue;
  };

  static loadIsoDate: (key: string, defaultValue: Date) => Date = (key, defaultValue) => {
    try {
      const str = localStorage.getItem(key);
      if (str) {
        const date = new Date(JSON.parse(str));
        if (date instanceof Date && !isNaN(date)) {
          return date;
        }
      }
    } catch {
      // Nothing to do: default value is returned below
    }

    return defaultValue;
  };

  static save: (key: string, value: any) => void = (key, value) => {
    if (value === undefined) {
      return;
    }

    localStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value, replacer));
  };
}
