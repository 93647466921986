/* @flow */

import './ApplicationContainer.css';
import * as React from 'react';
import { AppAppleStoreLogo, AppBackgroundImage, AppGoogleStoreLogo, AppHasHeaderCloseButton, AppHeaderLogo, AppMobileAppLogo } from '../../helpers/applicationCustomization/ui';
import { AvenueType, type FOCUSED_AVENUE_TYPE } from '../../helpers/ui/avenue/types';
import type { BasicFunction, KeyValuePair } from '@ntg/utils/dist/types';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import { PendingOperationKind, checkPendingOperation, updatePendingOperationType } from '../../helpers/rights/pendingOperations';
import { RegistrationType, type UserDeviceInfoType } from '../../redux/appRegistration/types/types';
import { fetchJsonWithErrorCheck, fetchTextWithErrorCheck } from '../../helpers/jsHelpers/xhr';
import { logError, logInfo } from '../../helpers/debug/debug';
import { registerApplication, resetUserInfo, unregisterApplication } from '../../redux/appRegistration/actions';
import { showAuthenticationRequiredModal, showExternalContentModal, showNewVersionModal } from '../../redux/modal/actions';
import { toggleDebugMode, updateBOVersion, updateCrmBackVersion, updateCrmFrontVersion } from '../../redux/appConf/actions';
import { APPLICATION_ID } from '../../helpers/applicationCustomization/types';
import AccurateTimestamp from '../../helpers/dateTime/AccurateTimestamp';
import ButtonFX from '../buttons/ButtonFX';
import type { CombinedReducers } from '../../redux/reducers';
import type { Dispatch } from '../../redux/types/types';
import { ExternalContentDisplayType } from '../../redux/appConf/types/types';
import { FEATURE_SUBSCRIPTION } from '../../redux/appConf/constants';
import InfiniteCircleLoaderArc from '../loader/infiniteCircleLoaderArc';
import LocalStorageManager from '../../helpers/localStorage/localStorageManager';
import { Localizer } from '@ntg/utils/dist/localization';
import MainView from './MainView';
import ModalContainer from '../modal/modalContainer';
import type { NEW_VERSION_DATA_MODAL_TYPE } from '../modal/newVersionModal/NewVersionModal';
import Notifier from '../../helpers/notification/notifier';
import { PictoX } from '@ntg/components/dist/pictos/Element';
import RegistrationView from './RegistrationView';
import StartView from './StartView';
import { StorageKeys } from '../../helpers/localStorage/keys';
import { areRightsDifferent } from '../../helpers/rights/rights';
import { buildDeviceUrl } from '../../helpers/dms/helper';
import { connect } from 'react-redux';
import { deleteOptimisticData } from '../../helpers/localStorage/optimisticData';
import { fireAndForget } from '../../helpers/jsHelpers/promise';
import { generateToken } from '../../helpers/crypto/crypto';
import { getUserInfo } from '../../redux/netgemApi/actions/crm/userInfo';
import { getUserInfoStatuses } from '../../helpers/rights/userInfo';
import { getVersion } from '../../helpers/version/version';
import { isRunningOnLocalhost } from '../../helpers/jsHelpers/environment';
import { openSelfCare } from '../../helpers/applicationCustomization/externalPaymentSystem';
import { parseBoolean } from '../../helpers/jsHelpers/parser';
import { produce } from 'immer';
import { sendUserRightsRequest } from '../../redux/netgemApi/actions/v8/rights';
import { updateFocusedAvenue } from '../../redux/ui/actions';

const VERSION_CHECK = Object.freeze({
  // Version is checked every 6h but not more than once in a 6h-window (in ms)
  Interval: 21_600_000,
  // Every 5 minutes, next version check date is checked against current date (in ms)
  Timeout: 300_000,
});

export enum ErrorType {
  None,
  // Application configuration couldn't be loaded [form App]
  AppConfFailure,
  // Authentication page took too long to load [from RegistrationView]
  LoadTimeout,
  // At least one of the startup queries failed (HTTP error or invalid response) [from StartView]
  StartupFailure,
  // Startup queries took too long [from StartView]
  StartupTimeout,
  // Guest sign-in failed [from ApplicationContainer]
  GuestAuthent,
}

/*
 * Following states only exist when guest mode is allowed (VIVA):
 *  - DummyRestart
 *  - RegisteringAsGuest
 *  - SignInDisplayed
 *  - SignUpDisplayed
 *  - SubscribeDisplayed
 */
export enum ApplicationState {
  BrowserNotCompatible,
  DummyRestart,
  Error,
  Initializing,
  Logged,
  NotRegistered,
  PodsDisplayed,
  Registered,
  RegisterAsGuest,
  RegisteringAsGuest,
  RunningOnMobile,
  SignInDisplayed,
  SignUpDisplayed,
  SubscribeDisplayed,
}

// Array of application states that require a loader
const LoadingStates = Object.freeze([ApplicationState.DummyRestart, ApplicationState.Initializing, ApplicationState.RegisteringAsGuest]);

type ReduxApplicationContainerDispatchToPropsType = {|
  +localGetUserInfo: () => Promise<any>,
  +localRegisterApplication: (userDeviceInfo: UserDeviceInfoType, asGuest: boolean) => Promise<any>,
  +localResetUserInfo: () => Promise<any>,
  +localSendUserRightsRequest: () => Promise<any>,
  +localShowAuthenticationRequiredModal: () => void,
  +localShowExternalContentModal: (url: string) => void,
  +localToggleDebugMode: BasicFunction,
  +localUnregisterApplication: BasicFunction,
  +localUpdateBOVersion: (version: string) => void,
  +localUpdateCrmBackVersion: (version: string) => void,
  +localUpdateCrmFrontVersion: (version: string) => void,
  +localUpdateFocusedAvenue: (index: number, type: AvenueType) => void,
  +showNewVersion: (newVersionData: NEW_VERSION_DATA_MODAL_TYPE) => void,
|};

type ReduxApplicationContainerReducerStateType = {|
  +applicationName: string,
  +authenticationToken: string | null,
  +chromeUrl: string,
  +configuration: KeyValuePair<string>,
  +deviceId: string,
  +edgeUrl: string,
  +firefoxUrl: string,
  +focusedAvenue: FOCUSED_AVENUE_TYPE | null,
  +isAuthentInFullscreen: boolean,
  +isGuestModeAllowed: boolean,
  +isInTheaterMode: boolean,
  +isRegisteredAsGuest: boolean,
  +isSubscriptionFeatureEnabled: boolean,
  +registration: RegistrationType,
  +useBOV2Api: boolean,
  +userAccountDisplay: ExternalContentDisplayType,
  +userAccountUrl: string,
  +userRights: Array<string> | null,
  +version: string,
|};

type DefaultProps = {|
  +initialAppError?: ErrorType,
  +initialAppState?: ApplicationState,
|};

type ApplicationContainerPropType = {|
  ...DefaultProps,
|};

type CompleteApplicationContainerPropType = {|
  ...ApplicationContainerPropType,
  ...ReduxApplicationContainerDispatchToPropsType,
  ...ReduxApplicationContainerReducerStateType,
|};

type ApplicationContainerStateType = {|
  applicationState: ApplicationState,
  error: ErrorType,
  forceSignInDisplay: boolean,
  hasSkippedGuestRegistration: boolean,
  highlightedCommercialOffers: Array<string>,
  isCloseButtonHidden: boolean,
  isDebugPanelVisible: boolean,
  isHeaderDisplayed: boolean,
  pendingOperationType?: PendingOperationKind,
  registrationCounter: number,
  requiredRightsChangeWatched: ?Array<string>,
|};

const InitialState: $ReadOnly<ApplicationContainerStateType> = Object.freeze({
  applicationState: ApplicationState.Initializing,
  error: ErrorType.None,
  forceSignInDisplay: false,
  hasSkippedGuestRegistration: false,
  highlightedCommercialOffers: [],
  isCloseButtonHidden: false,
  isDebugPanelVisible: false,
  isHeaderDisplayed: false,
  pendingOperationType: undefined,
  registrationCounter: 0,
  requiredRightsChangeWatched: null,
});

class ApplicationContainerView extends React.PureComponent<CompleteApplicationContainerPropType, ApplicationContainerStateType> {
  isFirstStartupSequence: boolean;

  newVersionData: ?NEW_VERSION_DATA_MODAL_TYPE;

  statesWithHeader: Set<ApplicationState>;

  versionCheckTimer: TimeoutID | null;

  static defaultProps: DefaultProps = {
    initialAppError: undefined,
    initialAppState: undefined,
  };

  constructor(props: CompleteApplicationContainerPropType) {
    super(props);

    Notifier.initialize();

    this.isFirstStartupSequence = true;
    this.newVersionData = null;
    this.versionCheckTimer = null;

    this.statesWithHeader = new Set([ApplicationState.DummyRestart, ApplicationState.Error, ApplicationState.RegisteringAsGuest]);

    const { isAuthentInFullscreen } = props;
    if (!isAuthentInFullscreen) {
      this.statesWithHeader.add(ApplicationState.NotRegistered);
      this.statesWithHeader.add(ApplicationState.SignInDisplayed);
      this.statesWithHeader.add(ApplicationState.SignUpDisplayed);
      this.statesWithHeader.add(ApplicationState.SubscribeDisplayed);
      this.statesWithHeader.add(ApplicationState.PodsDisplayed);
    }

    this.state = { ...InitialState };
  }

  componentDidMount() {
    const { initialAppError, initialAppState } = this.props;

    Messenger.on(MessengerEvents.CHECK_VERSION, this.checkVersion);
    Messenger.on(MessengerEvents.CHECK_PENDING_OPERATION, this.checkPendingOperationIfAllowed);
    Messenger.on(MessengerEvents.TOGGLE_DEBUG_MODE, this.toggleDebugMode);
    Messenger.on(MessengerEvents.RESTART_APP, this.restartApp);
    Messenger.on(MessengerEvents.SHOW_SIGN_IN, this.showSignIn);
    Messenger.on(MessengerEvents.SHOW_SUBSCRIBE, this.showSubscribe);
    Messenger.on(MessengerEvents.SHOW_GUEST_MODE, this.showGuestMode);
    Messenger.on(MessengerEvents.SHOW_PODS, this.showPods);
    Messenger.on(MessengerEvents.APPLICATION_FULLY_LOADED, this.applicationLoaded);
    Messenger.on(MessengerEvents.AUTHENTICATION_REQUIRED, this.handleAuthenticationRequired);
    Messenger.on(MessengerEvents.REGISTER_AS_GUEST, this.registerAsGuest);
    Messenger.on(MessengerEvents.ACCOUNT_CREATED, this.hideCloseButton);
    Messenger.on(MessengerEvents.SUBSCRIBED, this.showApp);
    Messenger.on(MessengerEvents.WATCH_RIGHTS_CHANGE, this.watchRightsChange);
    Messenger.on(MessengerEvents.OPEN_USER_ACCOUNT, this.handleOpenUserAccountOnClick);

    if (initialAppState !== undefined) {
      this.initialize(initialAppState, initialAppError);
    }
  }

  componentDidUpdate(prevProps: CompleteApplicationContainerPropType, prevState: ApplicationContainerStateType) {
    const { authenticationToken, initialAppError, initialAppState, isInTheaterMode, registration, showNewVersion, userRights } = this.props;
    const {
      authenticationToken: prevAuthenticationToken,
      initialAppState: prevInitialAppState,
      isInTheaterMode: prevIsInTheaterMode,
      registration: prevRegistration,
      userRights: prevUserRights,
    } = prevProps;
    const { applicationState } = this.state;
    const { applicationState: prevApplicationState } = prevState;
    const { newVersionData } = this;

    if (initialAppState === undefined) {
      return;
    }

    if (initialAppState !== prevInitialAppState) {
      // App became ready
      this.initialize(initialAppState, initialAppError);
    }

    if (authenticationToken !== prevAuthenticationToken) {
      this.handleTokenUpdate(authenticationToken);
      Messenger.emit(MessengerEvents.AUTHENTICATION_TOKEN_CHANGED);
    }

    if (userRights && areRightsDifferent(userRights, prevUserRights)) {
      this.checkRightsChange(userRights);
    }

    if (applicationState !== prevApplicationState) {
      if (applicationState === ApplicationState.Registered) {
        this.showCloseButton();
      }

      if (applicationState === ApplicationState.NotRegistered || applicationState === ApplicationState.SignInDisplayed || applicationState === ApplicationState.SignUpDisplayed) {
        // If user signs out or switches from guest mode to signed in, optimistic data are deleted
        deleteOptimisticData();
      }

      if (applicationState === ApplicationState.DummyRestart) {
        // Only used for applications with guest mode (DMS settings changed)
        this.updateState(ApplicationState.Registered);
        return;
      }

      if (applicationState === ApplicationState.NotRegistered && process.env.REACT_APP_ID === APPLICATION_ID.MyVideofutur) {
        // Enable debug panel (to override authent settings and much more) through console in case authent iframe is in fullscreen (no header to click on)
        window.dbg = { toggleDebugPanel: this.toggleDebugPanel };
      }

      if (applicationState === ApplicationState.RegisteringAsGuest) {
        // Try registering as guest (returned promise is ignored since there's nothing to do with it)
        fireAndForget(this.finalizeGuestRegistration());
      }
    }

    if (registration !== prevRegistration) {
      this.updateApplicationStateIfNotRegistering(prevRegistration);
    }

    if (!isInTheaterMode && prevIsInTheaterMode && newVersionData) {
      // Show pending new version popup when player exits
      showNewVersion(newVersionData);
      this.newVersionData = null;
    }
  }

  componentWillUnmount() {
    Messenger.off(MessengerEvents.CHECK_VERSION, this.checkVersion);
    Messenger.off(MessengerEvents.TOGGLE_DEBUG_MODE, this.toggleDebugMode);
    Messenger.off(MessengerEvents.RESTART_APP, this.restartApp);
    Messenger.off(MessengerEvents.SHOW_SIGN_IN, this.showSignIn);
    Messenger.off(MessengerEvents.SHOW_SUBSCRIBE, this.showSubscribe);
    Messenger.off(MessengerEvents.SHOW_GUEST_MODE, this.showGuestMode);
    Messenger.off(MessengerEvents.APPLICATION_FULLY_LOADED, this.applicationLoaded);
    Messenger.off(MessengerEvents.AUTHENTICATION_REQUIRED, this.handleAuthenticationRequired);
    Messenger.off(MessengerEvents.REGISTER_AS_GUEST, this.registerAsGuest);
    Messenger.off(MessengerEvents.ACCOUNT_CREATED, this.hideCloseButton);
    Messenger.off(MessengerEvents.SUBSCRIBED, this.showApp);
    Messenger.off(MessengerEvents.WATCH_RIGHTS_CHANGE, this.watchRightsChange);
    Messenger.off(MessengerEvents.OPEN_USER_ACCOUNT, this.handleOpenUserAccountOnClick);

    this.resetVersionCheckTimer();
  }

  initialize = (initialAppState: ApplicationState, initialAppError?: ErrorType): void => {
    const { isGuestModeAllowed } = this.props;

    if (initialAppState === ApplicationState.RegisterAsGuest) {
      this.registerAsGuest();
    } else {
      this.updateState(initialAppState);

      if (isGuestModeAllowed && (initialAppState === ApplicationState.SignInDisplayed || initialAppState === ApplicationState.SignUpDisplayed)) {
        // Startup sequence has been interrupted
        this.setState({ hasSkippedGuestRegistration: true });
      }

      if (initialAppError) {
        this.setState({ error: initialAppError });
        return;
      }
    }

    if (initialAppState !== ApplicationState.RunningOnMobile && initialAppState !== ApplicationState.BrowserNotCompatible) {
      this.startVersionCheckTimer();
    }

    fireAndForget(this.getBOVersion());
    fireAndForget(this.getCrmBackVersion());
    fireAndForget(this.getCrmFrontVersion());
  };

  getBOVersion = async (): Promise<void> => {
    const { localUpdateBOVersion, useBOV2Api } = this.props;

    const url = useBOV2Api ? process.env.REACT_APP_BO_VERSION_URL_V2 : process.env.REACT_APP_BO_VERSION_URL;
    if (typeof url !== 'string' || url === '') {
      return;
    }

    try {
      const data = await fetchTextWithErrorCheck(url, { mode: 'cors' });

      if (useBOV2Api) {
        // V2: JSON
        const { version } = JSON.parse(data);
        localUpdateBOVersion(version);
      } else {
        // V1: XML
        const match = /<pre>\s*Build version\s*:\s*(.+)\s*<\/pre>/iu.exec(data);
        if (match) {
          const [, version] = match;
          localUpdateBOVersion(version);
        }
      }
    } catch (error) {
      logError(`Error retrieving BO version: ${error.message}`);
    }
  };

  getCrmBackVersion = (): Promise<void> => {
    const { localUpdateCrmBackVersion } = this.props;

    return this.getCrmVersion(process.env.REACT_APP_CRM_BACK_VERSION_URL, localUpdateCrmBackVersion, 'Back');
  };

  getCrmFrontVersion = (): Promise<void> => {
    const { localUpdateCrmFrontVersion } = this.props;

    return this.getCrmVersion(process.env.REACT_APP_CRM_FRONT_VERSION_URL, localUpdateCrmFrontVersion, 'Front');
  };

  getCrmVersion = async (urlStr: ?string, updater: (version: string) => void, kind: string): Promise<void> => {
    if (!urlStr) {
      return;
    }

    const url = new URL(urlStr);

    // Ensure latest version is retrieved
    url.searchParams.set('v', AccurateTimestamp.now().toString());

    try {
      const { version } = await fetchJsonWithErrorCheck(url, { mode: 'cors' });
      if (version) {
        updater(version);
      }
    } catch (error) {
      logError(`Error retrieving CRM ${kind} version: ${error.message}`);
    }
  };

  handleAuthenticationRequired = (): void => {
    const { focusedAvenue, localShowAuthenticationRequiredModal, localUpdateFocusedAvenue } = this.props;

    if (focusedAvenue?.type === AvenueType.Myvideos) {
      // Can't stay on this avenue when switching to guest mode
      localUpdateFocusedAvenue(0, AvenueType.Regular);
    }

    Messenger.once(MessengerEvents.MODAL_CONFIRMATION_CLOSED, this.authenticationRequiredModalCloseCallback);
    localShowAuthenticationRequiredModal();
  };

  authenticationRequiredModalCloseCallback = () => {
    const { isGuestModeAllowed, localUnregisterApplication } = this.props;

    if (isGuestModeAllowed) {
      this.setState({ forceSignInDisplay: true });
    }
    localUnregisterApplication();
  };

  updateApplicationStateIfNotRegistering = (prevRegistration: RegistrationType): void => {
    const { applicationState } = this.state;
    const { registration } = this.props;

    if (applicationState === ApplicationState.RegisteringAsGuest) {
      return;
    }

    if (registration === RegistrationType.NotRegistered) {
      // Will show login page
      this.updateState(ApplicationState.NotRegistered, () => this.handleNotRegisteredState(prevRegistration));
    } else {
      // Registered (user or guest)
      this.updateState(ApplicationState.Registered);
    }
  };

  handleNotRegisteredState = (prevRegistration?: RegistrationType): void => {
    const { isGuestModeAllowed } = this.props;

    if (isGuestModeAllowed && prevRegistration === RegistrationType.Registered) {
      // User just disconnected and guest mode is enabled
      this.registerAsGuest();
    } else {
      //
      this.updateState(ApplicationState.NotRegistered);
    }
  };

  handleOpenUserAccountOnClick = (): void => {
    const { localShowExternalContentModal, userAccountDisplay, userAccountUrl } = this.props;

    if (!userAccountUrl) {
      logError('Missing user account URL');
      return;
    }

    if (userAccountDisplay === ExternalContentDisplayType.ExternalPopup) {
      // An external library is used to inject JS and open a popup
      openSelfCare();
    } else {
      // Regular case (ExternalContentDisplayType.EmbeddedPopup): directly open user account via given URL
      localShowExternalContentModal(userAccountUrl);
    }
  };

  refreshUserInfo = async (): Promise<void> => {
    const { localGetUserInfo } = this.props;

    const userInfo = await localGetUserInfo();
    const statuses = getUserInfoStatuses(userInfo);
    if (!statuses) {
      return;
    }

    statuses.forEach(({ key, notificationType }) =>
      Messenger.emit(
        notificationType,
        <>
          <div>{Localizer.localize(key)}</div>
          <ButtonFX onClick={this.handleOpenUserAccountOnClick}>{Localizer.localize('settings.general.user_account')}</ButtonFX>
        </>,
        { className: 'large' },
      ),
    );
  };

  refreshUserRights = (): void => {
    const { localSendUserRightsRequest } = this.props;

    localSendUserRightsRequest();
  };

  handleTokenUpdate = (token: string | null): void => {
    const { isSubscriptionFeatureEnabled, localResetUserInfo, registration } = this.props;
    const { applicationState, forceSignInDisplay } = this.state;

    if (token === null) {
      // User disconnected
      this.handleNotRegisteredState();
      localResetUserInfo();
      return;
    }

    // Registered (user or guest)
    if (registration === RegistrationType.Registered) {
      if (isSubscriptionFeatureEnabled) {
        // Only for apps with subscription feature
        if (applicationState === ApplicationState.Logged) {
          // Refresh user rights
          this.refreshUserRights();
        }

        // Refresh user info
        fireAndForget(this.refreshUserInfo());
        return;
      }
    } else if (forceSignInDisplay) {
      // Registered as guest
      this.setState({ forceSignInDisplay: false });
      this.showSignIn();
    }

    localResetUserInfo();
  };

  watchRightsChange = (requiredRights: Array<string>): void => {
    this.setState({ requiredRightsChangeWatched: requiredRights });
  };

  checkRightsChange = (userRights: Array<string>) => {
    const { requiredRightsChangeWatched } = this.state;

    if (!requiredRightsChangeWatched) {
      // User did not click a "play" button to trigger the subscription journey
      return;
    }

    this.setState({ requiredRightsChangeWatched: null }, () => {
      const userRightsSet = new Set(userRights);

      if (requiredRightsChangeWatched.every((right) => userRightsSet.has(right))) {
        // User new rights satisfied all required rights
        Messenger.emit(MessengerEvents.NOTIFY_SUCCESS, <div>{Localizer.localize('subscription.congrats_new_subscription')}</div>);
      } else {
        // Even if rights have changed, the new ones are not enough
        updatePendingOperationType(PendingOperationKind.OpenCard);
      }

      checkPendingOperation();
    });
  };

  checkPendingOperationIfAllowed: () => void = () => {
    const { requiredRightsChangeWatched } = this.state;

    if (requiredRightsChangeWatched) {
      return;
    }

    checkPendingOperation();
  };

  startVersionCheckTimer: () => void = () => {
    this.resetVersionCheckTimer();
    this.versionCheckTimer = setTimeout(this.checkVersion, VERSION_CHECK.Timeout);
  };

  resetVersionCheckTimer = () => {
    const { versionCheckTimer } = this;

    if (versionCheckTimer) {
      clearTimeout(versionCheckTimer);
      this.versionCheckTimer = null;
    }
  };

  getNextVersionCheckDate = (): number => LocalStorageManager.loadNumber(StorageKeys.NextVersionCheckDate, 0);

  checkVersion = async (isForced?: boolean) => {
    const { isInTheaterMode, showNewVersion, version: currentVersion } = this.props;

    if (isRunningOnLocalhost()) {
      // Dev mode
      return;
    }

    // Version check
    const nextCheckDate = this.getNextVersionCheckDate();
    if (!isForced && nextCheckDate > 0 && AccurateTimestamp.now() < nextCheckDate) {
      // Version was checked less than 6h ago
      this.startVersionCheckTimer();
      return;
    }

    try {
      const latestVersion = await getVersion(true);

      LocalStorageManager.save(StorageKeys.NextVersionCheckDate, (AccurateTimestamp.now() + VERSION_CHECK.Interval).toString());
      this.startVersionCheckTimer();

      logInfo(`current version: ${currentVersion} | server version: ${latestVersion}`);

      if (latestVersion === currentVersion) {
        // Current version is up-to-date: let's check again later
        return;
      }

      // New version is available
      this.newVersionData = {
        currentVersion,
        newVersion: latestVersion,
      };

      // Do not disturb while playing!
      if (!isInTheaterMode) {
        showNewVersion(this.newVersionData);
      }
    } catch {
      // Error could be safely ignored since version is checked on a regular basis (plus it's not critical to the app)
      this.startVersionCheckTimer();
    }
  };

  toggleDebugMode = (): void => {
    const { localToggleDebugMode } = this.props;

    localToggleDebugMode();
  };

  updateState = (applicationState: ApplicationState, callback?: BasicFunction) => {
    const { statesWithHeader } = this;

    this.setState(
      {
        applicationState,
        isHeaderDisplayed: statesWithHeader.has(applicationState),
      },
      callback,
    );
  };

  updateErrorState = (error: ErrorType) => {
    this.updateState(ApplicationState.Error);
    this.setState({ error });
  };

  showCloseButton = () => {
    this.setState({ isCloseButtonHidden: false });
  };

  hideCloseButton = () => {
    this.setState({ isCloseButtonHidden: true });
  };

  applicationLoaded = () => {
    this.isFirstStartupSequence = false;
  };

  restartApp = () => {
    const { isGuestModeAllowed } = this.props;
    const { applicationState } = this.state;

    if (isGuestModeAllowed) {
      // Guest mode enabled: restart means signing back as guest
      this.isFirstStartupSequence = false;
      this.updateState(ApplicationState.DummyRestart);
    } else if (applicationState !== ApplicationState.NotRegistered) {
      // Guest mode disabled: restart means refresh page (only if sign-in page is not already displayed)
      window.location.reload();
    }
  };

  registerAsGuest = () => {
    const { applicationState } = this.state;

    if (applicationState === ApplicationState.RegisteringAsGuest || applicationState === ApplicationState.Registered) {
      return;
    }

    // Actual guest registration will be triggered through componentDidUpdate()
    this.updateState(ApplicationState.RegisteringAsGuest);
  };

  finalizeGuestRegistration = async (): Promise<void> => {
    const {
      configuration: { guestRegistrationServiceUrl },
      deviceId,
      localRegisterApplication,
    } = this.props;

    const url = buildDeviceUrl(deviceId, guestRegistrationServiceUrl);

    if (!url) {
      logError('Guest sign in error: URL is null');
      this.updateErrorState(ErrorType.GuestAuthent);
      return;
    }

    const options = process.env.REACT_APP_ID === APPLICATION_ID.FranceChannel ? { credentials: 'include' } : undefined;

    try {
      const userDeviceInfo = await fetchJsonWithErrorCheck(url, options);
      const { anonymous, applicationId, authDeviceUrl, deviceKey, error, subscriberId, upgradeDeviceUrl } = userDeviceInfo;

      if ((typeof error === 'string' && error !== '') || !applicationId || !authDeviceUrl || !deviceKey || !subscriberId || !upgradeDeviceUrl) {
        // Registration failed
        throw new Error('Guest registration error');
      }

      generateToken(deviceId, userDeviceInfo).then((userDeviceInfoJwt) => {
        LocalStorageManager.save(StorageKeys.UserDeviceInfo, userDeviceInfoJwt);
        LocalStorageManager.save(StorageKeys.RegisteredAsGuest, anonymous);

        /*
         * Apps like VIVA and PREMIERE MAX have a regular registerAsGuest endpoint but FranceChannel, having an external landing page, has a registerUserOrAnonymous endpoint
         * Hence, coming from this landing page, we force registering so that the app retrieves the correct data (user or anonymous)
         */
        localRegisterApplication(userDeviceInfo, anonymous);

        this.setState({ hasSkippedGuestRegistration: false });
        this.updateState(ApplicationState.Registered);
      });
    } catch (error) {
      logError(`Guest sign in error: ${error}`);
      this.updateErrorState(ErrorType.GuestAuthent);
    }
  };

  startupSuccessCallback = () => {
    this.updateState(ApplicationState.Logged);
  };

  showSignIn = (pendingOperationType?: PendingOperationKind) => {
    this.setState({ pendingOperationType });
    this.updateState(ApplicationState.SignInDisplayed);
  };

  showSubscribe = (highlightedCommercialOffers?: Array<string>) => {
    if (highlightedCommercialOffers !== undefined) {
      this.setState({ highlightedCommercialOffers });
    }

    this.updateState(ApplicationState.SubscribeDisplayed);
  };

  showPods = () => {
    this.updateState(ApplicationState.PodsDisplayed);
  };

  showGuestMode = () => {
    const { authenticationToken } = this.props;
    const { hasSkippedGuestRegistration } = this.state;

    if (authenticationToken && !hasSkippedGuestRegistration) {
      // Already registered as guest
      this.updateState(ApplicationState.Logged);
    } else {
      // Register as guest
      this.registerAsGuest();
    }
  };

  showApp = () => {
    this.updateState(ApplicationState.Logged);
  };

  handleCloseButtonOnClick = () => {
    const { deviceId, localRegisterApplication, registration } = this.props;
    const { applicationState } = this.state;

    updatePendingOperationType(PendingOperationKind.OpenCard);

    if ([ApplicationState.SubscribeDisplayed, ApplicationState.PodsDisplayed].includes(applicationState)) {
      // User closes the CRM front
      if (registration === RegistrationType.Registered) {
        // User already logged in: refresh token (and user info if needed) and show app
        Messenger.emit(MessengerEvents.REFRESH_AUTHENTICATION_TOKEN);
        this.setState({ requiredRightsChangeWatched: null });
        this.updateState(ApplicationState.Logged, () => {
          if (applicationState === ApplicationState.PodsDisplayed) {
            Messenger.emit(MessengerEvents.OPEN_GLOBAL_SETTINGS);
          }
        });
        return;
      }

      // App is currently not in logged in mode
      const userDeviceInfo = LocalStorageManager.loadObject(StorageKeys.CrmData, {});
      const { anonymous } = userDeviceInfo;

      if (!anonymous) {
        // User signed up or in
        generateToken(deviceId, userDeviceInfo).then((userDeviceInfoJwt) => {
          LocalStorageManager.save(StorageKeys.UserDeviceInfo, userDeviceInfoJwt);
          LocalStorageManager.save(StorageKeys.RegisteredAsGuest, false);

          localRegisterApplication(userDeviceInfo, false);
        });
        return;
      }
    }

    // Use did not sign in
    this.showGuestMode();
  };

  registrationFailureCallback = () => {
    this.setState(
      produce((draft) => {
        draft.registrationCounter += 1;
      }),
      this.handleNotRegisteredState,
    );
  };

  crmLoadTimeoutCallback = () => {
    this.updateErrorState(ErrorType.LoadTimeout);
  };

  unrecoverableStartupFailureCallback = () => {
    this.updateErrorState(ErrorType.StartupFailure);
    deleteOptimisticData();
  };

  startupTimeoutCallback = () => {
    this.updateErrorState(ErrorType.StartupTimeout);
  };

  startupFailureCallback = () => {
    const { isGuestModeAllowed, isRegisteredAsGuest } = this.props;

    deleteOptimisticData();

    if (!isRegisteredAsGuest && isGuestModeAllowed) {
      this.registerAsGuest();
    } else {
      this.showSignIn();
    }
  };

  handleHeaderImageOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
    const { altKey, ctrlKey, shiftKey } = event;

    if ((ctrlKey || altKey) && shiftKey) {
      this.toggleDebugPanel();
    }
  };

  toggleDebugPanel = () => {
    this.setState(
      produce((draft) => {
        draft.isDebugPanelVisible = !draft.isDebugPanelVisible;
      }),
    );
  };

  hideHeaderCallback = () => {
    this.setState({ isHeaderDisplayed: false });
  };

  closeDebugPanelCallback = () => {
    this.setState({ isDebugPanelVisible: false });
  };

  renderTextLink = (text: string, url: string): React.Node => (
    <a href={url} rel='noopener noreferrer' target='_blank'>
      {text}
    </a>
  );

  renderImageLink = (image: string | null, url: string): React.Node => (
    <a href={url} rel='noopener noreferrer' target='_blank'>
      <img alt='' src={image} />
    </a>
  );

  renderUserAccountButton = (): React.Node => {
    const { userAccountUrl } = this.props;

    if (parseBoolean(process.env.REACT_APP_USER_ACCOUNT_ON_STORES_PAGE)) {
      return (
        <a className='text' href={userAccountUrl} rel='noopener noreferrer' target='_blank'>
          {Localizer.localize('settings.general.user_account')}
        </a>
      );
    }

    return null;
  };

  renderBrowserNotCompatibleState = (): React.Node => {
    const {
      applicationName,
      chromeUrl,
      configuration: { appStoreAppUrl, googlePlayAppUrl },
      edgeUrl,
      firefoxUrl,
    } = this.props;

    return (
      <div className='browserNotCompatible'>
        <img alt={applicationName} src={AppMobileAppLogo} />
        <div>{Localizer.localize('login.browser_not_compatible.service_not_available')}</div>
        <div>{Localizer.localize('login.browser_not_compatible.available_browsers')}</div>
        <div className='browsers'>
          {this.renderTextLink('Chrome', chromeUrl)}
          {this.renderTextLink('Edge', edgeUrl)}
          {this.renderTextLink('Firefox', firefoxUrl)}
          <span>Safari</span>
        </div>
        <div>{Localizer.localize('login.browser_not_compatible.download_mobile_apps')}</div>
        {this.renderImageLink(AppAppleStoreLogo, appStoreAppUrl)}
        {this.renderImageLink(AppGoogleStoreLogo, googlePlayAppUrl)}
      </div>
    );
  };

  renderRunningOnMobileState = (): React.Node => {
    const {
      applicationName,
      configuration: { appStoreAppUrl, googlePlayAppUrl },
    } = this.props;

    const style = {};
    if (AppBackgroundImage) {
      // $FlowFixMe: prop-missing
      style.backgroundImage = `url(${AppBackgroundImage})`;
    }

    return (
      <div className='runningOnMobile' style={style}>
        <img alt={applicationName} src={AppMobileAppLogo} />
        <div>{Localizer.localize('login.mobile_browser.download_mobile_apps')}</div>
        {this.renderImageLink(AppAppleStoreLogo, appStoreAppUrl)}
        {this.renderImageLink(AppGoogleStoreLogo, googlePlayAppUrl)}
        {this.renderUserAccountButton()}
      </div>
    );
  };

  renderNotRegisteredState = (): React.Node => {
    const { applicationState, isDebugPanelVisible, highlightedCommercialOffers, pendingOperationType } = this.state;

    return (
      <RegistrationView
        applicationState={applicationState}
        closeDebugPanelCallback={this.closeDebugPanelCallback}
        crmLoadTimeoutCallback={this.crmLoadTimeoutCallback}
        hideHeaderCallback={this.hideHeaderCallback}
        highlightedCommercialOffers={highlightedCommercialOffers}
        isDebugPanelVisible={isDebugPanelVisible}
        pendingOperationType={pendingOperationType}
        registrationFailureCallback={this.registrationFailureCallback}
      />
    );
  };

  renderRegisteredState = (): React.Node => {
    const { isFirstStartupSequence } = this;

    return (
      <StartView
        failureCallback={this.startupFailureCallback}
        isLoadingAnimationVisible={isFirstStartupSequence}
        successCallback={this.startupSuccessCallback}
        timeoutCallback={this.startupTimeoutCallback}
        unrecoverableFailureCallback={this.unrecoverableStartupFailureCallback}
      />
    );
  };

  renderLoggedState = (): React.Node => <MainView />;

  renderLoader = (): React.Node => {
    const { applicationState } = this.state;

    if (LoadingStates.includes(applicationState)) {
      return (
        <div className='appLoader'>
          <InfiniteCircleLoaderArc />
        </div>
      );
    }

    return null;
  };

  renderErrorState = (): React.Node => {
    const { error } = this.state;

    return (
      <div className='startupError'>
        <div className='errorTitle'>{Localizer.localize('login.error.message')}</div>
        <div className='errorName'>{Localizer.localize('login.error.name', { error: (error: string) })}</div>
        <div className='errorHint'>{Localizer.localize('login.error.hint')}</div>
      </div>
    );
  };

  render(): React.Node {
    const { applicationName } = this.props;
    const { applicationState, isCloseButtonHidden, isHeaderDisplayed } = this.state;

    // Close button is visible on a per-app basis, plus it could be hidden for apps having more steps after account is actually created
    const closeButton = !isCloseButtonHidden && AppHasHeaderCloseButton && applicationState !== ApplicationState.RegisteringAsGuest ? <PictoX onClick={this.handleCloseButtonOnClick} /> : null;

    const headerElement = isHeaderDisplayed ? (
      <div className='loginHeader'>
        <img alt={applicationName} draggable={false} onClick={this.handleHeaderImageOnClick} src={AppHeaderLogo} />
        {closeButton}
      </div>
    ) : null;

    let contentElement = null;
    switch (applicationState) {
      case ApplicationState.RunningOnMobile:
        contentElement = this.renderRunningOnMobileState();
        break;

      case ApplicationState.BrowserNotCompatible:
        contentElement = this.renderBrowserNotCompatibleState();
        break;

      case ApplicationState.Registered:
        contentElement = this.renderRegisteredState();
        break;

      case ApplicationState.Logged:
        contentElement = this.renderLoggedState();
        break;

      case ApplicationState.SignInDisplayed:
      case ApplicationState.SignUpDisplayed:
      case ApplicationState.SubscribeDisplayed:
      case ApplicationState.PodsDisplayed:
      case ApplicationState.NotRegistered:
        contentElement = this.renderNotRegisteredState();
        break;

      case ApplicationState.Error:
        contentElement = this.renderErrorState();
        break;

      case ApplicationState.Initializing:
      default:
        // Application is loading app configuration or still determining if running on mobile browser or on non-compatible browser
        contentElement = null;
    }

    return (
      <>
        {headerElement}
        {contentElement}
        <ModalContainer />
        {this.renderLoader()}
      </>
    );
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxApplicationContainerReducerStateType => {
  return {
    applicationName: state.appConfiguration.applicationName,
    authenticationToken: state.appRegistration.authenticationToken,
    chromeUrl: state.appConfiguration.devicesUrl.chrome,
    configuration: state.appConfiguration.configuration,
    deviceId: state.appRegistration.deviceId,
    edgeUrl: state.appConfiguration.devicesUrl.edge,
    firefoxUrl: state.appConfiguration.devicesUrl.firefox,
    focusedAvenue: state.ui.focusedAvenue,
    isAuthentInFullscreen: state.appConfiguration.isAuthentInFullscreen,
    isGuestModeAllowed: state.appConfiguration.isGuestModeAllowed,
    isInTheaterMode: state.ui.isInTheaterMode,
    isRegisteredAsGuest: state.appRegistration.registration === RegistrationType.RegisteredAsGuest,
    isSubscriptionFeatureEnabled: state.appConfiguration.features[FEATURE_SUBSCRIPTION],
    registration: state.appRegistration.registration,
    useBOV2Api: state.appConfiguration.useBOV2Api,
    userAccountDisplay: state.appConfiguration.userAccountDisplay,
    userAccountUrl: state.appConfiguration.configuration.customerAccountUrl ?? '',
    userRights: state.appConfiguration.rightsUser,
    version: state.appConfiguration.versionApp,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxApplicationContainerDispatchToPropsType => {
  return {
    localGetUserInfo: () => dispatch(getUserInfo()),
    localRegisterApplication: (userDeviceInfo: UserDeviceInfoType, asGuest: boolean) => dispatch(registerApplication(userDeviceInfo, asGuest)),
    localResetUserInfo: () => dispatch(resetUserInfo()),
    localSendUserRightsRequest: () => dispatch(sendUserRightsRequest()),
    localShowAuthenticationRequiredModal: () => dispatch(showAuthenticationRequiredModal()),
    localShowExternalContentModal: (url: string) => dispatch(showExternalContentModal(url)),
    localToggleDebugMode: () => dispatch(toggleDebugMode()),
    localUnregisterApplication: () => dispatch(unregisterApplication()),
    localUpdateBOVersion: (version: string) => dispatch(updateBOVersion(version)),
    localUpdateCrmBackVersion: (version: string) => dispatch(updateCrmBackVersion(version)),
    localUpdateCrmFrontVersion: (version: string) => dispatch(updateCrmFrontVersion(version)),
    localUpdateFocusedAvenue: (index: number, type: AvenueType) => dispatch(updateFocusedAvenue(index, type)),
    showNewVersion: (newVersionData: NEW_VERSION_DATA_MODAL_TYPE) => dispatch(showNewVersionModal(newVersionData)),
  };
};

const ApplicationContainer: React.ComponentType<ApplicationContainerPropType> = connect(mapStateToProps, mapDispatchToProps)(ApplicationContainerView);

export default ApplicationContainer;
