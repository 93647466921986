/* @flow */

import type { BO_PURCHASE_LISTS, BO_PURCHASE_LIST_BY_DISTRIBUTOR_TYPE } from '../../netgemApi/actions/videofutur/types/purchase';
import type { NETGEM_API_VIEWINGHISTORY, NETGEM_API_VIEWINGHISTORY_ITEM } from '../../../libs/netgemLibrary/v8/types/ViewingHistory';
import {
  REDUX_MSG_AVENUE_LIST_UPDATE,
  REDUX_MSG_BACK_TO_FOCUSED_AVENUE,
  REDUX_MSG_BO_FAVORITE_LIST_CLEAR,
  REDUX_MSG_BO_FAVORITE_LIST_UPDATE,
  REDUX_MSG_BO_PURCHASE_LISTS_FOR_ALL_DISTRIBUTORS_UPDATE,
  REDUX_MSG_BO_PURCHASE_LIST_CLEAR,
  REDUX_MSG_BO_PURCHASE_LIST_FOR_DISTRIBUTOR_UPDATE,
  REDUX_MSG_DEFAULT_ACTIONS_UPDATE,
  REDUX_MSG_DELETE_SEARCH_HISTORY,
  REDUX_MSG_DELETE_SEARCH_HISTORY_TERM,
  REDUX_MSG_FOCUSED_AVENUE_UPDATE,
  REDUX_MSG_RESET_SECTION_PAGE_INDICES,
  REDUX_MSG_SETTING_RESET,
  REDUX_MSG_SETTING_UPDATE,
  REDUX_MSG_SET_DEEPLINK,
  REDUX_MSG_THEATER_MODE_UPDATE,
  REDUX_MSG_UPDATE_DISPLAY_PAYWALL_SUBSCRIPTION,
  REDUX_MSG_UPDATE_GRID_SECTION_ID,
  REDUX_MSG_UPDATE_SEARCH_STRING,
  REDUX_MSG_UPDATE_SECTION_PAGE_INDEX,
  REDUX_MSG_VIDEO_CAROUSEL_PLAY_UPDATE,
  REDUX_MSG_VIDEO_CAROUSEL_SOUND_UPDATE,
  REDUX_MSG_VIEWING_HISTORY_ITEM_DELETE,
  REDUX_MSG_VIEWING_HISTORY_ITEM_ETAG_DELETE,
  REDUX_MSG_VIEWING_HISTORY_ITEM_UPDATE,
  REDUX_MSG_VIEWING_HISTORY_RESET,
  REDUX_MSG_VIEWING_HISTORY_SET_ERROR,
  REDUX_MSG_VIEWING_HISTORY_UPDATE,
  REDUX_MSG_WISHLIST_ETAG_DELETE,
  REDUX_MSG_WISHLIST_RESET,
  REDUX_MSG_WISHLIST_SET_ERROR,
  REDUX_MSG_WISHLIST_UPDATE,
} from '../constants';
import { AvenueType } from '../../../helpers/ui/avenue/types';
import type { NETGEM_API_V8_FEED_ITEM } from '../../../libs/netgemLibrary/v8/types/FeedItem';
import type { NETGEM_API_V8_HUB } from '../../../libs/netgemLibrary/v8/types/Hub';
import type { NETGEM_API_V8_WIDGET_ACTIONS_TYPE } from '../../../libs/netgemLibrary/v8/types/WidgetConfig';
import type { NETGEM_API_WISHLIST } from '../../../libs/netgemLibrary/v8/types/Wishlist';
import { Setting } from '../../../helpers/settings/types';

export type UIAction =
  // Default action
  | {| type: null |}
  // Update avenue hub
  | {|
      hub: NETGEM_API_V8_HUB,
      hubETag: string | null,
      hubMaxAge: number | null,
      type: typeof REDUX_MSG_AVENUE_LIST_UPDATE,
    |}
  // Update focused avenue
  | {|
      avenueType: AvenueType,
      hub?: NETGEM_API_V8_HUB,
      hubItem?: NETGEM_API_V8_FEED_ITEM,
      index: number,
      searchString?: string,
      type: typeof REDUX_MSG_FOCUSED_AVENUE_UPDATE,
    |}
  // Update search string
  | {|
      searchString: string,
      type: typeof REDUX_MSG_UPDATE_SEARCH_STRING,
    |}
  // Clear search history
  | {| type: typeof REDUX_MSG_DELETE_SEARCH_HISTORY |}
  // Delete search string in search history
  | {|
      searchString: string,
      type: typeof REDUX_MSG_DELETE_SEARCH_HISTORY_TERM,
    |}
  // Go back to focused avenue
  | {| type: typeof REDUX_MSG_BACK_TO_FOCUSED_AVENUE |}
  // Update default item actions
  | {|
      actions: NETGEM_API_V8_WIDGET_ACTIONS_TYPE,
      type: typeof REDUX_MSG_DEFAULT_ACTIONS_UPDATE,
    |}
  // Reset section page indices
  | {|
      prefix?: string,
      type: typeof REDUX_MSG_RESET_SECTION_PAGE_INDICES,
    |}
  // Update section page index
  | {|
      pageIndex: number,
      sectionId: string,
      type: typeof REDUX_MSG_UPDATE_SECTION_PAGE_INDEX,
    |}
  // Reset settings
  | {| type: typeof REDUX_MSG_SETTING_RESET |}
  // Update settings
  | {|
      setting: Setting,
      type: typeof REDUX_MSG_SETTING_UPDATE,
      value: any,
    |}
  // Toggle video carousel play/pause
  | {|
      isPlaying: boolean,
      type: typeof REDUX_MSG_VIDEO_CAROUSEL_PLAY_UPDATE,
    |}
  // Toggle video carousel sound
  | {|
      isMuted: boolean,
      type: typeof REDUX_MSG_VIDEO_CAROUSEL_SOUND_UPDATE,
    |}
  // Toggle theater mode
  | {|
      isInTheaterMode: boolean,
      type: typeof REDUX_MSG_THEATER_MODE_UPDATE,
    |}
  // Update VOD favorite list
  | {|
      eTag: string | null,
      favoriteList: Array<string>,
      type: typeof REDUX_MSG_BO_FAVORITE_LIST_UPDATE,
    |}
  // Clear VOD favorite list for all distributors
  | {| type: typeof REDUX_MSG_BO_FAVORITE_LIST_CLEAR |}
  // Update VOD purchase list for given distributor
  | {|
      distributorId: string,
      eTag: string | null,
      purchaseList: BO_PURCHASE_LIST_BY_DISTRIBUTOR_TYPE,
      type: typeof REDUX_MSG_BO_PURCHASE_LIST_FOR_DISTRIBUTOR_UPDATE,
    |}
  // Update VOD purchase lists for all distributors
  | {|
      purchaseLists: BO_PURCHASE_LISTS,
      type: typeof REDUX_MSG_BO_PURCHASE_LISTS_FOR_ALL_DISTRIBUTORS_UPDATE,
    |}
  // Clear VOD purchase list for all distributors
  | {| type: typeof REDUX_MSG_BO_PURCHASE_LIST_CLEAR |}
  // Set deeplink URL
  | {|
      deeplink: string,
      type: typeof REDUX_MSG_SET_DEEPLINK,
    |}
  // Set deeplink URL
  | {|
      isDisplayed: boolean,
      type: typeof REDUX_MSG_UPDATE_DISPLAY_PAYWALL_SUBSCRIPTION,
    |}
  // Viewing history load error
  | {| type: typeof REDUX_MSG_VIEWING_HISTORY_SET_ERROR |}
  // Reset viewing history
  | {| type: typeof REDUX_MSG_VIEWING_HISTORY_RESET |}
  // Delete viewing history item eTag
  | {|
      itemId: string,
      type: typeof REDUX_MSG_VIEWING_HISTORY_ITEM_ETAG_DELETE,
    |}
  // Delete viewing history item
  | {|
      itemId: string,
      type: typeof REDUX_MSG_VIEWING_HISTORY_ITEM_DELETE,
    |}
  // Update viewing history item
  | {|
      item: NETGEM_API_VIEWINGHISTORY_ITEM,
      itemETag: ?string,
      type: typeof REDUX_MSG_VIEWING_HISTORY_ITEM_UPDATE,
    |}
  // Update whole viewing history
  | {|
      viewingHistory: NETGEM_API_VIEWINGHISTORY,
      viewingHistoryETag: ?string,
      type: typeof REDUX_MSG_VIEWING_HISTORY_UPDATE,
    |}
  // Delete wishlist eTag
  | {| type: typeof REDUX_MSG_WISHLIST_ETAG_DELETE |}
  // Wishlist load error
  | {| type: typeof REDUX_MSG_WISHLIST_SET_ERROR |}
  // Reset whole wishlist
  | {| type: typeof REDUX_MSG_WISHLIST_RESET |}
  // Update whole wishlist
  | {|
      type: typeof REDUX_MSG_WISHLIST_UPDATE,
      wishlist: NETGEM_API_WISHLIST,
      wishlistETag: ?string,
    |}
  // Update grid section Id
  | {|
      type: typeof REDUX_MSG_UPDATE_GRID_SECTION_ID,
      gridSectionId: string | null,
    |};

const updateVideoCarouselPlaying: () => UIAction = () => {
  return {
    isPlaying: true,
    type: REDUX_MSG_VIDEO_CAROUSEL_PLAY_UPDATE,
  };
};

const updateVideoCarouselPaused: () => UIAction = () => {
  return {
    isPlaying: false,
    type: REDUX_MSG_VIDEO_CAROUSEL_PLAY_UPDATE,
  };
};

const updateVideoCarouselMuted: () => UIAction = () => {
  return {
    isMuted: true,
    type: REDUX_MSG_VIDEO_CAROUSEL_SOUND_UPDATE,
  };
};

const updateVideoCarouselUnmuted: () => UIAction = () => {
  return {
    isMuted: false,
    type: REDUX_MSG_VIDEO_CAROUSEL_SOUND_UPDATE,
  };
};

const enterTheaterMode: () => UIAction = () => {
  return {
    isInTheaterMode: true,
    type: REDUX_MSG_THEATER_MODE_UPDATE,
  };
};

const exitTheaterMode: () => UIAction = () => {
  return {
    isInTheaterMode: false,
    type: REDUX_MSG_THEATER_MODE_UPDATE,
  };
};

const updateAvenueList: (hub: NETGEM_API_V8_HUB, hubMaxAge: number | null, hubETag: string | null) => UIAction = (hub, hubMaxAge, hubETag) => {
  return {
    hub,
    hubETag,
    hubMaxAge,
    type: REDUX_MSG_AVENUE_LIST_UPDATE,
  };
};

const updateFocusedAvenue: (index: number, avenueType: AvenueType, hubItem?: NETGEM_API_V8_FEED_ITEM, hub?: NETGEM_API_V8_HUB, searchString?: string) => UIAction = (
  index,
  avenueType,
  hubItem,
  hub,
  searchString,
) => {
  return {
    avenueType,
    hub,
    hubItem,
    index,
    searchString,
    type: REDUX_MSG_FOCUSED_AVENUE_UPDATE,
  };
};

const updateSearchString: (searchString: string) => UIAction = (searchString) => {
  return {
    searchString,
    type: REDUX_MSG_UPDATE_SEARCH_STRING,
  };
};

const deleteSearchHistory: () => UIAction = () => {
  return { type: REDUX_MSG_DELETE_SEARCH_HISTORY };
};

const deleteSearchHistoryTerm: (searchString: string) => UIAction = (searchString) => {
  return {
    searchString,
    type: REDUX_MSG_DELETE_SEARCH_HISTORY_TERM,
  };
};

const goBackToFocusedAvenue: () => UIAction = () => {
  return { type: REDUX_MSG_BACK_TO_FOCUSED_AVENUE };
};

const updateDefaultActions: (actions: NETGEM_API_V8_WIDGET_ACTIONS_TYPE) => UIAction = (actions) => {
  return {
    actions,
    type: REDUX_MSG_DEFAULT_ACTIONS_UPDATE,
  };
};

const resetSectionPageIndices: (prefix?: string) => UIAction = (prefix) => {
  return {
    prefix,
    type: REDUX_MSG_RESET_SECTION_PAGE_INDICES,
  };
};

const updateSectionPageIndex: (sectionId: string, pageIndex: number) => UIAction = (sectionId, pageIndex) => {
  return {
    pageIndex,
    sectionId,
    type: REDUX_MSG_UPDATE_SECTION_PAGE_INDEX,
  };
};

const updateSetting: (setting?: Setting, value: any) => UIAction = (setting, value) => {
  if (setting !== undefined) {
    return {
      setting,
      type: REDUX_MSG_SETTING_UPDATE,
      value,
    };
  }

  return {
    type: null,
  };
};

const resetSettings: () => UIAction = () => {
  return { type: REDUX_MSG_SETTING_RESET };
};

const deleteViewingHistoryItem: (itemId: string) => UIAction = (itemId) => {
  return {
    itemId,
    type: REDUX_MSG_VIEWING_HISTORY_ITEM_DELETE,
  };
};

const deleteViewingHistoryItemETag: (itemId: string) => UIAction = (itemId) => {
  return {
    itemId,
    type: REDUX_MSG_VIEWING_HISTORY_ITEM_ETAG_DELETE,
  };
};

const deleteWishlistETag: () => UIAction = () => {
  return { type: REDUX_MSG_WISHLIST_ETAG_DELETE };
};

const clearBOFavoriteList: () => UIAction = () => {
  return { type: REDUX_MSG_BO_FAVORITE_LIST_CLEAR };
};

const updateBOFavoriteList: (favoriteList: Array<string>, eTag: string | null) => UIAction = (favoriteList, eTag) => {
  return {
    eTag,
    favoriteList,
    type: REDUX_MSG_BO_FAVORITE_LIST_UPDATE,
  };
};

const clearBOPurchaseList: () => UIAction = () => {
  return { type: REDUX_MSG_BO_PURCHASE_LIST_CLEAR };
};

const updateBOPurchaseListForDistributor: (distributorId: string, purchaseList: BO_PURCHASE_LIST_BY_DISTRIBUTOR_TYPE, eTag: string | null) => UIAction = (distributorId, purchaseList, eTag) => {
  return {
    distributorId,
    eTag,
    purchaseList,
    type: REDUX_MSG_BO_PURCHASE_LIST_FOR_DISTRIBUTOR_UPDATE,
  };
};

const updateBOPurchaseListsForAllDistributors: (purchaseLists: BO_PURCHASE_LISTS) => UIAction = (purchaseLists) => {
  return {
    purchaseLists,
    type: REDUX_MSG_BO_PURCHASE_LISTS_FOR_ALL_DISTRIBUTORS_UPDATE,
  };
};

const updateViewingHistoryItem: (item: NETGEM_API_VIEWINGHISTORY_ITEM, itemETag: ?string) => UIAction = (item, itemETag) => {
  return {
    item,
    itemETag,
    type: REDUX_MSG_VIEWING_HISTORY_ITEM_UPDATE,
  };
};

const setDeeplink: (deeplink: string) => UIAction = (deeplink) => {
  return {
    deeplink,
    type: REDUX_MSG_SET_DEEPLINK,
  };
};

const updateDisplayPaywallSubscription: (isDisplayed: boolean) => UIAction = (isDisplayed) => {
  return {
    isDisplayed,
    type: REDUX_MSG_UPDATE_DISPLAY_PAYWALL_SUBSCRIPTION,
  };
};

const setViewingHistoryError: () => UIAction = () => {
  return { type: REDUX_MSG_VIEWING_HISTORY_SET_ERROR };
};

const resetViewingHistory: () => UIAction = () => {
  return { type: REDUX_MSG_VIEWING_HISTORY_RESET };
};

const updateWholeViewingHistory: (viewingHistory: NETGEM_API_VIEWINGHISTORY, viewingHistoryETag: ?string) => UIAction = (viewingHistory, viewingHistoryETag) => {
  return {
    type: REDUX_MSG_VIEWING_HISTORY_UPDATE,
    viewingHistory,
    viewingHistoryETag,
  };
};

const setWishlistError: () => UIAction = () => {
  return { type: REDUX_MSG_WISHLIST_SET_ERROR };
};

const resetWishlist: () => UIAction = () => {
  return { type: REDUX_MSG_WISHLIST_RESET };
};

const updateWishlist: (wishlist: NETGEM_API_WISHLIST, wishlistETag: ?string) => UIAction = (wishlist, wishlistETag) => {
  return {
    type: REDUX_MSG_WISHLIST_UPDATE,
    wishlist,
    wishlistETag,
  };
};

const updateGridSectionId: (gridSectionId: string | null) => UIAction = (gridSectionId) => {
  return {
    gridSectionId,
    type: REDUX_MSG_UPDATE_GRID_SECTION_ID,
  };
};

const resetGridSectionId: () => UIAction = () => {
  return {
    gridSectionId: null,
    type: REDUX_MSG_UPDATE_GRID_SECTION_ID,
  };
};

export {
  clearBOFavoriteList,
  clearBOPurchaseList,
  deleteSearchHistory,
  deleteSearchHistoryTerm,
  deleteViewingHistoryItemETag,
  deleteViewingHistoryItem,
  deleteWishlistETag,
  enterTheaterMode,
  exitTheaterMode,
  goBackToFocusedAvenue,
  resetGridSectionId,
  resetSectionPageIndices,
  resetSettings,
  resetViewingHistory,
  resetWishlist,
  setDeeplink,
  setViewingHistoryError,
  setWishlistError,
  updateAvenueList,
  updateBOFavoriteList,
  updateBOPurchaseListForDistributor,
  updateBOPurchaseListsForAllDistributors,
  updateDefaultActions,
  updateDisplayPaywallSubscription,
  updateFocusedAvenue,
  updateGridSectionId,
  updateSearchString,
  updateSectionPageIndex,
  updateSetting,
  updateVideoCarouselMuted,
  updateVideoCarouselPaused,
  updateVideoCarouselPlaying,
  updateVideoCarouselUnmuted,
  updateViewingHistoryItem,
  updateWholeViewingHistory,
  updateWishlist,
};
