/* @flow */

import type { ScrollOptions } from './types';

const isTabHidden: () => ?boolean = () => {
  let isHidden: ?boolean = null;

  if (document.hidden !== undefined) {
    isHidden = document.hidden;
    // $FlowFixMe
  } else if (document.mozHidden !== undefined) {
    // $FlowFixMe
    isHidden = document.mozHidden;
    // $FlowFixMe
  } else if (document.msHidden !== undefined) {
    // $FlowFixMe
    isHidden = document.msHidden;
    // $FlowFixMe
  } else if (document.webkitHidden !== undefined) {
    // $FlowFixMe
    isHidden = document.webkitHidden;
  }

  return typeof isHidden === 'boolean' ? isHidden : null;
};

const smoothScrollIntoView: (container: HTMLElement, element: HTMLElement, options: ScrollOptions) => Promise<any> = (container, element, options) =>
  new Promise((resolve) => {
    const FRAME_COUNT_THRESHOLD = 2;
    let same = 0;
    let lastPosTop = container.scrollTop;

    const checkForScrollEnd = () => {
      const newPosTop = container.scrollTop;

      if (newPosTop === lastPosTop) {
        same += 1;
        if (same > FRAME_COUNT_THRESHOLD) {
          resolve();
        }
      } else {
        same = 0;
        lastPosTop = newPosTop;
      }

      requestAnimationFrame(checkForScrollEnd);
    };

    element.scrollIntoView({
      ...options,
      behavior: 'smooth',
    });
    requestAnimationFrame(checkForScrollEnd);
  });

export { isTabHidden, smoothScrollIntoView };
