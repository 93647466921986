/* @flow */

import * as React from 'react';
import { type CompleteSectionPropType, LOCAL_PROVIDER_QUERY, type LocalPlayedItemType, type SectionStateType } from './SectionConstsAndTypes';
import { FeedProviderKind, type FeedRequestFunction } from '../../../libs/netgemLibrary/v8/types/Feed';
import { MILLISECONDS_PER_HOUR, MILLISECONDS_PER_WEEK, getIso8601DurationInSeconds } from '../../../helpers/dateTime/Format';
import {
  type NETGEM_API_V8_FEED_ITEM,
  type NETGEM_API_V8_FEED_RAW_ITEM,
  type NETGEM_API_V8_ITEM_LOCATION_TYPE,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_EST,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_SVOD,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_TVOD,
} from '../../../libs/netgemLibrary/v8/types/FeedItem';
import { getFirstLocation, getLocationType } from '../../../libs/netgemLibrary/v8/helpers/Item';
import AccurateTimestamp from '../../../helpers/dateTime/AccurateTimestamp';
import type { CombinedReducers } from '../../../redux/reducers';
import { CustomNetworkError } from '../../../libs/netgemLibrary/helpers/CustomNetworkError';
import type { FeedRequestResponseType } from '../../../libs/netgemLibrary/v8/requests/feed';
import HotKeys from '../../../helpers/hotKeys/hotKeys';
import { HttpStatus } from '../../../libs/netgemLibrary/v8/constants/NetworkCodesAndMessages';
import type { KeyValuePair } from '@ntg/utils/dist/types';
import type { NETGEM_API_V8_AGGREGATION_FEED } from '../../../libs/netgemLibrary/v8/types/AggregationFeed';
import type { NETGEM_API_V8_NTG_VIDEO_FEED } from '../../../libs/netgemLibrary/v8/types/NtgVideoFeed';
import type { NETGEM_API_V8_PARAM } from '../../../libs/netgemLibrary/v8/types/Param';
import type { NETGEM_API_V8_RAW_FEED } from '../../../libs/netgemLibrary/v8/types/FeedResult';
import type { NETGEM_API_WISHLIST_ITEM } from '../../../libs/netgemLibrary/v8/types/Wishlist';
import { TileConfigTileType } from '../../../libs/netgemLibrary/v8/types/WidgetConfig';
import { getChannelsParameterName } from '../../../helpers/channel/helper';
import { getValueForParameter } from '../../../redux/netgemApi/actions/helpers/api';
import { showDebug } from '../../../helpers/debug/debug';

const enableHotKeys: (handleExitGridViewHotKey: (event: SyntheticKeyboardEvent<HTMLElement>) => void) => void = (handleExitGridViewHotKey) => {
  HotKeys.register('escape', handleExitGridViewHotKey, { name: 'StandardSection.exitGrid' });
};

const deserializeChannelIds: (channels: string) => Array<string> | string = (channels) => {
  try {
    // Argument 'channels' is a stringified list of channel Ids
    return JSON.parse(channels);
  } catch {
    // Argument 'channels' is a list alias
    return channels;
  }
};

const disableHotKeys: (handleExitGridViewHotKey: (event: SyntheticKeyboardEvent<HTMLElement>) => void) => void = (handleExitGridViewHotKey) => {
  HotKeys.unregister('escape', handleExitGridViewHotKey);
};

const getChannelIdsOrAliasFromParameters: (uri: string, params: Array<NETGEM_API_V8_PARAM>, state: CombinedReducers) => Array<string> | string | null = (uri, params, state) => {
  const channelsParameterName = getChannelsParameterName(uri);

  if (!channelsParameterName) {
    // Parameter name not found
    return null;
  }

  const param = params.find((p) => p.name === channelsParameterName);

  if (!param) {
    // Parameter not found
    return null;
  }

  const channelIds = getValueForParameter(param.value, null, state);

  if (!channelIds) {
    // No channel Id matched the parameter
    return [];
  }

  // Channel Ids returned by getValueForParameter() are serialized
  return deserializeChannelIds(channelIds);
};

const getPageStepFromKeyboardModifiers: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => number = (event) => {
  const { altKey, ctrlKey, shiftKey } = event;
  let step = 1;

  if (ctrlKey) {
    step += 1;

    if (shiftKey) {
      step += 1;

      if (altKey) {
        step += 1;
      }
    }
  }

  return step;
};

const getLocalPath: (uri: string) => string | null = (uri) => {
  /*
   * Regex will take all characters after "local://" and up to:
   * - ?    query string
   * - /?   trailing slash + query string
   * - /$   trailing slash + end of string
   * - $    end of string
   */
  const result = /local:\/\/(?<path>.+?)(\?|\/\?|\/$|$)/iu.exec(uri);
  return result?.groups?.path ?? null;
};

const getValuesFromQueryString: (uri: string, parameterNames: Array<string>) => KeyValuePair<string | null> = (uri, parameterNames) => {
  try {
    const params = new URL(uri).searchParams;
    const values: KeyValuePair<string | null> = {};

    parameterNames.forEach((name) => (values[name] = params.get(name)));

    return values;
  } catch {
    return Object.fromEntries(parameterNames.map((name) => ([name, null]: [string, string | null])));
  }
};

const getMatchingRecordingFeed: (recordingsListFeed: NETGEM_API_V8_RAW_FEED, seriesOrProgramId: string) => FeedRequestResponseType = (recordingsListFeed, seriesOrProgramId) => {
  const recording = recordingsListFeed.find((r) => r.id === seriesOrProgramId);

  if (!recording) {
    return {
      feedIndex: 0,
      result: { feed: [] },
    };
  }

  return {
    feedIndex: 0,
    result: { feed: [recording] },
  };
};

const getTimestampFromRange: (range: ?string) => number = (range) => (range !== null ? AccurateTimestamp.nowInSeconds() - getIso8601DurationInSeconds(range) : 0);

const getViewingHistoryPromiseGenerator: (
  getCatchupForAsset: FeedRequestFunction,
  getVodForAsset: FeedRequestFunction,
  recordingsListFeed: NETGEM_API_V8_RAW_FEED,
  uri: string,
  now: number,
  queryAllChannels: boolean,
  queryType: ?string,
  signal: AbortSignal,
) => ((item: LocalPlayedItemType) => Promise<any>) | null = (getCatchupForAsset, getVodForAsset, recordingsListFeed, uri, now, queryAllChannels, queryType, signal) => {
  const getChannelIds = (item: LocalPlayedItemType) => (queryAllChannels || !item.channelId ? undefined : [item.channelId]);

  switch (queryType) {
    case LOCAL_PROVIDER_QUERY.Catchup:
      return (item) => getCatchupForAssetRequest(getCatchupForAsset, item.id, now, getChannelIds(item), signal);

    case LOCAL_PROVIDER_QUERY.Est:
    case LOCAL_PROVIDER_QUERY.Svod:
    case LOCAL_PROVIDER_QUERY.Tvod:
      return (item) => getVodForAsset(item.id, now, MILLISECONDS_PER_HOUR, getChannelIds(item), signal);

    case LOCAL_PROVIDER_QUERY.Recording:
      return (item) => Promise.resolve(getMatchingRecordingFeed(recordingsListFeed, item.id));

    default:
  }

  return null;
};

const getCatchupForAssetRequest: (getCatchupForAsset: FeedRequestFunction, assetId: string, startDate: number, channelIds?: Array<string>, signal?: AbortSignal) => Promise<any> = (
  getCatchupForAsset,
  assetId,
  startDate,
  channelIds,
  signal,
) => {
  // Closure
  const errorResult = {
    feed: [],
    programId: assetId,
  };

  return getCatchupForAsset(assetId, startDate, MILLISECONDS_PER_WEEK, channelIds, signal).catch((error: CustomNetworkError) => {
    if (error.getStatus() === HttpStatus.NotFound) {
      // Catchup doesn't exist anymore: skip it from the viewing history, then delete it
      return Promise.resolve({
        result: errorResult,
        status: HttpStatus.NotFound,
      });
    }

    return Promise.reject(error);
  });
};

const getWishlistPromiseGenerator: (
  getCatchupForAsset: FeedRequestFunction,
  getEpgForAsset: FeedRequestFunction,
  getVodForAsset: FeedRequestFunction,
  recordingsListFeed: NETGEM_API_V8_RAW_FEED,
  uri: string,
  now: number,
  signal: AbortSignal,
) => (item: NETGEM_API_WISHLIST_ITEM) => Promise<any> = (getCatchupForAsset, getEpgForAsset, getVodForAsset, recordingsListFeed, uri, now, signal) => {
  const { allChannels, q } = getValuesFromQueryString(uri, ['allChannels', 'q']);
  const getChannelIds = (item: NETGEM_API_WISHLIST_ITEM) => (allChannels !== '0' || !item.channelId ? undefined : [item.channelId]);

  switch (q) {
    case LOCAL_PROVIDER_QUERY.Catchup:
      return (item) => getCatchupForAssetRequest(getCatchupForAsset, item.id, now, getChannelIds(item), signal);

    case LOCAL_PROVIDER_QUERY.Est:
    case LOCAL_PROVIDER_QUERY.Svod:
    case LOCAL_PROVIDER_QUERY.Tvod:
      return (item) => getVodForAsset(item.id, now, MILLISECONDS_PER_HOUR, getChannelIds(item), signal);

    case LOCAL_PROVIDER_QUERY.Recording:
      return (item) => Promise.resolve(getMatchingRecordingFeed(recordingsListFeed, item.id));

    case LOCAL_PROVIDER_QUERY.Scheduledevent:
      return (item) => getEpgForAsset(item.id, now, MILLISECONDS_PER_WEEK, getChannelIds(item), signal);

    default:
  }

  return () => Promise.resolve();
};

const locationTypeToQueryType: (locationType: NETGEM_API_V8_ITEM_LOCATION_TYPE) => ?string = (locationType) => {
  switch (locationType) {
    case NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP:
      return LOCAL_PROVIDER_QUERY.Catchup;

    case NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING:
      return LOCAL_PROVIDER_QUERY.Recording;

    case NETGEM_API_V8_ITEM_LOCATION_TYPE_SVOD:
      return LOCAL_PROVIDER_QUERY.Svod;

    case NETGEM_API_V8_ITEM_LOCATION_TYPE_TVOD:
      return LOCAL_PROVIDER_QUERY.Tvod;

    case NETGEM_API_V8_ITEM_LOCATION_TYPE_EST:
      return LOCAL_PROVIDER_QUERY.Est;

    default:
      return null;
  }
};

const queryTypeToLocationType: (queryType: ?string) => ?NETGEM_API_V8_ITEM_LOCATION_TYPE = (queryType) => {
  switch (queryType) {
    case LOCAL_PROVIDER_QUERY.Catchup:
      return NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP;

    case LOCAL_PROVIDER_QUERY.Recording:
      return NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING;

    case LOCAL_PROVIDER_QUERY.Svod:
      return NETGEM_API_V8_ITEM_LOCATION_TYPE_SVOD;

    case LOCAL_PROVIDER_QUERY.Tvod:
      return NETGEM_API_V8_ITEM_LOCATION_TYPE_TVOD;

    case LOCAL_PROVIDER_QUERY.Est:
      return NETGEM_API_V8_ITEM_LOCATION_TYPE_EST;

    default:
      return null;
  }
};

/* eslint-disable no-magic-numbers */
const getPlaceholderItemCount: (tileType?: TileConfigTileType) => number =
  // eslint-disable-next-line consistent-return
  (tileType) => {
    if (tileType === undefined) {
      // Same as Gemtv
      return 6;
    }

    switch (tileType) {
      case TileConfigTileType.Channel:
        return 18;
      case TileConfigTileType.ChannelGroup:
        return 12;
      case TileConfigTileType.ChannelGroupBig:
        return 8;
      case TileConfigTileType.ChannelGroupBigFill:
        return 8;
      case TileConfigTileType.ChannelGroupFill:
        return 12;
      case TileConfigTileType.Deeplink:
        return 8;
      case TileConfigTileType.Deeplink3x1:
        return 3;
      case TileConfigTileType.Deeplink5x1:
        return 2;
      case TileConfigTileType.Gemtv:
        return 6;
      case TileConfigTileType.GemtvBig:
        return 4;
      case TileConfigTileType.GemtvMedium:
        return 6;
      case TileConfigTileType.Landscape:
        return 6;
      case TileConfigTileType.LandscapeBig:
        return 4;
      case TileConfigTileType.LandscapeVod:
        return 4;
      case TileConfigTileType.Portrait:
        return 8;

      // No default
    }
  };
/* eslint-enable no-magic-numbers */

const showDebugInfo: (props: CompleteSectionPropType, state: SectionStateType, instance: React.PureComponent<CompleteSectionPropType, SectionStateType>) => void = (props, state, instance) => {
  showDebug('Section', {
    instance,
    instanceFields: ['isVisible', 'isVodOrDeeplink', 'sectionName', 'sectionItemWidth', 'sectionType', 'sliderPositions', 'tileConfig'],
    props,
    propsFields: ['avenueIndex', 'cardData', 'gridSectionId', 'hubItem', 'isGridAvenue', 'onItemClick', 'recordingsListFeed', 'searchString', 'section', 'sectionIndex', 'viewingHistory', 'wishlist'],
    state,
    stateFields: [
      'displayType',
      'feed',
      'isCollapsed',
      'isInVodSeriesCard',
      'isLive',
      'isLoading',
      'itemCountPerPage',
      'maxPageIndex',
      'originalFeed',
      'pageIndex',
      'sortAndFilter',
      'updateDimensionsNeeded',
    ],
  });
};

const getTime: (d1?: string, d2?: string, d3?: string) => number | null = (d1, d2, d3) => {
  const d = d1 ?? d2 ?? d3;
  if (d === undefined) {
    return null;
  }

  return new Date(d).getTime();
};

const compareDate: (item1: NETGEM_API_V8_FEED_ITEM, item2: NETGEM_API_V8_FEED_ITEM) => number = (item1, item2) => {
  const {
    selectedLocation: { availabilityStartDate: availabilityStartDate1, scheduledEventStartDate: scheduledEventStartDate1, startDate: startDate1 },
  } = item1;
  const {
    selectedLocation: { availabilityStartDate: availabilityStartDate2, scheduledEventStartDate: scheduledEventStartDate2, startDate: startDate2 },
  } = item2;

  const time1 = getTime(startDate1, scheduledEventStartDate1, availabilityStartDate1);
  const time2 = getTime(startDate2, scheduledEventStartDate2, availabilityStartDate2);

  if (typeof time1 === 'number' && typeof time2 === 'number') {
    return time1 - time2;
  }

  if (typeof time1 === 'number') {
    return -1;
  }

  if (typeof time2 === 'number') {
    return 1;
  }

  return 0;
};

const checkSources: (sources?: Array<NETGEM_API_V8_NTG_VIDEO_FEED>, model: NETGEM_API_V8_AGGREGATION_FEED | NETGEM_API_V8_NTG_VIDEO_FEED) => Array<NETGEM_API_V8_NTG_VIDEO_FEED> = (sources, model) => {
  if (sources) {
    return sources;
  }

  const { authent, authentRealm, filter, method, params, queryType, sakName, scoring, slice, uri } = ((model: any): NETGEM_API_V8_NTG_VIDEO_FEED);

  if (!uri) {
    return [];
  }

  return [
    {
      authent,
      authentRealm,
      filter,
      method,
      params,
      provider: FeedProviderKind.Local,
      queryType,
      sakName,
      scoring,
      slice,
      uri,
    },
  ];
};

const isItemTypeMatching: (item: NETGEM_API_V8_FEED_RAW_ITEM, expectedType: string | null) => boolean = (item, expectedType) => {
  if (expectedType === null) {
    return false;
  }

  const firstLocation = getFirstLocation(item);
  if (firstLocation === null) {
    return false;
  }

  return getLocationType(firstLocation.id) === expectedType;
};

export {
  checkSources,
  compareDate,
  enableHotKeys,
  deserializeChannelIds,
  disableHotKeys,
  getChannelIdsOrAliasFromParameters,
  getLocalPath,
  getMatchingRecordingFeed,
  getPageStepFromKeyboardModifiers,
  getPlaceholderItemCount,
  getTimestampFromRange,
  getValuesFromQueryString,
  getViewingHistoryPromiseGenerator,
  getWishlistPromiseGenerator,
  isItemTypeMatching,
  locationTypeToQueryType,
  queryTypeToLocationType,
  showDebugInfo,
};
