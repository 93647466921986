/* @flow */

import './StartView.css';
import 'core-js/features/string/pad-end';
import 'core-js/features/string/pad-start';
import * as React from 'react';
import type { BasicFunction, KeyValuePair } from '@ntg/utils/dist/types';
import { FEATURE_SUBSCRIPTION, type FEATURE_TYPE, FEATURE_VOD } from '../../redux/appConf/constants';
import { createTagListAliases, sendV8ListAliasPostRequest } from '../../redux/netgemApi/actions/v8/listAlias';
import { logDebug, logError, logInfo, logWarning } from '../../helpers/debug/debug';
import { sendCommercialOffersRequest, sendDefaultRightsRequest, sendUserRightsRequest } from '../../redux/netgemApi/actions/v8/rights';
import { sendV8DefaultHubRequest, sendV8HubRequest } from '../../redux/netgemApi/actions/v8/hub';
import type { AppConfigurationFeatures } from '../../redux/appConf/types/types';
import type { CombinedReducers } from '../../redux/reducers';
import { CustomNetworkError } from '../../libs/netgemLibrary/helpers/CustomNetworkError';
import DebugPicto from '@ntg/components/dist/pictos/DebugPicto';
import type { Dispatch } from '../../redux/types/types';
import { DmsNetworkCode } from '../../libs/netgemLibrary/dms/constants/NetworkCodesAndMessages';
import HotKeys from '../../helpers/hotKeys/hotKeys';
import InfiniteCircleLoaderArc from '../loader/infiniteCircleLoaderArc';
import { Localizer } from '@ntg/utils/dist/localization';
import { MILLISECONDS_PER_SECOND } from '../../helpers/dateTime/Format';
import { RegistrationType } from '../../redux/appRegistration/types/types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { getAppLottieLoader } from '../../helpers/applicationCustomization/ui';
import { isTabHidden } from '../../helpers/ui/browser';
import lottie from 'lottie-web';
import { produce } from 'immer';
import sendGetDeviceChannelListRequest from '../../redux/netgemApi/actions/dms/channelList';
import sendGetDeviceSettingsRequest from '../../redux/netgemApi/actions/dms/deviceSettings';
import sendLoginRequest from '../../redux/netgemApi/actions/dms/login';
import sendV8DiscoveryRequest from '../../redux/netgemApi/actions/v8/discovery';
import sendV8MetadataChannelsRequest from '../../redux/netgemApi/actions/v8/metadataChannels';
import sendVideofuturAuthenticationRequest from '../../redux/netgemApi/actions/videofutur/authentication';
import sendVideofuturDiscoveryRequest from '../../redux/netgemApi/actions/videofutur/helpers/discovery';
import sendVideofuturPersonalDataRequest from '../../redux/netgemApi/actions/videofutur/personalData';
import { stopOpenStreams } from '../../redux/netgemApi/actions/videofutur/helpers/stopOpenStreams';
import { toggleDebugMode } from '../../redux/appConf/actions';

// $FlowFixMe: Flow doesn't know 'unit' style
const formatMs = (time: number) => new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 0, style: 'unit', unit: 'millisecond' }).format(time);

const Padding = Object.freeze({
  Duration: 10,
  InitSequence: 15,
  Name: 40,
  StepName: 23,
  Version: 31,
  VersionBO: 28,
  VersionCrmBack: 22,
  VersionCrmFront: 21,
});

const Css = Object.freeze({
  Initial: 'background-color: unset; color: unset; font-weight: normal',
  StepError: 'background-color: #F00; color: #000; font-weight: bold',
  StepSuccess: 'background-color: #0F0; color: #000; font-weight: bold',
});

// Wait two minutes at startup before declaring failure (in milliseconds)
const STARTING_TIMEOUT = 120_000;

enum QueryStatus {
  NotStarted = '',
  Disabled = 'disabled',
  Error = 'error',
  InProgress = 'inProgress',
  Success = 'success',
}

type STEP_ITEM = {|
  name: string,
  parameters?: Array<'applicationId' | 'deviceKey'>,
  request: any,
  then?: Array<STEP_ITEM>,
  thenAfter?: Array<STEP_ITEM>,
|};

type STEP_TYPE = {|
  displayIndex: number,
  displayName: string,
  feature?: FEATURE_TYPE,
  isOptional?: boolean,
  name: string,
|};

const StepName = Object.freeze({
  ChannelList: 'channelList',
  ChannelsInfo: 'channelsInfo',
  CommercialOffers: 'commercialOffers',
  DefaultHub: 'defaultHub',
  DefaultRights: 'defaultRights',
  DeviceSettings: 'deviceSettings',
  Discovery: 'discovery',
  Hub: 'hub',
  ListAlias: 'listAlias',
  Login: 'login',
  TagListAliases: 'tagListAliases',
  UserRights: 'userRights',
  VideofuturAuthentication: 'boAuthentication',
  VideofuturDiscovery: 'boDiscovery',
  VideofuturPersonalData: 'boPersonalData',
  VideofuturStopOpenStreams: 'boStopOpenStreams',
});

type STEP_SET = KeyValuePair<STEP_TYPE>;

// Define all existing startup steps
const STEPS: $ReadOnly<STEP_SET> = Object.freeze({
  [StepName.ChannelList]: {
    displayIndex: 2,
    displayName: 'DMS Channel List',
    name: StepName.ChannelList,
  },
  [StepName.ChannelsInfo]: {
    displayIndex: 5,
    displayName: 'PTF Channels Info',
    name: StepName.ChannelsInfo,
  },
  [StepName.CommercialOffers]: {
    displayIndex: 9,
    displayName: 'PTF Commercial Offers',
    feature: FEATURE_SUBSCRIPTION,
    isOptional: true,
    name: StepName.CommercialOffers,
  },
  [StepName.DefaultHub]: {
    displayIndex: 8,
    displayName: 'PTF Default Hub',
    name: StepName.DefaultHub,
  },
  [StepName.DefaultRights]: {
    displayIndex: 10,
    displayName: 'PTF Default Rights',
    feature: FEATURE_SUBSCRIPTION,
    isOptional: true,
    name: StepName.DefaultRights,
  },
  [StepName.DeviceSettings]: {
    displayIndex: 1,
    displayName: 'DMS Device Settings',
    name: StepName.DeviceSettings,
  },
  [StepName.Discovery]: {
    displayIndex: 3,
    displayName: 'PTF Discovery',
    name: StepName.Discovery,
  },
  [StepName.Hub]: {
    displayIndex: 7,
    displayName: 'PTF Hub',
    name: StepName.Hub,
  },
  [StepName.ListAlias]: {
    displayIndex: 4,
    displayName: 'PTF Channel List Alias',
    name: StepName.ListAlias,
  },
  [StepName.Login]: {
    displayIndex: 0,
    displayName: 'DMS Login',
    name: StepName.Login,
  },
  [StepName.TagListAliases]: {
    displayIndex: 6,
    displayName: 'PTF Tag List Aliases',
    name: StepName.TagListAliases,
  },
  [StepName.UserRights]: {
    displayIndex: 11,
    displayName: 'PTF User Rights',
    feature: FEATURE_SUBSCRIPTION,
    isOptional: true,
    name: StepName.UserRights,
  },
  [StepName.VideofuturAuthentication]: {
    displayIndex: 12,
    displayName: 'BO Login',
    feature: FEATURE_VOD,
    name: StepName.VideofuturAuthentication,
  },
  [StepName.VideofuturDiscovery]: {
    displayIndex: 12,
    displayName: 'BO Discovery',
    feature: FEATURE_VOD,
    name: StepName.VideofuturDiscovery,
  },
  [StepName.VideofuturPersonalData]: {
    displayIndex: 14,
    displayName: 'BO Personal Data',
    feature: FEATURE_VOD,
    name: StepName.VideofuturPersonalData,
  },
  [StepName.VideofuturStopOpenStreams]: {
    displayIndex: 15,
    displayName: 'BO Kill Streams (<count>)',
    feature: FEATURE_VOD,
    name: StepName.VideofuturStopOpenStreams,
  },
});

type ReduxStartReducerStateType = {|
  +applicationId: string,
  +applicationName: string,
  +authDeviceUrl: string,
  +authenticationToken: string | null,
  +deviceKey: string,
  +features: AppConfigurationFeatures,
  +isDebugModeEnabled: boolean,
  +isRegisteredAsGuest: boolean,
  +subscriberId: string,
  +upgradeDeviceUrl: string,
  +useBOV2Api: boolean,
  +versionBO: string,
  +versionCrmBack: string,
  +versionCrmFront: string,
  +versionShort: string,
|};

type ReduxStartDispatchToPropsType = {|
  +localCreateTagListAliases: (signal: AbortSignal) => Promise<any>,
  +localSendCommercialOfferRequest: (signal: AbortSignal) => Promise<any>,
  +localSendDefaultHubRequest: (signal: AbortSignal) => Promise<any>,
  +localSendDefaultRightsRequest: (signal: AbortSignal) => Promise<any>,
  +localSendDiscoveryRequest: (signal: AbortSignal) => Promise<any>,
  +localSendGetDeviceChannelListRequest: (signal: AbortSignal) => Promise<any>,
  +localSendGetDeviceSettingRequest: (signal: AbortSignal) => Promise<any>,
  +localSendHubRequest: (signal: AbortSignal) => Promise<any>,
  +localSendListAliasPostRequest: (signal: AbortSignal) => Promise<any>,
  +localSendLoginRequest: (authDeviceUrl: string, upgradeDeviceUrl: string, applicationId: string, subscriberId: string, deviceKey: string, signal?: AbortSignal) => Promise<any>,
  +localSendMetadataChannelsRequest: (signal: AbortSignal) => Promise<any>,
  +localSendUserRightsRequest: (signal: AbortSignal) => Promise<any>,
  +localSendVideofuturAuthenticationRequest: (applicationId: string, deviceKey: string, signal: AbortSignal) => Promise<any>,
  +localSendVideofuturDiscoveryRequest: (signal: AbortSignal) => Promise<any>,
  +localSendVideofuturPersonalDataRequest: (signal: AbortSignal) => Promise<any>,
  +localStopVideofuturOpenStreams: (signal: AbortSignal) => Promise<any>,
  +localToggleDebugMode: BasicFunction,
|};

type StartPropType = {|
  +isLoadingAnimationVisible: boolean,
  +failureCallback: BasicFunction,
  +successCallback: BasicFunction,
  +timeoutCallback: BasicFunction,
  +unrecoverableFailureCallback: BasicFunction,
|};

type CompleteStartPropType = {|
  ...StartPropType,
  ...ReduxStartReducerStateType,
  ...ReduxStartDispatchToPropsType,
|};

type StartStateType = {|
  currentSteps: Array<STEP_ITEM>,
  durations: KeyValuePair<string>,
  isDebugPanelVisible: ?boolean,
  killedStreamCount: string,
  nextSteps: Array<STEP_ITEM>,
  queryStatuses: KeyValuePair<QueryStatus>,
  stepProgress: number,
|};

const InitialState: $ReadOnly<StartStateType> = Object.freeze({
  currentSteps: [],
  durations: {},
  isDebugPanelVisible: null,
  killedStreamCount: '0',
  nextSteps: [],
  queryStatuses: {
    [StepName.ChannelList]: QueryStatus.NotStarted,
    [StepName.ChannelsInfo]: QueryStatus.NotStarted,
    [StepName.CommercialOffers]: QueryStatus.NotStarted,
    [StepName.DefaultHub]: QueryStatus.NotStarted,
    [StepName.DefaultRights]: QueryStatus.NotStarted,
    [StepName.DeviceSettings]: QueryStatus.NotStarted,
    [StepName.Discovery]: QueryStatus.NotStarted,
    [StepName.Hub]: QueryStatus.NotStarted,
    [StepName.ListAlias]: QueryStatus.NotStarted,
    [StepName.Login]: QueryStatus.NotStarted,
    [StepName.TagListAliases]: QueryStatus.NotStarted,
    [StepName.UserRights]: QueryStatus.NotStarted,
    [StepName.VideofuturAuthentication]: QueryStatus.NotStarted,
    [StepName.VideofuturDiscovery]: QueryStatus.NotStarted,
    [StepName.VideofuturPersonalData]: QueryStatus.NotStarted,
    [StepName.VideofuturStopOpenStreams]: QueryStatus.NotStarted,
  },
  stepProgress: 1,
});

class StartView extends React.PureComponent<CompleteStartPropType, StartStateType> {
  abortController: AbortController;

  animation: any;

  areQueriesFinished: boolean;

  enabledSteps: Set<string>;

  initializationSequenceEnd: number;

  initializationSequenceStart: number;

  isAnimationFinished: boolean;

  mainLayout: HTMLElement | null;

  orderedSteps: Array<STEP_TYPE>;

  rootStep: STEP_ITEM;

  startedSteps: Set<string>;

  startingTimer: TimeoutID | null;

  stepCount: number;

  constructor(props: CompleteStartPropType) {
    super(props);

    const {
      isDebugModeEnabled,
      localCreateTagListAliases,
      localSendCommercialOfferRequest,
      localSendDefaultHubRequest,
      localSendDefaultRightsRequest,
      localSendDiscoveryRequest,
      localSendGetDeviceChannelListRequest,
      localSendGetDeviceSettingRequest,
      localSendHubRequest,
      localSendListAliasPostRequest,
      localSendMetadataChannelsRequest,
      localSendUserRightsRequest,
      localSendVideofuturAuthenticationRequest,
      localSendVideofuturDiscoveryRequest,
      localSendVideofuturPersonalDataRequest,
      localStopVideofuturOpenStreams,
    } = props;

    this.abortController = new AbortController();
    this.areQueriesFinished = false;
    this.initializationSequenceEnd = 0;
    this.initializationSequenceStart = performance.now();
    this.isAnimationFinished = true;
    this.mainLayout = null;
    this.startedSteps = new Set<string>();
    this.startingTimer = null;

    const { enabled, ordered } = this.prepareSteps(STEPS);
    this.enabledSteps = enabled;
    this.orderedSteps = ordered;
    this.stepCount = this.orderedSteps.length;

    this.rootStep = {
      // DMS Login
      name: StepName.Login,
      request: null,
      then: [
        {
          // DMS Device Settings
          name: StepName.DeviceSettings,
          request: localSendGetDeviceSettingRequest,
          then: [
            {
              // DMS Channel List
              name: StepName.ChannelList,
              request: localSendGetDeviceChannelListRequest,
            },
            {
              // Platform Discovery
              name: StepName.Discovery,
              request: localSendDiscoveryRequest,
            },
            {
              // BO Login
              name: StepName.VideofuturAuthentication,
              parameters: ['applicationId', 'deviceKey'],
              request: localSendVideofuturAuthenticationRequest,
            },
          ],
        },
      ],
      thenAfter: [
        {
          // Platform Channel List Alias: needs channelList & discovery
          name: StepName.ListAlias,
          request: localSendListAliasPostRequest,
          then: [
            {
              // Platform Channels Info: needs List Alias
              name: StepName.ChannelsInfo,
              request: localSendMetadataChannelsRequest,
              then: [
                {
                  // Platform Tag List Aliases: creates one list alias per tag (needs Channels Info)
                  name: StepName.TagListAliases,
                  request: localCreateTagListAliases,
                },
              ],
            },
          ],
        },
        {
          // Platform Default Hub: needs discovery
          name: StepName.DefaultHub,
          request: localSendDefaultHubRequest,
        },
        {
          // BO Discovery: needs BO Auth (only)
          name: StepName.VideofuturDiscovery,
          request: localSendVideofuturDiscoveryRequest,
          then: [
            {
              // BO Personal Data
              name: StepName.VideofuturPersonalData,
              request: localSendVideofuturPersonalDataRequest,
              then: [
                {
                  // Platform Hub: needs discovery & BO Auth
                  name: StepName.Hub,
                  request: localSendHubRequest,
                },
              ],
            },
            {
              // BO Kill Streams
              name: StepName.VideofuturStopOpenStreams,
              request: localStopVideofuturOpenStreams,
            },
          ],
        },
        {
          // Platform Commercial Offers
          name: StepName.CommercialOffers,
          request: localSendCommercialOfferRequest,
        },
        {
          // Platform Default Rights
          name: StepName.DefaultRights,
          request: localSendDefaultRightsRequest,
        },
        {
          // Platform User Rights
          name: StepName.UserRights,
          request: localSendUserRightsRequest,
        },
      ],
    };

    this.state = {
      ...InitialState,
      currentSteps: [this.rootStep],
      isDebugPanelVisible: isDebugModeEnabled ? true : null,
    };
  }

  componentDidMount() {
    const { applicationId, authDeviceUrl, deviceKey, isLoadingAnimationVisible, timeoutCallback, subscriberId, upgradeDeviceUrl } = this.props;

    HotKeys.register(['alt+d', 'ctrl+d', 'shift+d'], this.handleToggleDebugPanelHotKey, { name: 'Start.debug' });

    // Initialize starting sequence by logging into the DMS
    this.sendLoginRequest(authDeviceUrl, upgradeDeviceUrl, applicationId, subscriberId, deviceKey);
    this.startingTimer = setTimeout(timeoutCallback, STARTING_TIMEOUT);

    if (!isLoadingAnimationVisible) {
      // Hide loading animation is this screen is shown more than once
      return;
    }

    const animationData = getAppLottieLoader();

    if (animationData) {
      this.isAnimationFinished = false;

      // $FlowFixMe
      this.animation = lottie.loadAnimation({
        animationData,
        autoplay: true,
        container: document.getElementsByClassName('loaderAnimation')[0],
        loop: false,
        renderer: 'svg',
        rendererSettings: {
          progressiveLoad: true,
        },
      });

      this.animation.addEventListener('complete', this.onAnimationComplete, { passive: true });
    }
  }

  componentDidUpdate(prevProps: CompleteStartPropType) {
    const { authenticationToken } = this.props;
    const { authenticationToken: prevAuthenticationToken } = prevProps;

    if (authenticationToken !== prevAuthenticationToken) {
      this.handleTokenUpdate(authenticationToken);
    }
  }

  componentWillUnmount() {
    const { successCallback } = this.props;
    const { abortController, animation, mainLayout } = this;

    this.resetStartingTimer();

    abortController.abort('Component StartView will unmount');

    HotKeys.unregister(['alt+d', 'ctrl+d', 'shift+d'], this.handleToggleDebugPanelHotKey);

    if (mainLayout) {
      mainLayout.removeEventListener('animationend', successCallback, { passive: true });
    }

    if (animation) {
      animation.removeEventListener('complete', this.onAnimationComplete, { passive: true });
      animation.destroy();
    }
  }

  isBOStepEnabled = (name: string): boolean => {
    const { useBOV2Api } = this.props;

    // BO authentication (BO API V1) and personal data (BO API V2) are mutually exclusive
    return (!useBOV2Api || name !== StepName.VideofuturAuthentication) && (useBOV2Api || name !== StepName.VideofuturPersonalData);
  };

  prepareSteps = (steps: $ReadOnly<STEP_SET>): {| enabled: Set<string>, ordered: Array<STEP_TYPE> |} => {
    const { features } = this.props;

    // Filter out steps linked to disable features
    const enabled = new Set<string>();
    const ordered: Array<STEP_TYPE> = [];
    ((Object.values(steps): any): Array<STEP_TYPE>).forEach((value) => {
      const { feature, name } = value;

      if ((!feature || features[feature]) && this.isBOStepEnabled(name)) {
        enabled.add(name);
        ordered.push(value);
      }
    });

    // Sort steps for display
    ordered.sort((a, b) => a.displayIndex - b.displayIndex);

    return {
      enabled,
      ordered,
    };
  };

  handleTokenUpdate = (token: string | null) => {
    if (token) {
      // Logging OK
      this.proceedToSecondStep();
    }
  };

  resetStartingTimer = () => {
    if (this.startingTimer) {
      clearTimeout(this.startingTimer);
      this.startingTimer = null;
    }
  };

  handleToggleDebugPanelHotKey = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState(
      produce((draft) => {
        draft.isDebugPanelVisible = !draft.isDebugPanelVisible;
      }),
    );
  };

  onAnimationComplete = () => {
    this.isAnimationFinished = true;
    this.checkInitializationSequenceEnd();
  };

  checkInitializationSequenceEnd = () => {
    const { applicationName, successCallback, versionBO, versionCrmBack, versionCrmFront, versionShort } = this.props;
    const { durations, killedStreamCount, queryStatuses } = this.state;
    const { animation, areQueriesFinished, initializationSequenceEnd, initializationSequenceStart, isAnimationFinished, mainLayout, orderedSteps } = this;

    const isAppHidden = isTabHidden() === true;

    if (areQueriesFinished && (isAnimationFinished || isAppHidden)) {
      const duration = new Intl.NumberFormat('fr-FR', {
        maximumFractionDigits: 3,
        minimumFractionDigits: 3,
        // $FlowFixMe: Flow doesn't know 'unit' style
        style: 'unit',
        unit: 'second',
      }).format((initializationSequenceEnd - initializationSequenceStart) / MILLISECONDS_PER_SECOND);
      logInfo('#==========================================#');
      logInfo(`║ ${applicationName.padEnd(Padding.Name)} ║`);
      logInfo('║                                          ║');
      logInfo(`║ Version: ${versionShort.padEnd(Padding.Version)} ║`);

      if (versionBO !== '') {
        logInfo(`║ Version BO: ${versionBO.padEnd(Padding.VersionBO)} ║`);
      }

      if (versionCrmBack !== '') {
        logInfo(`║ Version CRM Back: ${versionCrmBack.padEnd(Padding.VersionCrmBack)} ║`);
      }

      if (versionCrmFront !== '') {
        logInfo(`║ Version CRM Front: ${versionCrmFront.padEnd(Padding.VersionCrmFront)} ║`);
      }

      logInfo(`║ Initialization sequence: ${duration.padStart(Padding.InitSequence)} ║`);
      logInfo('#==========================================#');

      orderedSteps.forEach((step) => {
        const { displayName, name } = step;
        const { [name]: stepStatus } = queryStatuses;
        const { [name]: stepDuration } = durations;
        const statusStr = stepStatus === QueryStatus.Success ? 'OK' : 'KO';
        const css = stepStatus === QueryStatus.Success ? Css.StepSuccess : Css.StepError;
        const localDisplayName = StepName.VideofuturStopOpenStreams ? displayName.replace('<count>', killedStreamCount) : displayName;
        logDebug(`${localDisplayName.padEnd(Padding.StepName)} %c ${statusStr} %c ${stepDuration?.padStart(Padding.Duration) ?? ''}`, css, Css.Initial);
      });

      if (mainLayout) {
        mainLayout.classList.add('hidden');
        mainLayout.classList.remove('visible');

        if (isAppHidden) {
          // Hard transition since animation is not visible anyway
          animation?.destroy();
          this.animation = null;
          successCallback();
        } else {
          // Smooth transition
          mainLayout.addEventListener('animationend', successCallback, { passive: true });
        }
      } else {
        // Not suppose to happen
        successCallback();
      }
    }
  };

  sendLoginRequest = (authDeviceUrl: string, upgradeDeviceUrl: string, applicationId: string, subscriberId: string, deviceKey: string) => {
    const { localSendLoginRequest, unrecoverableFailureCallback } = this.props;

    this.setState(
      produce((draft) => {
        draft.queryStatuses[StepName.Login] = QueryStatus.InProgress;
      }),
    );

    const requestStart = performance.now();

    localSendLoginRequest(authDeviceUrl, upgradeDeviceUrl, applicationId, subscriberId, deviceKey)
      .then((result) => {
        if (result === undefined || result.status !== DmsNetworkCode.Created) {
          throw new Error('DMS authentication error');
        }

        const requestEnd = performance.now();
        this.setState(
          produce((draft) => {
            draft.durations[StepName.Login] = formatMs(requestEnd - requestStart);
          }),
        );
      })
      .catch((error: CustomNetworkError | Error) => {
        this.setState(
          produce((draft) => {
            draft.queryStatuses[StepName.Login] = QueryStatus.Error;
          }),
        );

        logError(`Login error: ${error.message}`);

        if (error instanceof CustomNetworkError) {
          logError(error.networkError);
          const customStatus = error.getCustomStatus();
          if (
            customStatus !== (DmsNetworkCode.MissingOrInvalidAuthenticationToken: number) &&
            // Unknown device
            customStatus !== (DmsNetworkCode.UnknownDevice: number) &&
            // Invalid device
            customStatus !== (DmsNetworkCode.InvalidDevice: number) &&
            // DMS did not provide correct error code
            customStatus !== (DmsNetworkCode.UnknownError: number)
          ) {
            // The reasons above cause the display of the login page
            unrecoverableFailureCallback();
          }
        }
      });
  };

  proceedToSecondStep = () => {
    this.setState(
      produce((draft) => {
        draft.currentSteps = this.rootStep.then ?? [];
        draft.queryStatuses[StepName.Login] = QueryStatus.Success;
        draft.nextSteps = this.rootStep.thenAfter ?? [];
      }),
      this.processCurrentSteps,
    );
  };

  filterCurrentSteps = (currentSteps: Array<STEP_ITEM>, disabledSteps: Array<STEP_ITEM> | STEP_ITEM): Array<STEP_ITEM> => {
    const localDisabledSteps = Array.isArray(disabledSteps) ? disabledSteps : [disabledSteps];

    // Remove disabled steps from current steps
    const newSteps = currentSteps.filter(({ name }) => localDisabledSteps.every((s) => s.name !== name));

    // Add children processes of disabled steps to current steps
    localDisabledSteps.forEach((step) => {
      if (step.then) {
        newSteps.push(...step.then);
      }
    });

    return newSteps;
  };

  processCurrentSteps = () => {
    const { enabledSteps } = this;
    const {
      currentSteps,
      currentSteps: { length: stepCount },
      nextSteps,
      nextSteps: { length: nextStepCount },
    } = this.state;

    if (stepCount === 0) {
      if (nextStepCount > 0) {
        // Proceed to second pass steps
        this.setState(
          produce((draft) => {
            draft.currentSteps = nextSteps;
            draft.nextSteps = [];
          }),
          this.processCurrentSteps,
        );
      } else {
        // All done
        this.resetStartingTimer();
        this.initializationSequenceEnd = performance.now();
        this.areQueriesFinished = true;
        this.checkInitializationSequenceEnd();
      }
      return;
    }

    const disabledSteps = [];
    let hasStartedProcess = false;

    // Process current steps not started yet
    for (let i = 0; i < stepCount; i++) {
      const { [i]: step } = currentSteps;
      const { name } = step;
      const {
        queryStatuses: { [name]: stepStatus },
      } = this.state;

      if (stepStatus === QueryStatus.NotStarted && stepStatus !== QueryStatus.Disabled && !this.startedSteps.has(name)) {
        if (enabledSteps.has(name)) {
          // Step is enabled
          hasStartedProcess = true;
          this.startedSteps.add(name);
          this.setState(
            produce((draft) => {
              draft.queryStatuses[name] = QueryStatus.InProgress;
            }),
            () => this.processStep(step),
          );
        } else {
          // Step is disabled
          disabledSteps.push(step);
        }
      }
    }

    if (disabledSteps.length > 0) {
      this.setState(
        produce((draft) => {
          draft.currentSteps = this.filterCurrentSteps(draft.currentSteps, disabledSteps);
          disabledSteps.forEach(({ name }) => (draft.queryStatuses[name] = QueryStatus.Disabled));
        }),
        () => {
          if (!hasStartedProcess) {
            // All current processes have been disabled, process next ones
            this.processCurrentSteps();
          }
        },
      );
    }
  };

  processStep = (step: STEP_ITEM) => {
    const {
      abortController: { signal },
      props,
    } = this;
    const { name, parameters, request } = step;

    if (!request) {
      return;
    }

    const args = parameters?.map((p) => props[p]) ?? [];
    const requestStart = performance.now();

    request(...args, signal)
      .then((response) => {
        const requestEnd = performance.now();

        if (name === StepName.VideofuturStopOpenStreams) {
          this.setState({ killedStreamCount: response.toString() });
        }

        this.setState(
          produce((draft) => {
            draft.currentSteps = this.filterCurrentSteps(draft.currentSteps, step);
            draft.durations[name] = formatMs(requestEnd - requestStart);
            draft.queryStatuses[name] = QueryStatus.Success;
            draft.stepProgress += 1;
          }),
          this.processCurrentSteps,
        );
      })
      .catch((error) => this.handleStepError(error, step));
  };

  handleStepError = (error: Error, step: STEP_ITEM) => {
    const { isRegisteredAsGuest, failureCallback } = this.props;
    const { abortController } = this;
    const { name } = step;

    // $FlowFixMe: flow doesn't know DOMException
    if (error instanceof DOMException && error.name === 'AbortError') {
      // Error should be ignored since everything has already been taken care of
      return;
    }

    let message: ?string = null;

    if (error instanceof CustomNetworkError) {
      const { networkError } = error;
      if (networkError) {
        ({ message } = networkError);
      }
    }

    this.setState(
      produce((draft) => {
        draft.queryStatuses[name] = QueryStatus.Error;
      }),
    );

    const {
      [name]: { isOptional },
    } = STEPS;
    const logger = isOptional ? logWarning : logError;

    logger(`Error in step "${name}": ${message ?? 'no message'}`);
    logger(error);

    if (isOptional) {
      // Step failed but is optional: process remaining steps
      this.setState(
        produce((draft) => {
          draft.currentSteps = this.filterCurrentSteps(draft.currentSteps, step);
          draft.stepProgress += 1;
        }),
        this.processCurrentSteps,
      );
    } else {
      // Step failed and is not optional: stop everything
      abortController.abort('Unrecoverable error in StartView');
    }

    if (isRegisteredAsGuest || name === StepName.DeviceSettings || name === StepName.VideofuturAuthentication) {
      // Error in a mandatory step while in guest mode means something very bad: bail out and display login page to avoid infinite reload
      failureCallback();
    }
  };

  handleVersionOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
    const { localToggleDebugMode } = this.props;

    if ((event.ctrlKey || event.altKey) && event.shiftKey) {
      localToggleDebugMode();
    }
  };

  renderDebugPanel = (): React.Node => {
    const { isDebugModeEnabled, versionShort: version } = this.props;
    const { durations, isDebugPanelVisible, killedStreamCount, queryStatuses } = this.state;
    const { orderedSteps } = this;

    if (!orderedSteps) {
      // Startup tree not built yet
      return null;
    }

    return (
      <div className={clsx('debugContainer', isDebugPanelVisible !== null && (isDebugPanelVisible ? 'slideIn' : 'slideOut'))}>
        <div className='version' onClick={this.handleVersionOnClick}>
          <div>Version {version}</div>
          <DebugPicto isEnabled={isDebugModeEnabled} />
        </div>
        {orderedSteps.map((step) => {
          const { displayName, name } = step;
          const { [name]: stepStatus } = queryStatuses;
          const { [name]: duration } = durations;

          return (
            <div className={clsx('line', stepStatus)} key={name}>
              <div className='label'>{name === StepName.VideofuturStopOpenStreams ? displayName.replace('<count>', killedStreamCount) : displayName}</div>
              <div className='duration'>{duration}</div>
              <div className='status'>{stepStatus === QueryStatus.InProgress ? <InfiniteCircleLoaderArc className='queryLoader' /> : null}</div>
            </div>
          );
        })}
      </div>
    );
  };

  render(): React.Node {
    const { state, stepCount } = this;
    const { isDebugModeEnabled, isLoadingAnimationVisible, versionShort: version } = this.props;
    const { stepProgress } = state;

    const progress = stepProgress / stepCount;

    return (
      <div className='start'>
        <div
          className='mainLayout visible'
          ref={(instance) => {
            this.mainLayout = instance;
          }}
        >
          <div className='loaderAnimation' />
          <div className='progressBar' style={{ transform: `scaleX(${progress})` }} />
          {this.renderDebugPanel()}
        </div>
        <div className='footerVersion' onClick={this.handleVersionOnClick}>
          <div>{Localizer.localize('authentication.version', { version })}</div>
          <DebugPicto isEnabled={isDebugModeEnabled} />
        </div>
        {!isLoadingAnimationVisible ? <InfiniteCircleLoaderArc className='startLoader' /> : null}
      </div>
    );
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxStartReducerStateType => {
  return {
    applicationId: state.appRegistration.applicationId,
    applicationName: state.appConfiguration.applicationName,
    authDeviceUrl: state.appRegistration.authDeviceUrl,
    authenticationToken: state.appRegistration.authenticationToken,
    deviceKey: state.appRegistration.deviceKey,
    features: state.appConfiguration.features,
    isDebugModeEnabled: state.appConfiguration.isDebugModeEnabled,
    isRegisteredAsGuest: state.appRegistration.registration === RegistrationType.RegisteredAsGuest,
    subscriberId: state.appRegistration.subscriberId,
    upgradeDeviceUrl: state.appRegistration.upgradeDeviceUrl,
    useBOV2Api: state.appConfiguration.useBOV2Api,
    versionBO: state.appConfiguration.versionBO,
    versionCrmBack: state.appConfiguration.versionCrmBack,
    versionCrmFront: state.appConfiguration.versionCrmFront,
    versionShort: state.appConfiguration.versionAppShort,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxStartDispatchToPropsType => {
  return {
    localCreateTagListAliases: (signal: AbortSignal): Promise<any> => dispatch(createTagListAliases(signal)),
    localSendCommercialOfferRequest: (signal: AbortSignal) => dispatch(sendCommercialOffersRequest(signal)),
    localSendDefaultHubRequest: (signal: AbortSignal): Promise<any> => dispatch(sendV8DefaultHubRequest(signal)),
    localSendDefaultRightsRequest: (signal: AbortSignal) => dispatch(sendDefaultRightsRequest(signal)),
    localSendDiscoveryRequest: (signal: AbortSignal): Promise<any> => dispatch(sendV8DiscoveryRequest(signal)),
    localSendGetDeviceChannelListRequest: (signal: AbortSignal): Promise<any> => dispatch(sendGetDeviceChannelListRequest(signal)),
    localSendGetDeviceSettingRequest: (signal: AbortSignal): Promise<any> => dispatch(sendGetDeviceSettingsRequest(signal)),
    localSendHubRequest: (signal: AbortSignal): Promise<any> => dispatch(sendV8HubRequest(null, signal)),
    localSendListAliasPostRequest: (signal: AbortSignal): Promise<any> => dispatch(sendV8ListAliasPostRequest(null, signal)),
    localSendLoginRequest: (authDeviceUrl: string, upgradeDeviceUrl: string, applicationId: string, subscriberId: string, deviceKey: string, signal?: AbortSignal): Promise<any> =>
      dispatch(sendLoginRequest(authDeviceUrl, upgradeDeviceUrl, applicationId, subscriberId, deviceKey, null, null, true, signal)),
    localSendMetadataChannelsRequest: (signal: AbortSignal): Promise<any> => dispatch(sendV8MetadataChannelsRequest(signal)),
    localSendUserRightsRequest: (signal: AbortSignal) => dispatch(sendUserRightsRequest(signal)),
    localSendVideofuturAuthenticationRequest: (applicationId: string, deviceKey: string, signal: AbortSignal): Promise<any> =>
      dispatch(sendVideofuturAuthenticationRequest(applicationId, deviceKey, signal)),
    localSendVideofuturDiscoveryRequest: (signal: AbortSignal): Promise<any> => dispatch(sendVideofuturDiscoveryRequest(signal)),
    localSendVideofuturPersonalDataRequest: (signal: AbortSignal): Promise<any> => dispatch(sendVideofuturPersonalDataRequest(signal)),
    localStopVideofuturOpenStreams: (signal: AbortSignal): Promise<any> => dispatch(stopOpenStreams(null, signal)),
    localToggleDebugMode: () => dispatch(toggleDebugMode()),
  };
};

const Start: React.ComponentType<StartPropType> = connect(mapStateToProps, mapDispatchToProps)(StartView);

export default Start;
