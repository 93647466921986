/* @flow */

// $FlowFixMe: CSS file only exists in dist folder
import './Base.css';
import * as React from 'react';
// $FlowFixMe: Flow cannot resolve clsx
import clsx from 'clsx';

export type ExportedPictoPropType = {|
  +className?: string,
  +data?: string,
  +draggable?: boolean,
  +forceHoverEffect?: boolean,
  +forwardedRef?: any,
  +hasBackground?: boolean,
  +isDisabled?: boolean,
  +key?: string,
  +onClick?: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void,
  +onMouseDown?: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void,
  +onMouseEnter?: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void,
  +onMouseLeave?: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void,
  +onMouseMove?: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void,
  +onMouseUp?: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void,
|};

export type PictoPropType = {|
  ...ExportedPictoPropType,
  +svgElement: React.Node,
|};

class PictoView extends React.PureComponent<PictoPropType> {
  static defaultProps: ExportedPictoPropType = {
    className: '',
    data: undefined,
    draggable: false,
    forceHoverEffect: false,
    forwardedRef: undefined,
    hasBackground: false,
    isDisabled: false,
    key: undefined,
    onClick: undefined,
    onMouseDown: undefined,
    onMouseEnter: undefined,
    onMouseLeave: undefined,
    onMouseMove: undefined,
    onMouseUp: undefined,
  };

  render(): React.Node {
    const {
      className = '',
      data,
      draggable,
      forceHoverEffect,
      forwardedRef,
      hasBackground,
      key,
      isDisabled,
      onClick,
      onMouseDown,
      onMouseEnter,
      onMouseLeave,
      onMouseMove,
      onMouseUp,
      svgElement,
    } = this.props;

    const backgroundElement = hasBackground ? <div className='background' /> : null;

    if (onClick !== undefined) {
      // Clickable
      return (
        <button
          className={clsx('pictoElement', 'hoverEffect', className)}
          data={data}
          disabled={isDisabled}
          draggable={draggable}
          key={key}
          onClick={onClick}
          onMouseDown={onMouseDown}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          ref={forwardedRef}
          type='button'
        >
          {backgroundElement}
          {svgElement}
        </button>
      );
    }

    // Not clickable
    return (
      <div
        className={clsx('pictoElement', className, forceHoverEffect && 'hoverEffect')}
        data={data}
        disabled={isDisabled}
        draggable={draggable}
        key={key}
        onMouseDown={onMouseDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        ref={forwardedRef}
      >
        {backgroundElement}
        {svgElement}
      </div>
    );
  }
}

export { PictoView };
