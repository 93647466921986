/* @flow */

import { ItemContent, type NETGEM_API_V8_FEED_ITEM } from '../../libs/netgemLibrary/v8/types/FeedItem';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import type { NETGEM_API_V8_METADATA_PROGRAM, NETGEM_API_V8_METADATA_SERIES } from '../../libs/netgemLibrary/v8/types/MetadataProgram';
import AccurateTimestamp from '../dateTime/AccurateTimestamp';
import type { CARD_DATA_MODAL_TYPE } from '../../components/modal/cardModal/CardModalConstsAndTypes';
import { Definition } from '../ui/metadata/Types';
import LocalStorageManager from '../localStorage/localStorageManager';
import { type ModalState } from '../../redux/modal/reducers';
import type { NETGEM_API_V8_AUTHENT_REALM } from '../../libs/netgemLibrary/v8/types/Realm';
import type { NETGEM_API_V8_METADATA_SCHEDULE_LOCATION } from '../../libs/netgemLibrary/v8/types/MetadataSchedule';
import type { NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND } from '../../libs/netgemLibrary/v8/types/Npvr';
import type { PurchaseType } from '../../redux/netgemApi/actions/videofutur/types/purchase';
import { StorageKeys } from '../localStorage/keys';

// After 5 minutes, any pending operation becomes obsolete (in ms)
const PENDING_OPERATION_TTL = 300_000;

export enum PendingOperationKind {
  AddToFavorite = 'add_to_favorite',
  AddToWishlist = 'add_to_wishlist',
  OpenCard = 'open_card',
  Play = 'play',
  Purchase = 'purchase',
  Record = 'record',
}

export enum PendingOperationReason {
  RequireAccount = 'require_account',
  RequireSubscription = 'require_subscription',
}

export type ADD_TO_FAVORITE_DATA_TYPE = {|
  titId: string,
|};

export type ADD_TO_WISHLIST_DATA_TYPE = {|
  assetId: string,
  channelId: string,
|};

export type BASE_VOD_PURCHASE_DATA_TYPE = {|
  definition: Definition,
  displayPrice: string,
  distributorId: string,
  itemCount: number,
  licenseDuration?: string,
  locationId?: string,
  otherVtiId?: number,
  purchaseType: PurchaseType,
  vodLocationMetadata?: NETGEM_API_V8_METADATA_SCHEDULE_LOCATION,
  vtiId: number,
|};

export type VOD_PURCHASE_DATA_TYPE = {|
  ...BASE_VOD_PURCHASE_DATA_TYPE,
  item: NETGEM_API_V8_FEED_ITEM,
  programMetadata: NETGEM_API_V8_METADATA_PROGRAM,
  seriesMetadata: ?NETGEM_API_V8_METADATA_SERIES,
  viewingHistoryId?: string,
|};

export type PENDING_OPERATION_DATA_TYPE = {|
  authority?: NETGEM_API_V8_AUTHENT_REALM | null,
  addToFavoriteData?: ADD_TO_FAVORITE_DATA_TYPE | null,
  addToWishlistData?: ADD_TO_WISHLIST_DATA_TYPE | null,
  contentType?: ItemContent | null,
  operationType: PendingOperationKind,
  purchaseData?: VOD_PURCHASE_DATA_TYPE | null,
  recordKind?: NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND | null,
  reason: PendingOperationReason,
|};

type PENDING_OPERATION_TYPE = {|
  cardData: CARD_DATA_MODAL_TYPE,
  date: number,
|};

const storePendingOperation: (cardData: CARD_DATA_MODAL_TYPE) => void = (cardData) => {
  LocalStorageManager.save(StorageKeys.PendingOperation, {
    cardData,
    date: AccurateTimestamp.now(),
  });
};

const getPendingOperation: () => ?PENDING_OPERATION_TYPE = () => LocalStorageManager.loadObject(StorageKeys.PendingOperation, null);

const clearPendingPurchase: () => void = () => {
  const pendingOp = getPendingOperation();
  const operationType = pendingOp?.cardData.pendingOperation?.operationType;

  if (operationType === PendingOperationKind.Purchase) {
    clearPendingOperation();
  }
};

const clearPendingOperation: () => void = () => {
  LocalStorageManager.delete(StorageKeys.PendingOperation);
};

const clearPendingOperationFromCard: (modalCard: ?ModalState) => ?ModalState = (modalCard) => {
  if (!modalCard) {
    return null;
  }

  const { cardData } = modalCard;
  if (!cardData) {
    return modalCard;
  }

  return {
    ...modalCard,
    cardData: {
      ...cardData,
      pendingOperation: undefined,
    },
  };
};

const hasPendingOperation: () => boolean = () => getPendingOperation() !== null;

const checkPendingOperation: () => void = () => {
  const pendingOperation = getPendingOperation();

  if (!pendingOperation) {
    return;
  }

  const { cardData, date } = pendingOperation;

  if (date + PENDING_OPERATION_TTL < AccurateTimestamp.now()) {
    // Pending operation expired
    clearPendingOperation();
    return;
  }

  Messenger.emit(MessengerEvents.OPEN_CARD, cardData);
};

const updatePendingOperationType: (newType: PendingOperationKind) => void = (newType) => {
  const pendingOperation = getPendingOperation();

  if (!pendingOperation) {
    return;
  }

  if (pendingOperation.cardData.pendingOperation?.operationType === undefined) {
    return;
  }

  pendingOperation.cardData.pendingOperation.operationType = newType;

  if (newType === PendingOperationKind.OpenCard) {
    /*
     * User was trying to play something but had no subscription, then refused to subscribe, hence, no need to try to play again
     * In this case, we also ensure that the correct card is open (series if required)
     */
    const { seriesId } = pendingOperation.cardData.item;
    if (seriesId) {
      pendingOperation.cardData.item.id = seriesId;
    }
  }

  LocalStorageManager.save(StorageKeys.PendingOperation, pendingOperation);
};

export {
  checkPendingOperation,
  clearPendingOperation,
  clearPendingOperationFromCard,
  clearPendingPurchase,
  getPendingOperation,
  hasPendingOperation,
  storePendingOperation,
  updatePendingOperationType,
};
