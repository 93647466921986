/* @flow */

import * as React from 'react';
import { BroadcastStatus } from '../../helpers/ui/location/Format';
import type { NETGEM_API_V8_FEED_ITEM } from '../../libs/netgemLibrary/v8/types/FeedItem';
import { getRealPercentage } from '../../helpers/maths/maths';
import styles from './ProgressBar.module.css';
import { useMemo } from 'react';

const MAX_PERCENTAGE = 100;

// Ensures that minimum progress is displayed when a program has been watched for a very short time (5%)
const MIN_PERCENTAGE = 0.05;

/*
 * ProgressBar component should be called with either {broadcastStatus, item, now} or {progress}, with progress in [0,100]
 */
/* eslint-disable react/require-default-props */
export type ProgressBarPropType = {|
  +allowZeroProgress?: boolean,
  +broadcastStatus?: BroadcastStatus,
  +item?: NETGEM_API_V8_FEED_ITEM,
  +now?: number,
  +progress?: number | null,
|};
/* eslint-enable react/require-default-props */

const getProgress = ({ allowZeroProgress, broadcastStatus, item, now, progress }: ProgressBarPropType): number | null => {
  if (typeof progress === 'number') {
    if (progress > 0) {
      // Progress is directly provided by parent component (button for live or catchup tile/card)
      return progress / MAX_PERCENTAGE;
    } else if (progress === 0) {
      return allowZeroProgress ? 0 : MIN_PERCENTAGE;
    }
  }

  if (broadcastStatus === undefined || broadcastStatus !== BroadcastStatus.Live || item === undefined || now === undefined) {
    return null;
  }

  // Progress has to be computed from item start and end times (live tile)
  const { endTime, startTime } = item;
  return getRealPercentage(now, startTime, endTime);
};

const ProgressBar = ({ allowZeroProgress = true, broadcastStatus, item, now, progress }: ProgressBarPropType): React.Node => {
  const progressPct = useMemo(
    () =>
      getProgress({
        allowZeroProgress,
        broadcastStatus,
        item,
        now,
        progress,
      }),
    [allowZeroProgress, broadcastStatus, item, now, progress],
  );

  if (progressPct === null) {
    // Not live
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.progressBar} style={{ transform: `scaleX(${progressPct})` }} />
    </div>
  );
};

export default ProgressBar;
